@import '../../styles/utilities.scss';
.careerfuturesec {
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
    @include min(1600) {
        margin-bottom: size(150);
    }
    opacity: 0;
    transform: translateY(20px);
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), .3s);
    &.loaded {
        opacity:1;
        transform: translateY(0px);
        height: auto !important;
        min-height: 1px !important;
    }
}
.outerwrap {
    width: 1089px;
    max-width: 100%;
    display: block;
    margin: auto;
}
.wrapper {
    display: flex;
    flex-wrap: wrap;
    &>* {
        flex-basis: 100%;
        max-width: 100%;
    }
    .textcol {
        @include min(768) {
            flex-basis: 51.4%;
            max-width: 51.4%;
        }
        @include min(992) {
            flex-basis: 40.4%;
            max-width: 40.4%;
        }
        @include max(767.98) {
            order: 2;
        }
    }
    .imgcol {
        display: flex;
        align-items: flex-end;
        &>* {
            max-width: 100%;
        }
        @include min(768) {
            flex-basis: 48.6%;
            max-width: 48.6%;
        }
        @include min(992) {
            flex-basis: 59.6%;
            max-width: 59.6%;
        }
        @include max(767.98) {
            order: 1;
        }
    }
}
.textwrap {
    max-width: 437px;
    display: block;
    @include min(768) {
        margin-right: auto;
    }
    @include max(767.98) {
        text-align: center;
    }
    h2 {
        margin-bottom: 0;
        @include min(1200) {
            padding-bottom: 10px;
        }
    }
    .arw_link {
        margin-top: size(20);
        @include min(992) {
            margin-top: size(30);
        }
        @include min(1200) {
            margin-top: size(35);
        }
        @include min(1600) {
            margin-top: size(40);
        }
    }
}
.wrapouter {
    display: flex;
    flex-wrap: wrap;
    @include min-max(768,991.98) {
        padding-left: 25px;
    }
    @include max(767.98) {
        margin-bottom: size(30);
    }
    .figwrap {
        max-width: 100%;
        position: relative;
        .imgwrap {
            position: relative;
            @include border-radius(8px);
            @include overflow(hidden);
            margin: 0;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                transform: scale(1.2);
            }
        }
        &:nth-child(1) {
            margin-left: auto;
        }
        &:nth-child(2)  {
            bottom: size(-93);
            margin-left: 8px;
            @include min(992) {
                margin-left: 13px;
            }
        }
        &:nth-child(1), &:nth-child(2) {
            max-width: 37.4%;
            width: 239px;
            margin-bottom: 8px;
            @include min(992) {
                margin-bottom: 13px;
            }
            .imgwrap {
                padding-bottom: calc((333/239)*100%);
            }
        }
        &:nth-child(3) {
            width: 387px;
            max-width: 60.5%;
            @include min(1200) {
                margin-left: 10px;
            }
            .imgwrap {
                padding-bottom: calc((239/387)*100%);
            }
        }
    }
}

html[dir=rtl] {
    .textwrap {
        margin-right: 0 !important;
        @include min(768) {
            margin-left: auto;
        }
    }
    .wrapouter {
        @include min-max(768,991.98) {
            padding-left: 0;
            padding-right: 25px;
        }
        .figwrap {
            &:nth-child(1) {
                margin-right: auto;
                margin-left: 0;
            }
            &:nth-child(2)  {
                margin-right: 8px;
                @include min(992) {
                    margin-right: 13px;
                }
                margin-left: 0 !important;
            }
            &:nth-child(3) {
                @include min(1200) {
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
        }

    }
}