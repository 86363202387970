@import '../../../styles/utilities.scss';
.tablesec {
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
    @include min(1600) {
        margin-bottom: size(150);
    }
}
.table {
    border: 2px solid $line-color;
    @include border-radius(20px);
    @include overflow(hidden);
    font-size: size(18);
    font-weight: $font-medium;
    .tablehead {
        display: flex;
        padding: size(20) size(46) size(20) size(35);
        border-bottom: 2px solid $line-color;
        @include min(1200) {
            padding: size(22.5) size(76) size(22.5) size(65);
        }
    }
    .bodynote {
        padding: size(20) 2px 0 0 !important;
        position: relative;
        
        &::before {
            content: '';
            display: block;
            width: 11px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            background-color: #f9f9f9;
        }
    }
    .tablebody {
        margin: 0 !important;
        height: 263px;
        padding-right: 5px;
        overflow: auto;
        .no_result {
            padding: size(20) size(46) size(20) size(35);
            text-align: center;
            @include min(1200) {
                padding: size(22.5) size(76) size(22.5) size(65);
            }
        }
        &::-webkit-scrollbar {
            width: 6px;
            @include border-radius(22px);
          }
          
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent; 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #DBDBDB; 
        @include border-radius(22px);
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: darken(#DBDBDB, 10); 
        }
        li {
            display: flex;
            padding: size(18) size(35);
            @include min(1200) {
                padding: size(20) size(65);
            }
        }
    }
    .tablenote {
        margin: 0 !important;
        padding: size(60) size(30) size(42);
        text-align: center;
        p {
            color: #939393;
            font-weight: $font-medium;
            a {
                font-weight: $font-medium;
            }
        }
    }
    .index {
        flex-basis: 14.6%;
        max-width: 14.6%;
    }
    .currency {
        flex-basis: 35.8%;
        max-width: 35.8%;
        display: flex;
        align-items: center;
        .icon {
            width: 36px;
            max-width: 36px;
            display: flex;
            align-items: center;
            margin-right: size(24);
            position: relative;
            &::before {
                content: '';
                display: block;
                width: 100%;
                padding-bottom: calc((26/36)*100%);
            }
            &>* {
                max-width: 100%;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                -o-object-fit: cover;
            }
        }
    }
    .dd_dt {
        flex-basis: 19.6%;
        max-width: 19.6%;
    }
    .fc_buy {
        flex-basis: 20%;
        max-width: 20%;
    }
    .fc_cell {
        flex-basis: 10%;
        max-width: 10%;
    }
}

html[dir=rtl] {
    .table {
        .tablehead {
            padding: size(20) size(35) size(20) size(46);
            border-bottom: 2px solid $line-color;
            @include min(1200) {
                padding: size(22.5) size(65) size(22.5) size(76);
            }
        }
        .bodynote {
            padding: size(20) 0 0 2px !important;
            &::before {
                left: 0;
                right: auto;
            }
        }
        .currency {
            .icon {
                margin-right: 0;
                margin-left: size(24);
            }
        }
    }
}