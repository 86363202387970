@import '../../styles/utilities.scss';

.servicefaqsec{
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(100);
    }
    @include min(1600) {
        margin-bottom: size(130);
    }
    
    .circleshape {
        background: rgb(255,155,93);
        background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
        position: absolute;
        right: 40px;
        @include border-radius(50%);
        filter: blur(70px);
        opacity: .5;
        z-index: -1;
        width: 200px;
        height: 200px;
        left: -150px;
        top: -100px;
        pointer-events: none;
        @include min(768) {
            width: 500px;
            height: 500px;
            left: -450px;
            top: -150px;
        }
        @include min(1200) {
            width: 700px;
            height: 700px;
            left: -650px;
            top: -250px;
        }
    }
}
.headingsec {
    text-align: center;
    text-align: center;
    margin-bottom: size(30);
    @include min(768) {
        margin-bottom: size(40);
    }
    @include min(992) {
        margin-bottom: size(50);
    }
    @include min(1200) {
        margin-bottom: size(60);
    }
    @include min(1600) {
        margin-bottom: size(75);
    }
}
.wrapper {
    max-width: 1087px;
    margin-left: auto;
    margin-right: auto;
    min-height: 322px;
}

.btnwrap {
    text-align: center;
    margin-top: size(25);
    @include min(992) {
        margin-top: size(30);
    }
    @include min(1200) {
        margin-top: size(40);
    }
    @include min(1600) {
        margin-top: size(50);
    }
}
.accordions {
    position: relative;
    .acc_item {
        position: relative;
    }
}
html[dir=rtl] {
    .circleshape {
        right: -150px;
        @include min(768) {
            right: -300px;
        }
        @include min(1200) {
            right: -500px;
        }
        left: auto !important;
    }
}