@import '../../../styles/utilities.scss';

.textwrap {
    @include min (992) {
        max-width: 489px;
    }
    h2 {
        padding-bottom: 10px;
        margin-bottom: 0;
    }
    .btn {
        margin-top: size(20);
        padding-left: 27px;
        padding-right: 27px;
        @include min(768) {    
            margin-top: size(30);
        }
        @include min(1200) {    
            margin-top: size(40);
            padding-left: size(30);
            padding-right: size(30);
        }
        @include min(1600) {    
            margin-top: size(50);
        }
    }
}