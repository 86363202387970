@import '../../styles/utilities.scss';
.exchangesendmoney {
    
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(140);
    }
    @include min(1600) {
        margin-bottom: size(170);
    }
}
.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 50px;
    @include min(992){
        margin-bottom: 100px;
    }
    &>* {
        padding-left: 10px;
        padding-right: 10px;
        flex-basis: 100%;
        max-width: 100%;
    }
    .textcol {
        @include min(992) {
            flex-basis: 40%;
            max-width: 40%;
        }
        @include max(991.98) {
            order: 2;
        }
    }
    .imgcol {
        @include min(992) {
            flex-basis: 60%;
            max-width: 60%;
        }
        @include max(991.98) {
            order: 1;
        }
    }
    .textwrap {
        @include min (992) {
            max-width: 489px;
        }
        h2 {
            padding-bottom: 10px;
            margin-bottom: 0;
        }
        .btn {
            margin-top: size(34);
            @include min(1200) {    
                margin-top: size(40);
            }
            @include min(1600) {    
                margin-top: size(50);
            }
        }
    }
    .devicewrap {
        position: relative;
        max-width: size(580);
        margin-left: auto;
        margin-right: auto;
        @include min(1200) {
            margin-right: 0;
        }
        .imgwrap {
            max-width: 100%;
            @include border-radius(20px);
            background-color: #FFF9F5;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 240px;
            margin: auto;
            @include min(768) {
                width: 400px;
                margin: 0 0 0 auto;
            }
            @include min(992) {
                width: 350px;
            }
            @include min(1600) {
                width: 449px;
            }
            @include max(991.98) {
                margin-bottom: size(50);
            }
            .imgsec {
                width: 100%;
                padding-bottom: calc((558/449)*100%);
                position: relative;
            }
            img {
                max-width: 100%;
                margin: 0 auto;
                max-height: 93%;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .moneychart {
            display: inline-block;
            position: absolute;
            max-width: 44%;
            /* left: 0; */
            bottom: 25px;
            width: 250px;
            @include min(768) {
                right: 300px;
                bottom: 49px;
                width: 275px;
            }
            @include min(992) {
                right: 260px;
                bottom: 35px;
                width: 250px;
            }
            @include min(1600) {
                right: 339px;
                bottom: 49px;
                width: 318px;
            }
            &::before {
                content: '';
                position: absolute;
                width: calc(100% + 5px);
                height: calc(100% + 5px);
                @include border-radius(20px);
                right: -10px;
                bottom: -10px;
                background-color: $primary-color;
                filter: blur(12px);
                opacity: .11;
                z-index: -1;
            }
            .chartwrap {
                background-color: $white;
                @include border-radius(10px);
                padding: size(20) size(20) size(10);
                width: 100%;
                @include min(1200) {
                    @include border-radius(20px);
                }
                @include min(1600) {
                    padding: size(35) size(35) size(23);
                }
                .money {
                    display: flex;
                    align-items: center;
                    .flag {
                        display: flex;
                        align-items: center;
                        flex-basis: 27px;
                        max-width: 27px;
                        @include min(768) {    
                            flex-basis: 40px;
                            max-width: 40px;
                        }
                        @include min(1200) {    
                            flex-basis: 51px;
                            max-width: 51px;
                        }
                        svg {
                            max-width: 100%;
                            max-height: 19px;
                            @include min(768) {   
                                max-height: 29px;
                            }
                            @include min(1200) {    
                                max-height: 35px;
                            }
                        }
                    }
                    .currency {
                        flex-basis: calc(100% - 51px);
                        max-width: calc(100% - 51px);
                        padding-left: size(13);
                        @include min(768) {   
                            padding-left: size(18);
                        }
                        @include min(1200) {   
                            padding-left: size(24);
                        }
                        &>* {
                            display: block;
                            line-height: 1;
                        }
                        .main {
                            font-size: size(20);
                            font-weight: $font-semibold;
                            color: $black;
                            padding-bottom: size(2);
                            
                            font-size: size(10);
                            @include min(568) {    
                                font-size: size(14);
                                padding-bottom: size(5);
                            }
                            @include min(768) {    
                                font-size: size(18);
                            }
                            @include min(1200) {    
                                font-size: size(20);
                            }
                        }
                        .convert {
                            font-size: size(9);
                            font-weight: $font-semibold;
                            @include min(568) {    
                                font-size: size(13);
                            }
                            @include min(768) {    
                                font-size: size(15);
                            }
                            @include min(1200) {    
                                font-size: size(16);
                            }
                        }
                    }
                }
                .figwrap {
                    width: 237px;
                    max-width: 100%;
                    margin-top: size(-15);
                    figure {
                        margin: 0;
                        width: 100%;
                        padding-bottom: calc((143/237)*100%);
                        position: relative;
                        img {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            -o-object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}

html[dir=rtl] {
    
    .wrapper {
        .devicewrap {
            .imgwrap {
                margin-left: 0;
                margin-right: auto;
            }
            .moneychart {
                left: 50%;
                @include min(768) {
                    left: 300px;
                }
                @include min(992) {
                    left: 260px;
                }
                @include min(1600) {
                    left: 339px;
                }
                right: auto !important;
                &::before {
                    left: -10px;
                }
                .chartwrap {
                    .money {
                        .currency {
                            padding-right: size(13);
                            @include min(768) {   
                                padding-right: size(18);
                            }
                            @include min(1200) {   
                                padding-right: size(24);
                            }
                            padding-left: 0;
                        }
                    }
                    .figwrap {
                        transform: rotateY(180deg);
                    }
                }
            }
        }
    }
}