@import "../../styles/utilities.scss";
.smcontainer {
  max-width: 1033px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.smwrapper {
  max-width: 812px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.breadcrumbwrap {
}
.title_detail {
  margin: 0 0 size(25);
  min-height: 115px;
  @include min(768) {
    margin: size(20) 0 size(35);
  }
  @include min(1200) {
    margin: size(20) 0 size(45);
  }
  .postdate {
    margin-top: size(14);
    @include min(1200) {
      margin-top: size(24);
    }
  }
}
.postfigwrap {
  width: 1033px;
  max-width: 100%;
  margin: 0 0 size(25);
  @include min(768) {
    margin: 0 0 size(35);
  }
  @include min(1200) {
    margin: 0 0 size(45);
  }
  .imgwrap {
    position: relative;
    width: 100%;
    padding-bottom: calc((429 / 1033) * 100%);
    @include overflow(hidden);
    @include border-radius(10px);
    @include min(768) {
      @include border-radius(20px);
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.postcnt {
  min-height: 240px;
  white-space: pre-line;
}
.postdetail_share {
  padding: size(25) 0 size(40);
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: size(50);
  @include min(768) {
    margin-bottom: size(60);
  }
  @include min(1200) {
    margin-bottom: size(80);
  }
  @include min(768) {
    padding: size(35) 0 size(40);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  @include min(1200) {
    padding: size(48) 0 size(45);
  }
  @include min(1600) {
    padding: size(59) 0 size(55);
  }
  .postdetail {
    @include max(767.98) {
      margin-bottom: size(30);
    }
    li {
      font-weight: $font-medium;
    }
  }
  .share {
    display: flex;
    align-items: center;
    font-weight: $font-medium;
    color: #939393;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0 !important;
      width: 153px;
      max-width: 100%;
      margin-left: size(25);
      li {
        position: relative;
        a {
          svg {
            fill: #939393;
            @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
          }
          &:hover {
            svg {
              fill: $black;
            }
          }
        }
        .clip_to_board {
          position: absolute;
          display: inline-block;
          bottom: -25px;
          color: $primary-color;
          left: 50%;
          transform: translateX(-50%);
          font-size: size(12);
          padding: 2px 3px;
          border: 1px solid rgba($primary-color, 0.7);
          background-color: rgba($primary-color, 0.3);
          border-radius: 4px;
          line-height: 1;
        }
      }
    }
  }
}

html[dir="rtl"] {
  .postdetail_share {
    .share {
      ul {
        margin-left: auto;
        margin-right: size(25);
      }
    }
  }
}
