@import '../../styles/utilities.scss';

.thankwrap {
    text-align: center;
    figure {
        display: block;
        margin: auto auto size(18);
        img {
            max-width: 100px;
            @include min(1200) {
                max-width: 100%;
            }
        }
    }
    .thanktitle {
        h2 {
            color: $primary-color;
        }
    }
    .thankmsg {
        margin-bottom: size(35);
        @include min(768) {
            margin-bottom: size(45);
        }
        @include min(992) {
            margin-bottom: size(55);
        }
        @include min(1200) {
            margin-bottom: size(65);
        }
        @include min(1600) {
            margin-bottom: size(99);
        }
        p {
            font-size: size(16);
            font-weight: $font-medium;
            @include min(768) {
                font-size: size(18);
            }
        }
    }
    .visitext {
        margin-bottom: size(20);
        @include min(768) {
            margin-bottom: size(35);
        }
        @include min(992) {
            margin-bottom: size(40);
        }
        @include min(1200) {
            margin-bottom: size(50);
        }
        @include min(1600) {
            margin-bottom: size(60);
        }
        p {
            color: #939393;
            font-weight: $font-medium;
            a {
                color: $primary-color;
                &:hover {
                    color: $secondary-color;
                }
            }
        }
    }
    .donebtn  {
        width: 100%;
    }
}