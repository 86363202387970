@import '../../../../styles/utilities.scss';
.logins {
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: size(50);
    @include min(1200) {
        margin-top: size(0);
        flex-wrap: nowrap;
        align-items: center;
        flex-basis: 190px;
        max-width: 190px;
    }
    @include min(1600) {
        flex-basis: 205px;
        max-width: 205px;
    }
    .loginbtn {
        margin-left: auto;
        padding: 0 16px;
        min-width: 80px;
        font-size: size(14);
        font-weight: $font-semibold;
        @include max(1199.98) {
            flex-basis: 48%;
            max-width: 48%;
            margin-left: 0;
        }
        @include max(480.98) {
            flex-basis: 100%;
            max-width: 100%;
            font-size: size(16);
        }
        @include min(1600) {
            font-size: size(16);
            padding: 0 23px;
            min-width: 117px;
        }
        &+.loginbtn {
            margin-top: 15px;
            @include min(481) {
                margin-left: 10px;
                margin-top: 0;
            }
            @include min(1600) {
                margin-left: 15px;
            }
        }
    }
}
:global(.search_open ) {
    .logins {
        opacity: 0;
        pointer-events: none;
    }
}

html[dir=rtl] {
    .logins {
        .loginbtn {
            &+.loginbtn {
                @include min(768) {
                    margin-right: 10px;
                    margin-left: 0 !important;
                }
                @include min(1600) {
                    margin-right: 15px;
                }
            }
        }
    }
}