@import '../../styles/utilities.scss';
.title_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: size(25);
    @include min(768) {
        margin-bottom: size(40);
        &>* {
            flex-basis: 50%;
            max-width: 50%;
        }
    }
    @include min(992) {
        margin-bottom: size(45);
    }
    @include min(1200) {
        margin-bottom: size(50);
    }
    h2 {
        margin: 0;
    }
}
.search {
    text-align: right;
    .searcholder {
        position: relative;
        display: inline-block;
        max-width: 100%;
        .searchicon {
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .searchinput {
            height: 50px;
            border: 2px solid $line-color;
            @include border-radius(25px);
            padding: 0 25px 0 56px;
            width: 385px;
            max-width: 100%;
            outline: none !important;
            box-shadow: none !important;
        }
    }
}