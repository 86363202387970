@import '../../../styles/utilities.scss';
.tablesec {
    
    margin-bottom: size(50);
}
.table {
    font-size: size(15);
}
.tablehead {
    padding: size(19) 0;
    border: 1px solid $line-color;
    border-width: 1px 0 1px;
    font-weight: $font-semibold;
    color: $text-alt;
    &>* {
        display: flex;
        &>* {
            &+* {
                padding-left: 10px;
            }
        }
    }
}
.tablebody {
    li {
        display: flex;
        padding: size(12) 0;
        color: $black;
        font-weight: $font-medium;
        &>* {
            &+* {
                padding-left: 10px;
            }
        }
    }
}
.tablenote {
    margin: size(20) 0 0 !important;
    text-align: center;
    p {
        color: #939393;
        font-weight: $font-medium;
        a {
            font-weight: $font-medium;
        }
    }
}
.currency {
    flex-basis: 25.5%;
    max-width: 25.5%;
    @include max(767.98) {
        padding-right: 6%;
    }
}
.dd_dt {
    flex-basis: 26.5%;
    max-width: 26.5%;
}
.fc_buy {
    flex-basis: 26.5%;
    max-width: 26.5%;
}
.fc_cell {
    flex-basis: 21.5%;
    max-width: 21.5%;
    text-align: right;
}
.btnwrap {
    text-align: center;
    margin-top: size(32);
    .loadmore {
        min-width: 1px;
    }
}