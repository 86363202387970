@import '../../styles/utilities.scss';

.linear_progress_material {
	background: $line-color-2;
	height: 2px;
	position: relative;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	-webkit-animation: start 0.3s ease-in;
	animation: start 0.3s ease-in;
	.bar {
		position: absolute;
		background: $primary-color;
		transition: transform 0.2s linear;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 70%;
	}
	.bar1 {
		-webkit-animation: growBar1 1.5s infinite, moveBar1 1.5s infinite;
		animation: growBar1 1.5s infinite, moveBar1 1.5s infinite;
	}
	.bar2 {
		-webkit-animation: growBar2 1.5s infinite, moveBar2 1.5s infinite;
		animation: growBar2 1.5s infinite, moveBar2 1.5s infinite;
	}
}

.progress_loader {
    display: block;
    position: fixed;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: $white;
    &_bar {
        width: 100%;
        height: 4px;
        background: $line-color-2;
        position: relative;
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 30%;
            height: 4px;
            top: 0;
            background-color: $primary-color;
        }
    }
}