@import "../../styles/utilities.scss";

.circleshape {
  background: rgb(255, 155, 93);
  background: radial-gradient(
    circle,
    rgba(255, 155, 93, 1) 0%,
    rgba(249, 249, 249, 0) 100%
  );
  position: absolute;
  right: 40px;
  @include border-radius(50%);
  filter: blur(50px);
  opacity: 0.5;
  z-index: -1;
  width: 200px;
  height: 200px;
  right: -150px;
  bottom: -100px;
  pointer-events: none;
  @include min(768) {
    width: 500px;
    height: 500px;
    right: -450px;
    bottom: -250px;
  }
  @include min(1200) {
    width: 700px;
    height: 700px;
    right: -600px;
    bottom: -350px;
  }
}
.cashtrasfersec {
  position: relative;
  margin-bottom: size(50);
  @include min(768) {
    margin-bottom: size(60);
  }
  @include min(992) {
    margin-bottom: size(80);
  }
  @include min(1200) {
    margin-bottom: size(130);
  }
  @include min(1600) {
    margin-bottom: size(150);
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    & > * {
      padding-left: 10px;
      padding-right: 10px;
      flex-basis: 100%;
      max-width: 100%;
    }
    .textcol {
      @include min(992) {
        flex-basis: 40%;
        max-width: 40%;
      }
    }
    .imgcol {
      @include min(992) {
        flex-basis: 60%;
        max-width: 60%;
      }
    }
    .textwrap {
      @include min(992) {
        max-width: 489px;
      }
      h2 {
        padding-bottom: 10px;
        margin-bottom: 0;
      }
      .btn {
        margin-top: size(20);
        @include min(768) {
          margin-top: size(30);
        }
        @include min(1200) {
          margin-top: size(40);
        }
        @include min(1600) {
          margin-top: size(50);
        }
      }
    }
    .devicewrap {
      position: relative;
      .imgwrap {
        max-width: 100%;
        @include border-radius(20px);
        background-color: #fff9f5;
        margin-right: auto;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 240px;
        @include min(768) {
          width: 400px;
        }
        @include min(992) {
          width: 350px;
        }
        @include min(1600) {
          width: 449px;
        }
        @include max(991.98) {
          margin-bottom: size(50);
        }
        .imgsec {
          width: 100%;
          padding-bottom: calc((558 / 449) * 100%);
          position: relative;
        }
        img {
          max-width: 100%;
          margin: 0 auto;
          max-height: 93%;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .dashboard {
        position: absolute;
        max-width: 50%;
        left: 50%;
        bottom: 35px;
        width: 250px;
        .box {
          background-color: $white;
          display: inline-flex;
          width: 100%;
          padding: size(20);
          @include border-radius(10px);
          @include min(1200) {
            @include border-radius(20px);
          }
        }
        @include min(768) {
          left: 300px;
          bottom: 49px;
          width: 306px;
        }
        @include min(992) {
          left: 260px;
          bottom: 35px;
          width: 250px;
        }
        @include min(1600) {
          left: 339px;
          bottom: 49px;
          width: 306px;
          .box {
            padding: size(35);
          }
        }
        .cnt {
          display: flex;
          flex-direction: column;
          flex-basis: calc(100% - 25px);
          max-width: calc(100% - 25px);
          @include min(768) {
            flex-basis: calc(100% - 35px);
            max-width: calc(100% - 35px);
          }
          @include min(1200) {
            flex-basis: calc(100% - 50px);
            max-width: calc(100% - 50px);
          }
          & > header {
            color: $black;
            font-weight: $font-medium;
            font-size: size(8);
            padding-bottom: size(10);
            @include min(768) {
              font-size: size(12);
              padding-bottom: size(12);
            }
            @include min(992) {
              font-size: size(16);
            }
            @include min(1200) {
              padding-bottom: size(15);
              font-size: size(17);
            }
            @include min(1600) {
              padding-bottom: size(19);
            }
          }
          .price {
            font-weight: $font-semibold;
            color: $black;
            font-size: size(13);
            line-height: 1;
            padding-bottom: size(6);
            @include min(768) {
              font-size: size(20);
            }
            @include min(992) {
              font-size: size(22);
            }
            @include min(1200) {
              font-size: size(24);
            }
            @include min(1600) {
              font-size: size(27);
            }
          }
          .note {
            color: $text-alt;
            font-size: size(6);
            @include min(768) {
              font-size: size(10);
            }
            @include min(992) {
              font-size: size(12);
            }
            @include min(1200) {
              font-size: size(13);
            }
          }
        }
        .arwup {
          flex-basis: 25px;
          max-width: 25px;
          pointer-events: none;
          @include min(768) {
            flex-basis: 35px;
            max-width: 35px;
          }
          @include min(1200) {
            flex-basis: 50px;
            max-width: 50px;
          }
          .upbtn {
            @include border-radius(50%);
            background-color: $primary-color;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            padding: 0;
            width: 22px;
            height: 22px;
            @include min(768) {
              width: 30px;
              height: 30px;
            }
            @include min(1200) {
              width: 40px;
              height: 40px;
            }
            svg {
              fill: $white;
              transform: rotate(180deg);
              width: 8px;
              height: 8px;
              @include min(768) {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
        &::before {
          content: "";
          position: absolute;
          width: calc(100% + 5px);
          height: calc(100% + 5px);
          @include border-radius(20px);
          right: -10px;
          bottom: -10px;
          background-color: $primary-color;
          filter: blur(12px);
          opacity: 0.11;
          z-index: -1;
        }
      }
    }
  }
}
html[dir="rtl"] {
  .cashtrasfersec {
    .wrapper {
      .devicewrap {
        .imgwrap {
          margin-left: auto;
          margin-right: 0;
        }
        .dashboard {
          right: 50%;
          @include min(768) {
            right: 300px;
          }
          @include min(992) {
            right: 260px;
          }
          @include min(1600) {
            right: 339px;
          }
          left: auto !important;
          .arwup {
            .upbtn {
              margin-left: 0;
              margin-right: auto;
            }
          }
          &::before {
            left: -10px;
            right: auto;
          }
        }
      }
    }
  }
}
