@import '../../styles/utilities.scss';
.otherbranchesec {
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(100);
    }
    @include min(1600) {
        margin-bottom: size(130);
    }
}
.titlesec {
    padding-bottom: size(10);
    @include min(1200) {
        padding-bottom: size(20);
    }
}
.btnwrap {
    padding-top: size(10);
    @include min(1200) {
        padding-top: size(20);
    }
    .btn {
        padding-left: size(20);
        padding-right: size(20);
        @include min(992) {
            padding-left: size(27);
            padding-right: size(27);
        }
    }
}