@import "./utilities.scss";

html {
  font-size: 16px;
  overflow: auto;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  scroll-behavior: auto !important;
  scroll-padding-top: 100px;
  @include max(1600) {
    font-size: 16px;
  }
  &.noScroll {
    overflow: hidden;
  }
}
html,
body {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    // display: none;
  }
}
::-webkit-scrollbar {
  width: 5px;
  @include border-radius(5px);
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: rgba($secondary-color, 0.6);
  background: #f27705;
  transition: 0.3s all ease-in-out;
  @include border-radius(5px);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary-color;
}
/* #viewport {
    @include overflow(hidden);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
.scroll-container {
    position: absolute;  
    @include overflow(hidden);
    width: 100%;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  position: relative;
  margin: 0 auto;
  padding: 0;
  font-family: $font-family;
  overflow-x: hidden;
  overflow: hidden;
  padding-top: 55px;
  @include min(768) {
    padding-top: 65px;
  }
  @include min(992) {
    padding-top: 75px;
  }
  @include min(1600) {
    padding-top: 79px;
  }
  &.modal-open {
    // height: 100vh;
  }
}
.search_is_open {
  overflow: hidden;
  body {
    overflow: hidden;
  }
}
img {
  max-width: 100%;
}
.fw-medium {
  font-weight: 500 !important;
}
a {
  @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
  cursor: pointer;
  color: $primary-color;
  font-weight: $font-medium;
  &:hover {
    color: $secondary-color;
  }
}
.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}
.form-control,
.form-select,
input,
button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  &.disabled {
    pointer-events: none;
    button {
      background-image: none !important;
    }
  }
}

select.default {
  -moz-appearance: none;
  -webkit-appearance: none;
  /* background: $white url('../images/carret.png') no-repeat 96% 50%; */
  outline: none;
  &:focus,
  &:active {
    outline: none;
  }
}

/* =====  Unordered List   ===== */
ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1.6;
}
p,
li {
  font-size: size(15);
  color: $black;
  @include min(992) {
    font-size: size(16);
  }
}
p {
  line-height: 1.6;
  margin-bottom: 0;
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6,
  & + ul,
  & + ol {
    margin-top: size(20);
    @include min(992) {
      margin-top: size(25);
    }
  }
}
ul {
  &.list {
    display: block;
    margin: 0;
    padding-left: 0;
    li {
      display: block;
      line-height: 1.6;
      padding-left: 25px;
      position: relative;
      /* font-size: size(16); */
      & + li {
        margin-top: 18px;
      }
      &:before {
        background-color: $text-alt;
        border: none;
        @include border-radius(50%);
        content: "";
        display: block;
        height: 7px;
        left: 0;
        position: absolute;
        top: 9px;
        width: 7px;
      }
      @include max(1199) {
        padding-left: 20px;
        & + li {
          margin-top: 12px;
        }
      }
      @include max(767) {
        padding-left: 15px;
      }
    }
    &.white {
      li {
        &:before {
          border-color: $white;
        }
      }
    }
  }
}
/* =====    Ordered List     ===== */
ol {
  &.list {
    margin: 0;
    counter-reset: item;
    li {
      display: block;
      line-height: 1.57;
      padding-left: 20px;
      position: relative;
      & + li {
        margin-top: 18px;
      }
      &:before {
        content: counters(item, ".") ".";
        counter-increment: item;
        display: inline-block;
        left: 0;
        position: absolute;
      }
      @include max(1199) {
        & + li {
          margin-top: 12px;
        }
      }
    }
    &.lower-alpha {
      li {
        &:before {
          content: counters(item, "", lower-alpha) ")";
        }
      }
    }
    &.lower-roman {
      li {
        &:before {
          content: counters(item, "", lower-roman) ")";
        }
      }
    }
    &.roman-sub {
      li {
        padding-left: 30px;
        &:before {
          content: counters(item, ".", upper-roman) ".";
          counter-increment: item;
          font-weight: 500;
        }
        ol {
          li {
            padding-left: 32px;
          }
        }
      }
    }
    &.decimal-sub {
      li {
        padding-left: 30px;
        &:before {
          content: counters(item, ". ", decimal) ".";
          counter-increment: item;
          font-weight: 500;
        }
        ol {
          padding-top: 5px;
          li {
            padding-left: 32px;
          }
          ol {
            li {
              padding-left: 50px;
            }
          }
        }
      }
    }
  }
}
html[dir="rtl"] {
  ul {
    &.list {
      li {
        padding-left: 0;
        padding-right: 25px;
        &:before {
          right: 0;
          left: auto;
        }
      }
    }
  }
  ol {
    &.list {
      li {
        padding-left: 0;
        padding-right: 25px;
        &:before {
          left: auto;
          right: 0;
        }
      }
    }
  }
}
ul,
ol {
  & + * {
    margin-top: 20px;
  }
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 30px;
  }
  & + p {
    margin-top: 20px !important;
  }
}

* {
  & + p {
    margin-top: size(20) !important;
    @include min(992) {
      margin-top: size(25) !important;
    }
  }
  & + ul,
  & + ol {
    margin-top: size(20) !important;
    @include min(992) {
      margin-top: size(25) !important;
    }
  }
  & + .h1,
  & + h1,
  & + .h2,
  & + h2,
  & + .h3,
  & + h3,
  & + .h4,
  & + h4,
  & + .h5,
  & + h5,
  & + .h6,
  & + h6 {
    margin-top: size(20) !important;
    @include min(992) {
      margin-top: size(25) !important;
    }
  }
}
.zsiq_cnt {
  // display: none !important;
  * {
    & + p {
      margin-top: 0 !important;
      @include min(992) {
        margin-top: 0 !important;
      }
    }
  }
}
/* Headings */
.smtitle {
  font-weight: $font-medium;
  color: $primary-color;
  line-height: 1;
  font-size: size(16);
  @include min(768) {
    font-size: size(18);
  }
  @include min(1200) {
    font-size: size(20);
  }
  & + .h1,
  & + h1,
  & + .h2,
  & + h2,
  & + .h3,
  & + h3,
  & + .h4,
  & + h4,
  & + .h5,
  & + h5,
  & + .h6,
  & + h6 {
    margin-top: size(12) !important;
    @include min(768) {
      margin-top: size(20) !important;
    }
    @include min(992) {
      margin-top: size(25) !important;
    }
  }
}
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-weight: $font-semibold;
  font-family: $font-family;
  color: $black;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: size(30);
  @include min(576) {
    font-size: size(34);
  }
  @include min(768) {
    font-size: size(45);
  }
  @include min(1200) {
    font-size: size(55);
  }
  @include min(1600) {
    font-size: size(70);
  }
}
.h2,
h2 {
  font-size: size(25);
  @include min(768) {
    font-size: size(28);
  }
  @include min(1200) {
    font-size: size(30);
  }
  @include min(1600) {
    font-size: size(35);
  }
}
.h3,
h3 {
  font-size: size(20);
  @include min(768) {
    font-size: size(22);
  }
  /* @include min(992) {    
        font-size: size(28);
    } */
  @include min(1200) {
    font-size: size(24);
  }
  @include min(1600) {
    font-size: size(26);
  }
}
.h4,
h4 {
  font-size: size(20);
  @include min(768) {
    font-size: size(22);
  }
  /* @include min(992) {    
        font-size: size(28);
    } */
  @include min(1200) {
    font-size: size(24);
  }
  @include min(1600) {
    font-size: size(26);
  }
}
.h5,
h5 {
  font-size: size(20);
  @include min(1200) {
    font-size: size(22);
  }
  @include min(1600) {
    font-size: size(24);
  }
}
.h6,
h6 {
  font-size: size(16);
  @include min(768) {
    font-size: size(18);
  }
  @include min(1200) {
    font-size: size(20);
  }
  @include min(1600) {
    font-size: size(22);
  }
}

.sectitle {
  padding-bottom: size(25);
  @include min(768) {
    padding-bottom: size(30);
  }
  @include min(1200) {
    padding-bottom: size(49);
  }
  .h1,
  h1,
  .h2,
  h2,
  .h3,
  h3,
  .h4,
  h4,
  .h5,
  h5,
  .h6,
  h6 {
    margin-bottom: 0;
    margin-bottom: 0;
  }
  &.center {
    text-align: center;
  }
}
.breadcrumb {
  margin-bottom: 0;
  padding: size(25) 0 size(25);
  // flex-wrap: nowrap !important;
  //   min-height: 100px;
  @include min(992) {
    padding: size(30) 0 size(30);
  }
  @include min(1200) {
    padding: size(38) 0 size(38);
  }
  &-item {
    white-space: nowrap;
    &.active {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 40%;
      color: rgba($primary-color, 1);
      @include min(768) {
        max-width: 45%;
      }
      @include min(1200) {
        max-width: 100%;
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        color: rgba($primary-color, 1);
        @include min(992) {
          max-width: 100%;
        }
        @include min(1200) {
          max-width: 100%;
        }
      }
    }
  }
  li {
    color: $text-alt;
    // text-transform: capitalize;
    a {
      text-decoration: none;
      color: $text-alt;
      font-weight: $font-regular;
      &:hover {
        color: $primary-color;
      }
    }
  }
}
/* Container */

.container {
  width: 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  @include min(576) {
    max-width: 480px;
  }
  @include min(768) {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 720px;
  }
  @include min(992) {
    max-width: 960px;
  }
  @include min(1200) {
    max-width: 1140px;
  }
  @include min(1600) {
    max-width: 1395px;
  }
}
.smcontainer {
  max-width: 100%;
  width: 1084px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  & > * {
    padding-left: 15px;
    padding-right: 15px;
    flex: 0 1 auto;
  }
}

/* Flex Boxes */

.d-flx {
  @include flexbox();
  &.no-wrap {
    @include flex-wrap(nowrap);
  }
}
.flx-both {
  @include flx(both);
}
.flx-top {
  @include flx(top);
}
.flx-bottom {
  @include flx(bottom);
}
.flx-vcenter {
  @include flx(vcenter);
}
.flx-left {
  @include flx(left);
}
.flx-right {
  @include flx(right);
}
.flx-hcenter {
  @include flx(hcenter);
}
.flx-equal-space {
  @include flx(eqaul-space);
}

/* Buttons */
button,
a {
  outline: none;
  box-shadow: none !important;

  @include min(1200) {
    &.btn {
      &.btn-primary,
      &.btn-brdr,
      &.white {
        &::before {
          content: "";
          display: block;
          width: calc(100% + 50px);
          height: calc(100% + 50px);
          background-color: $quinary-color;
          position: absolute;
          top: 60px;
          left: -25px;
          @include border-radius(50%);
          z-index: -1;
          transition: top 0.3s ease-out;
        }
        &:hover {
          background-color: transparent;
          border-color: $quinary-color;
          color: $white;
          &::before {
            top: -25px;
          }
        }
      }
      &.btn-brdr,
      &.white {
        &::before {
          z-index: 0 !important;
        }
        span {
          position: relative;
          z-index: 1;
        }
      }
      /* This is button anim */
      &.leave {
        &::before {
          transition: all 0.4s cubic-bezier(0.38, 0.67, 1, 1);
          top: -100px;
        }
      }
      &.leave,
      &.enter {
        &::before {
          transition: all 0.4s cubic-bezier(0.38, 0.67, 1, 1);
        }
      }
    }
  }
}
.btn {
  min-width: 164px;
  max-width: 100%;
  display: inline-block;
  position: relative;
  background-color: $primary-color;
  border: 1px solid $primary-color;
  padding: 0 27px;
  height: 50px;
  line-height: 48px;
  @include border-radius(28px);
  @include overflow(hidden);
  @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
  font-weight: $font-semibold;
  color: $white;
  outline: none;
  box-shadow: none;
  font-size: size(16);
  &:focus {
    box-shadow: none !important;
  }
  @include min(768) {
    padding: 0 30px;
    height: 50px;
    line-height: 48px;
    font-size: size(17);
  }
  @include min(1200) {
    font-size: size(18);
    padding: 0 35px;
  }
  &.btn-primary {
    &:focus {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white;
      @include min(1200) {
        background-color: $quinary-color;
        border-color: $quinary-color;
        color: $white;
      }
    }
  }
  &.btn-brdr {
    border-color: $quinary-color;
    color: $quinary-color;
    background-color: transparent;
    &:focus {
      background-color: transparent;
      border-color: $quinary-color;
      color: $quinary-color;
    }
    &:hover {
      border-color: $quinary-color;
      color: $quinary-color;
      background-color: transparent;
      @include min(1200) {
        background-color: $quinary-color;
        color: $white;
      }
    }
  }
  &.white {
    background-color: $white;
    border-color: $white;
    color: $text-off;
    &:focus {
      background-color: $white;
      border-color: $white;
      color: $text-off;
    }
    &:hover {
      background-color: $white;
      border-color: $white;
      color: $text-off;
      @include min(1200) {
        background-color: $secondary-color;
        color: $white;
        border-color: $secondary-color;
      }
    }
  }
}
.arw-link {
  display: inline-flex;
  align-items: center;
  color: $black;
  font-weight: $font-semibold;
  text-decoration: none;
  cursor: pointer;
  font-size: size(15);
  @include min(992) {
    font-size: size(16);
  }
  span {
    width: 12px;
    height: 12px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
    svg {
      fill: $primary-color;
    }
  }
  &:hover {
    color: $primary-color;
    span {
      margin-right: 8px;
    }
  }
  &.disable {
    pointer-events: none;
    span {
      color: #bababa;

      background-color: #bababa;
    }
  }
}
html[dir="rtl"] {
  .arw-link {
    direction: ltr;
  }
}
/* Commons */
.cf {
  @include clearfix();
}
.d-blk {
  display: block;
}
.mr-auto {
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}
.h100 {
  height: 100%;
}
.w100 {
  width: 100%;
}
.tel {
  cursor: default;
}
.no-pad {
  padding: 0 !important;
}

.cmn-p-spce {
  padding-top: 60px;
  padding-bottom: 60px;
}

.mobile-hide {
  @include max(767.98) {
    display: none !important;
  }
}
.only {
  &-device {
    @include min(1200) {
      display: none !important;
    }
  }
  &-desktop {
    @include max(1199.98) {
      display: none !important;
    }
  }
}

/* Forms */
.textarea,
textarea {
  resize: none;
  background-color: transparent !important;
  line-height: 1.8;
  &::-webkit-scrollbar {
      width: size(3);
  }

  &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}
.form-select {
  border: none;
  outline: none;
  background: transparent !important;
  padding: 0 !important;

  .dropdown-menu {
    padding: 0;
    width: 100%;
    filter: drop-shadow(0 3px 10px rgba($black, 0.08));
    border: none;
    overflow: auto;
    max-height: 192px;
    &::-webkit-scrollbar {
      width: 3px;
      @include border-radius(5px);
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba($secondary-color, 0.6);
      @include border-radius(22px);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $secondary-color;
    }
    .dropdown-item {
      padding: size(12) size(20);
      background-color: $white !important;
      color: $black !important;
      &:hover {
        @include min(1200) {
          background-color: #e9ecef !important;
          color: #1e2125 !important;
        }
      }
      &:active {
        background-color: $white !important;
        color: $black !important;
      }
    }
  }
  button {
    &::before {
      display: none !important;
    }
  }
}
textarea {
  &.form-control {
    padding-top: size(10);
    line-height: 1.8 !important;
  }
}
.form-control,
.form-select button {
  border: none;
  outline: none !important;
  border-bottom: 2px solid $line-color;
  border-color: $line-color !important;
  width: 100%;
  height: 50px;
  line-height: 46px;
  box-shadow: none !important;
  padding: 0;
  @include border-radius(0);
  background-color: transparent !important;
}
.form-control {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px var(--input-bg, white) inset !important;
    background-color: inherit !important;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: initial !important;
    color: inherit !important;
  }
}
.form-select button {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.028' height='6.574' viewBox='0 0 11.028 6.574'%3E%3Cpath id='Path_76777' data-name='Path 76777' d='M16171.41-16114.724a.747.747 0 0 1-.53-.22.75.75 0 0 1 0-1.061l4.233-4.233-4.233-4.234a.75.75 0 0 1 0-1.061.75.75 0 0 1 1.061 0l5.294 5.295-5.294 5.294A.747.747 0 0 1 16171.41-16114.724Z' transform='translate(-16114.724 -16170.66) rotate(90)' fill='%230e1728'/%3E%3C/svg%3E") !important;
  background-position: right 0 center !important;
  background-size: 12px;
  background-repeat: no-repeat;
  color: $black;
  font-weight: $font-regular;
  font-size: size(15);
  padding-right: size(20);
  text-align: left;
  @include min(992) {
    font-size: size(16);
  }
  &.btn {
    color: $black !important;
  }
  &::after {
    display: none;
  }
  &:hover {
    color: $black !important;
  }
}
.input-holder {
  position: relative;
  margin-top: size(30);
  &.captcha-holder {
    iframe {
      @include max(767.98) {
        transform: scale(0.76) translateX(-16%);
      }
    }
  }
  &.btn-holder {
    margin-top: size(40);
    @include min(1200) {
      margin-top: size(50);
    }
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: transparent;
    opacity: 0;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent;
    opacity: 0;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: transparent;
    opacity: 0;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: transparent;
    opacity: 0;
  }
  @include min(1200) {
    margin-top: size(40);
  }
  .char-count {
    position: absolute;
    font-size: size(12);
    color: $text-alt;
    bottom: -25px;
    right: 10px;
  }
  label {
    position: absolute;
    color: $black;
    font-size: size(15);
    top: calc(50%);
    transform: translateY(-50%);
    @include transition(all, cubic-bezier(0.4, 0, 0.2, 1), 0.2s);
    @include min(992) {
      font-size: size(16);
    }
    &.fileupload {
      display: block;
      position: relative;
      transform: none;
      top: auto;
    }
  }
  &.active {
    .uploadwraper .uploadlabel .fileupload {
      border-color: rgba($primary-color, 1);
    }
    label {
      top: 0;
      font-size: size(12);
      color: $primary-color;
    }
    .form-control,
    .form-select button {
      border-color: $primary-color !important;
    }
  }
  .form-control {
    &:focus {
      border-color: $primary-color !important;
    }
    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      top: 0;
      font-size: size(12);
      color: $primary-color;
    }
  }
  &.error {
    label {
      // color: $red;
      top: 0;
      font-size: size(12);
    }
    .form-control,
    .form-select button {
      border-color: $red !important;
    }
    .text-danger {
      position: absolute;
      bottom: -18px;
      font-size: 12px;
    }
  }
}
.file-control {
  display: none;
}
.uploadwraper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-left: -10px;
  margin-right: -10px;
  padding-top: size(10);
  @include min(1200) {
    padding-top: size(12);
  }
  & > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  .uploadlabel {
    flex-basis: 100%;
    max-width: 100%;
    @include min(768) {
      flex-basis: 200.5px;
      max-width: 46%;
    }
    .fileupload {
      color: $black;
      padding-bottom: size(8);
      border-bottom: 2px solid $black;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @include transition(all, linear, 0.2s);
      cursor: pointer;
      svg {
        fill: $black;
        flex-basis: 15.5px;
        max-width: 15.5px;
        width: 15.5px;
        height: 15.5px;
        @include transition(all, linear, 0.2s);
      }
      span {
        flex-basis: calc(100% - 15.5px);
        max-width: calc(100% - 15.5px);
        padding-left: size(12);
        display: block;
        white-space: nowrap;
        @include overflow(hidden);
        text-overflow: ellipsis;
        @include transition(all, linear, 0.2s);
        @include max(767.98) {
          text-align: right;
        }
      }
      &:hover {
        svg {
          fill: rgba($primary-color, 1);
        }
        span {
          // color: rgba($primary-color,1);
        }
        border-color: rgba($primary-color, 1);
      }
    }
  }
  .filetypes {
    flex-basis: 100%;
    max-width: 100%;
    @include min(768) {
      flex-basis: calc(100% - 200.5px);
      max-width: 54%;
    }
    @include min(1600) {
      padding-left: size(30);
    }
    @include max(767.98) {
      padding-top: size(14);
    }
    p {
      color: $text-alt;
      font-size: size(11);
      @include min(992) {
        font-size: size(12);
      }
      @include max(767.98) {
        font-size: size(12);
      }
    }
  }
}

.error {
  .uploadwraper .uploadlabel .fileupload {
    border-color: $red !important;
  }
}
/* Drop Box */
.dropbox {
  .droplabel {
    line-height: 0;
    display: block;
    width: 158px;
    max-width: 100%;
    margin-right: auto;
    margin-left: 25px;
    position: relative;
    margin-top: 0;
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.5s);
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 180px;
      height: 180px;
      background-color: #fff4ea;
      @include border-radius(50%);
      left: -25px;
      @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.5s);
    }
    .dropthumb {
      margin: 0;
      position: relative;
      padding-bottom: calc((169 / 158) * 100%);
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        -o-object-fit: contain;
        left: 0;
        pointer-events: none;
      }
    }
  }
  .file-drop {
    position: relative;
    height: 180px;
    width: 100%;
    & > .file-drop-target {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      text-align: center;
      border: 3px solid transparent;
      @include border-radius(15px);
      @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
      /* background-color: rgba($primary-color,.1);
            border-color: $primary-color; */
    }
    & > .file-drop-target.file-drop-dragging-over-frame,
    & > .active {
      background-color: rgba($primary-color, 0.1);
      box-shadow: none;
      z-index: 50;
      opacity: 1;
      color: white;
      border-color: $primary-color;
      .droplabel {
        width: 30px;
        opacity: 0.7;
        margin-top: 10px;
        margin-left: 10px;
        &::before {
          width: 30px;
          height: 30px;
          left: -6px;
          background-color: $white;
        }
      }
    }
    & > .file-drop-target.file-drop-dragging-over-target,
    & > .active {
      border-color: rgba($primary-color, 0.6);
    }
  }
}

/* Pagination */
.pagination {
  justify-content: center;
  margin-top: size(40) !important;
  @include min(1200) {
    margin-top: size(50) !important;
  }
  * {
    display: flex;
    align-items: center;
  }
  .page-item {
    .page-link {
      font-weight: $font-semibold;
      color: $black;
      background-color: transparent !important;
      border: none;
      padding: 0 12px;
      line-height: 26px;
      svg {
        fill: $primary-color;
        transition: all cubic-bezier(0.38, 0.67, 1, 1) 0.2s;
      }
    }
    a {
      &:hover {
        color: $primary-color;
        svg {
          fill: rgba($secondary-color, 1);
        }
      }
    }
    &.active {
      .page-link {
        color: $primary-color;
      }
    }
    &.disabled {
      .page-link {
        svg {
          fill: #bababa;
        }
      }
    }
  }
}

/* Form Arabic View */
html[dir="rtl"] {
  .form-select button {
    background-position: left 0 center !important;
    text-align: right;
    padding-left: size(20);
    padding-right: 0 !important;
  }
  .uploadwraper {
    .uploadlabel {
    }
    .filetypes {
      @include min(1600) {
        padding-left: 10px;
        padding-right: size(30);
      }
    }
    .fileupload {
      span {
        padding-left: 0;
        padding-right: size(12);
      }
    }
  }
  .dropbox {
    .droplabel {
      margin-right: 25px;
      margin-left: auto;
    }
    .file-drop {
      & > .file-drop-target.file-drop-dragging-over-frame,
      & > .active {
        .droplabel {
          margin-left: auto;
          margin-right: 10px;
        }
      }
    }
  }
  .pagination {
    .page-item {
      &:first-child,
      &:last-child {
        transform: scale(-1);
        display: flex;
        align-items: center;
        .page-link {
          display: flex;
          align-items: center;
          svg {
            fill: #bababa;
          }
          &:hover {
            svg {
              fill: $primary-color;
            }
          }
          & > * {
            &:first-child {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
/* Accordion */
.accordionview {
  position: relative;
  .accordion-item {
    position: relative;
    border-width: 2px 2px 2px;
    @include border-radius(0px);
    border-color: transparent;
    border-bottom-color: #e9e8ec;
    background-color: transparent;
    &:not(.active) {
      .accordion-button {
        &:hover {
          color: rgba($primary-color, 1) !important;
        }
      }
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      height: 2px;
      top: -2px;
      background-color: rgba(#e9e8ec, 1);
    }
    & + .accordion-item {
      &::before {
        opacity: 0;
      }
    }
    &.active {
      &::before {
        // background-color: $primary-color;
        opacity: 1 !important;
      }
    }
  }
  .accordion-header {
    position: relative;
    @include min(992) {
      padding-right: 25px;
      padding-left: 25px;
    }
    button {
      background-color: transparent;
      padding: 25px 0;
      padding-right: 25px;
      font-size: size(18);
      font-weight: $font-medium;
      @include min(768) {
        font-size: size(20);
      }
      @include min(1200) {
        font-size: size(24);
      }
      &::before,
      &::after {
        content: "";
        width: 16px;
        height: 2px;
        @include border-radius(4px);
        background: $primary-color;
        position: absolute;
        right: 0;
        @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
        transform: rotate(0deg);
      }
      /* &::before {
                display: none;
            } */
      &:not(.collapsed) {
        color: $primary-color;
      }
      &.collapsed {
        color: $black;
        &::before {
          display: block;
          transform: rotate(-90deg);
        }
        &::before,
        &::after {
          background: $black;
        }
      }
    }
  }
  .accordion-body {
    position: relative;
    padding: 0 0 25px;
    @include min(992) {
      padding-right: 25px;
      padding-left: 25px;
    }
    color: $black;
    &::before {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      height: 2px;
      bottom: -2px;
      background-color: $primary-color;
    }
    header {
      font-size: size(18);
      font-weight: $font-medium;
      & + p {
        margin-top: 20px !important;
      }
    }
  }
  &.secondary {
    .accordion-item {
      &.active {
        &::before {
          // background-color: $primary-color;
        }
      }
    }
    .accordion-header {
      button {
        color: $black !important;
        &::before,
        &::after {
          background: $primary-color;
        }
        &.collapsed {
          &::before,
          &::after {
            background: #707070;
          }
        }
      }
    }
    .accordion-body {
      &::before {
        background-color: $primary-color !important;
      }
    }
  }
}

/* modal */
.modal-dialog {
  &.modal-lg {
    @include min(768) {
      max-width: 600px !important;
    }
    /* @include min(1200) {
      .modal-content {
        min-height: 740px;
      }
    } */
  }
  &.modal-dialog-centered {
    .modal-body {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      & > * {
        flex-basis: 100%;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .modal-content {
    border: none;
    @include border-radius(20px);
  }
  .modal-header {
    border: none;
    position: relative;
    min-height: 42px;
    padding: size(20) size(35) 0;
    @include min(768) {
      padding: size(20) size(60) 0;
    }
    @include min(1200) {
      padding: size(22) size(87) 0;
    }
    .btn-close {
      position: absolute;
      bottom: 0;
      right: 20px;
    }
  }
  .modal-body {
    padding: size(10) size(35) size(30);
    @include min(768) {
      padding: size(10) size(60) size(40);
    }
    @include min(1200) {
      padding: size(10) size(87) size(63);
    }
  }
}

/* Modal Arabic  */
html[dir="rtl"] {
  .modal-dialog {
    .modal-header {
      .btn-close {
        right: auto;
        left: 20px;
      }
    }
  }
}
.dropdown {
  &.show {
    z-index: 3;
  }
}
.dropdownbtn {
  display: inline-block;
  @include max(767.98) {
    width: 100%;
  }
  & + .dropdown {
    @include min(768) {
      margin-left: 20px;
    }
    @include min(1200) {
      margin-left: 30px;
    }
    @include max(767.98) {
      margin-top: size(20);
    }
  }
  & > button {
    color: $black !important;
    box-shadow: none !important;
    width: 245px;
    max-width: 100%;
    padding: 0 37px 0 26px;
    text-align: left;
    display: flex;
    align-items: center;
    line-height: 46px;
    height: 48px;
    @include min(1200) {
      line-height: 48px;
      height: 50px;
    }
    svg {
      margin-right: size(12);
    }
    @include max(767.98) {
      width: 100%;
    }
    &::after {
      content: "";
      border: none !important;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.629' height='6.374' viewBox='0 0 10.629 6.374'%3E%3Cpath id='Path_76772' data-name='Path 76772' d='M16171.41-16115.123a.747.747 0 0 1-.53-.22.75.75 0 0 1 0-1.061l4.033-4.033-4.033-4.035a.75.75 0 0 1 0-1.061.75.75 0 0 1 1.061 0l5.094 5.1-5.094 5.094A.747.747 0 0 1 16171.41-16115.123Z' transform='translate(-16115.123 -16170.66) rotate(90)' fill='%230e1728'/%3E%3C/svg%3E");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 11px;
      height: 11px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 26px;
    }
    &:hover {
      @include min(992) {
        color: $white !important;
      }
      svg {
        @include min(992) {
          fill: $white;
        }
      }
      &::after {
        @include min(1200) {
          filter: brightness(20);
        }
      }
    }
  }
  .dropdown-menu {
    padding: 17px 0;
    @include border-radius(25px);
    border-color: $line-color;
    margin-top: size(8);
    width: 100%;
    overflow-x: hidden;
    max-height: 210px;

    .dropdown-item {
      padding: 12px 30px;
      /* @include border-radius(25px); */
      color: $black;
      font-weight: $font-medium;
      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
}
html[dir="rtl"] {
  .dropdownbtn {
    button {
      svg {
        margin-right: 0;
        margin-left: size(12);
      }
    }
    .dropdown-menu {
      text-align: right;
    }
  }
}
.bounceanim {
  animation: smoothbounce 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shine;
  -webkit-animation-timing-function: linear;
}
.lines {
  height: 16px;
  width: 60px;
  max-width: 100%;
  @include min(1600) {
    width: 70px;
  }
}
.loading {
  background-color: #efefef;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: loading 1s infinite;
  img[src=""] {
    visibility: hidden;
  }
}

.loader_gif {
  position: fixed;
  z-index: 9999;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.38, 0.67, 1, 1);
  background-color: $white;
  z-index: 1;
  height: calc(100vh - 62px);
  top: 62px;
  @include min(768) {
    height: calc(100vh - 80px);
    top: 80px;
  }
  img {
    max-width: 100px;
    display: block;
  }
}
img[src] {
  opacity: 1;
}
img {
  opacity: 0;
  transition: all cubic-bezier(0.38, 0.67, 1, 1) 0.2s;
}
// [data-anim="fade-in"] {
//     transform: translateY(10px);
//     opacity: 0;
//     transition: 0.5s all cubic-bezier(0.38, 0.67, 1, 1);

//     &.visible {
//       opacity: 1;
//       transform: initial;
//     }

//   }

//   .anim {
//       opacity: 0;
//       transform: translateY(35px);
//       transition: transform 0.75s cubic-bezier(0.38, 0.005, 0.215, 1), opacity 0.75s cubic-bezier(0.38, 0.005, 0.215, 1);
//       &.vissible {
//           opacity: 1;
//           transform: translateY(0px);
//       }
//   }
.nogap {
  .modal {
    &-dialog {
      max-width: 100%;
      .modal {
        &-header {
          margin-bottom: 15px;
          .btn-close {
            right: 0;
            filter: brightness(10);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='19' height='19' viewBox='0 0 19 19'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_17378' data-name='Rectangle 17378' width='19' height='19' transform='translate(18372.5 -19494.699)' fill='rgba(0 0 0 0.36)'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_25259' data-name='Mask Group 25259' transform='translate(-18372.5 19494.699)' clip-path='url(%23clip-path)'%3E%3Cg id='Group_64684' data-name='Group 64684' transform='translate(20350.541 -23595.893) rotate(45)'%3E%3Cpath id='Line_1289' data-name='Line 1289' d='M1 24.362H-1V0H1Z' transform='translate(1514.681 4286.5)' fill='%23fff'/%3E%3Cpath id='Line_1290' data-name='Line 1290' d='M1 24.362H-1V0H1Z' transform='translate(1526.862 4298.681) rotate(90)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
            opacity: 1;
            transition: opacity linear 0.2s;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
    &-content {
      background-color: transparent;
      margin: auto;
      width: auto;
      max-width: calc(100% - 20px);
    }
    &-header {
      padding-left: 0;
      padding-right: 0;
    }
    &-body {
      padding: 0 !important;
    }
  }
}

.spinner {
  > * {
    width: 10px;
    height: 10px;
    background-color: $white;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    margin: 2px;
  }
  &.primary {
    > * {
      background-color: $primary-color;
    }
  }
  &.secondary {
    > * {
      background-color: $secondary-color;
    }
  }
  &_1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &_2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.success_messsage {
  padding: 6px 10px !important;
  line-height: 1;
  background-color: rgba($primary-color, 0.3);

  color: rgba($primary-color, 1);
  font-size: size(13);
  margin-top: size(20);
  text-align: center;
}
main {
  min-height: 100vh;
}

html {
  body {
    .widget-visible {
      bottom: 40px !important;
    }
  }
}

