@import '../../styles/utilities.scss';
.servicebnr {
    opacity: 0;
    padding: 0 0 size(40);
    position: relative;
    min-height: 625px;
    @include min(576) {
        min-height: 665px;
    }
    @include min(768) {
        padding: 0 0 size(40);
        min-height: 845px;
    }
    @include min(992) {
        padding: 0 0 size(40);
        min-height: 415px;
    }
    @include min(1200) {
        padding: 0 0 size(80);
        min-height: 552px;
    }
    @include min(1600) {
        padding: 0 0 size(90);
        min-height: 630px;
    }
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1),0.3s);
    .circleshape {
        pointer-events: none;
        background: rgb(255,205,93);
        background: radial-gradient(circle, rgba(255,205,93,1) 0%, rgba(249,249,249,0) 100%);
        position: absolute;
        @include border-radius(50%);
        opacity: .4;
        filter: blur(80px);
        z-index: -1;
        width: 320px;
        height: 270px;
        right: -20px;
        top: 150px;
        @include min(768) {
            width: 500px;
            height: 500px;
            right: 40px;
        }
        @include min(1200) {
            width: 850px;
            height: 850px;
            opacity: .24;
            filter: blur(50px);
        }
        @include max(767.98) {
            top: 170px;
        }
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        &>* {
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
        .textcol {
            padding-top: size(40);
            @include min(768) {
                padding-top: size(50);
            }
            @include min(992) {
                padding-top: size(60);
                fallback: 48%;
                max-width: 48%;
            }
            @include min(1200) {
                padding-top: size(80);
            }
            @include min(1600) {
                padding-top: size(90);
            }
            @include max(991.98) {
                order: 2;
            }
            .textwrap {
                max-width: 100%;
                &>* {
                    // transition: all ease .3s;
                    transform: translateY(30px);
                    opacity: 0;
                    transition: transform .75s cubic-bezier(.38,.005,.215,1),opacity .75s cubic-bezier(.38,.005,.215,1),-webkit-transform .75s cubic-bezier(.38,.005,.215,1);
                }
                @include min(992) {
                    width: 535px;
                }

                .smtitle {
                    font-weight: $font-medium;
                    color: $primary-color;
                    line-height: 1;
                    font-size: size(16);

                    transition-delay: .1s;
                    @include min(768) {    
                        font-size: size(18);
                    }
                    @include min(1200) {    
                        font-size: size(20);
                    }
                }
                .maintitle {
                    font-size: 30px; 
                    margin-bottom: 0;
                    transition-delay: .2s;
                    @include min(768) {    
                        font-size: size(30);
                    }
                    @include min(992) {    
                        font-size: size(35);
                    }
                    @include min(1200) {    
                        font-size: size(40);
                        padding-bottom: size(5);
                    }
                    @include min(1600) {    
                        font-size: size(50);
                        padding-bottom: size(15);
                    }
                    span {
                        display: inline-block;
                    }
                }
                p {
                    transition-delay: .3s;
                }
                .bnrbtn {
                    transition-delay: .4s;
                    margin-top: size(20);
                    min-width: 137px;
                    @include min(768) {    
                        margin-top: size(30);
                    }
                    @include min(1200) {    
                        margin-top: size(40);
                        min-width: 164px;
                        padding-left: size(30);
                        padding-right: size(30);
                        height: 60px;
                        border-radius: 30px;
                        line-height: 58px;
                    }
                    @include min(1600) {    
                        margin-top: size(50);
                    }
                    @include max(991.98) {
                        background-color: $primary-color;
                        border-color: $primary-color;
                        color: $white;
                        &:hover {
                            background-color: $secondary-color;
                            border-color: $secondary-color;
                        }
                    }
                }
            }
        }
        .imgcol {
            position: relative;
            @include min(992) {
                fallback: 52%;
                max-width: 52%;
            }
            @include max(991.98) {
                order: 1;
            }
            .figwrap {
                width: 673px;
                max-width: 100%;
                transition: all cubic-bezier(0.38, 0.67, 1, 1) .3s;
                opacity: 0;
                &.lg {
                    width: 732px;
                }
            }
            .imgsec {
                margin: 0;
                position: relative;
                padding-bottom: calc((534/673)*100%);
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    &:global(.loaded), &.loaded {
       opacity: 1;
       height: auto !important;
       min-height: 1px !important;
       .wrapper {
           .textcol {
               .textwrap {
                   &>* {
                       transform: translateY(0) scale(1);
                       opacity: 1;
                   }
               }
           }
           .imgcol {
               .figwrap {
                    // transform: translateY(0) scale(1);
                    opacity: 1;
               }
           }
       }
   }
}
html[dir=rtl] {
    .servicebnr {
        .circleshape {
            left: -20px;
            @include min(768) {
                left: 40px;
            }
            right: auto !important;
        }
    }
}