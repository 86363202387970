@import '../../styles/utilities.scss';

.branchloc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;
    padding-top: size(30);
    @include min(992) {
        padding-top: size(35);
        padding-bottom: size(5);
    }
    @include min(1200) {
        padding-top: size(40);
        padding-bottom: size(10);
    }
    @include min(1600) {
        padding-top: size(50);
        padding-bottom: size(20);
    }
    &>* {
        padding: 0 10px 30px;
        flex-basis: 100%;
        max-width: 100%;
        @include min(768) {
            flex-basis: 33.33%;
            max-width: 33.33%;
            &:nth-child(1) {
                text-align: left;
            }
            &:nth-child(2) {
                text-align: center;
            }
            &:nth-child(3) {
                text-align: right;
            }
        }
    }
}
html[dir=rtl] {
    .branchloc {
        .locitem {
            .locname {
                .icon {
                    margin-right: 0;
                    margin-left: size(16);
                }
            }
        }
    }
}