@import "../../styles/utilities.scss";
.partnersec {
  margin-bottom: size(50);
  @include min(768) {
    margin-bottom: size(60);
  }
  @include min(992) {
    margin-bottom: size(80);
  }
  @include min(1200) {
    margin-bottom: size(130);
  }
  @include min(1600) {
    margin-bottom: size(150);
  }
  h2 {
    @include min(1200) {
      font-weight: 500 !important;
    }
  }
}
.outerwrap {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .items {
      margin: 0 !important;
      display: flex;
      align-items: center;
    }
  }
}
.slider {
  pointer-events: none;
  @include max(767.98) {
    overflow: visible;
  }
  & > :global(.swiper-wrapper) {
    transition-timing-function: linear;
  }
  .items {
    margin: 0 !important;
    display: flex;
    align-items: center;
    .imgwrap {
      transition: all cubic-bezier(0.38, 0.67, 1, 1) 0.3s;
      @include min(1200) {
        // filter: grayscale(1);
        &:hover {
          // filter: grayscale(0);
        }
      }
    }
    img {
      @include max(1199.98) {
        max-height: 65px;
      }
      @include max(991.98) {
        max-height: 47px;
      }
      @include max(767.98) {
        max-height: 65px;
      }
      @include max(479.98) {
        max-height: 50px;
      }
    }
  }
}
