@import '../../styles/utilities.scss';
.circleone, .circletwo {
    @include min(768) {
        background: rgb(255,155,93);
        background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
        position: absolute;
        @include border-radius(50%);
        filter: blur(50px);
        opacity: .45;
        z-index: -1;
        width: 500px;
        height: 500px;
    }
    @include min(1200) {
        width: 700px;
        height: 700px;
    }
}
.circleone {
    @include min(768) {
        right: -450px;
        top: 250px;
    }
    @include min(1200) {
        right: -600px;
        top: 350px;
    }
}
.circletwo {
    @include min(768) {
        left: -450px;
        bottom: 600px;
    }
    @include min(1200) {
        left: -600px;
    }
}
.terms_policy {
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
    h2 {
        margin: 0 !important;
        padding-bottom: size(10);
    }
    
    * {
        &+.title {
            margin-top: size(35);
        }
        &+h2 {
            margin-top: size(35) !important;
            @include min(992) {
                margin-top: size(45) !important;
            }
            @include min(1200) {
                margin-top: size(65) !important;
            }
        }
    }
    .title {
        font-size: size(16);
        font-weight: $font-medium;
        @include min(768) {
            font-size: size(18);
        }
        & {
            +* {
                margin-top: size(18) !important;
                @include min(768) {
                    margin-top: size(24) !important;
                }
            }
        }
    }
}
.termsandc_mainhead {
    h1 {
        font-size: size(25);
        margin-bottom: 0 !important;
        padding-bottom: size(35);
        @include min(768) {    
            font-size: size(30);
            padding-bottom: size(50);
        }
        @include min(992) {    
            font-size: size(35);
        }
        @include min(1200) {    
            font-size: size(40);
            padding-bottom: size(60);
        }
        @include min(1600) {    
            font-size: size(50);
            padding-bottom: size(70);
        }
        span {
            display: inline-block;
        }
    }
}
.cntsec {
    white-space: pre-line;
}