@import '../../styles/utilities.scss';
.smcontainer {
    max-width: 1033px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

@include min(768) {
    .circleshape {
        pointer-events: none;
        position: absolute;
        z-index: -1;
        filter: blur(50px);
        background: rgb(255,155,93);
        background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
        opacity: .75;
        top: 22%;
        left: -400px;
        width: 400px;
        height: 400px;
        @include border-radius(50%);
        @include min(1200) {
            left: -600px;
            width: 600px;
            height: 600px;
        }
    }
}
.realtedtopics {
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(100);
    }
    @include min(1600) {
        margin-bottom: size(130);
    }
    &>:global(.container) {
        padding-right: 0;
    }
}
.titlesec {
    margin-bottom: size(25);
    @include min(768) {
        margin-bottom: size(35);
    }
    @include min(1200) {
        margin-bottom: size(45);
    }
    @include min(1600) {
        margin-bottom: size(55);
    }
    h2 {
        margin-bottom: 0;
    }
}
.postlistwrap {
    display: flex;
    justify-content: space-between;
    @include max(991.98) {
        overflow: auto;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    @include min(992){
        margin-left: -15px;
        margin-right: -15px;
    }
    &>* {
        padding-right: 20px;
        flex-basis: 420px;
        width: 420px;
        max-width: 100%;
        min-width: 250px;
        @include min-max(768,991) {
            min-width: 327px;
        }
        @include min(992){
            max-width: 50%;
            padding-left: 15px;
            padding-right: 15px;
        }
        @include min(1200) {
            max-width: 33.33%;
        }
    }
    .newitem {
        display: block;
        position: relative;
        height: 100%;
        text-decoration: none;
        color: $text-off;
        font-weight: $font-regular;
        @include border-radius(15px);
        /* @include overflow(hidden); */
        border: 1px solid #D1D1D1;
        background-color: $white;
        transform: translateY(0);
        &:hover {
           @include min(1200) {
            transform: translateY(-3px);
           }
        }
        .details {
            padding: 30px 20px 20px;
            height: 100%;

            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            padding: 20px 20px 30px;
            @include min(768) {
                padding: 25px 25px 30px;
            }
            @include min(992) {
                padding: 35px 30px 35px;
            }
            @include min(1200) {
                padding: 50px 35px 40px;
            }
            .postitle {
                font-size: size(18);
                color: $black;
                margin-bottom: size(28);
                font-weight: $font-medium;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
                @include min(768) {
                    margin-bottom: size(40);
                }
                @include min(992) {
                    margin-bottom: size(55);
                }
                @include min(1200) {
                    margin-bottom: size(74);
                }
            }
            .cntsec {
                margin-bottom: size(20);
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;  
                overflow: hidden;
                p {
                    &+p {
                        margin-top: size(10) !important;
                    }
                }
            }
        }
        .figwrap {
            width: 325px;
            max-width: 100%;
            .postimg {
                margin: 0;
                @include border-radius(15px 15px 0 0);
                @include overflow(hidden);
                position: relative;
                padding-bottom: calc((162.65/325)*100%);
                width: calc(100% + 2px);
                left: -1px;
                top: -1px;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &+.details {
                @include border-radius(0 0 15px 15px);
                height: auto;
            }
        }
        .postdate {
            margin-top: auto;
            font-size: size(14);
        }
    }
}

.btnwrap {
    text-align: center;
    margin-top: size(35);
    @include min(992) {
        margin-top: size(40);
    }
    @include min(1200) {
        margin-top: size(50);
    }
    @include min(1600) {
        margin-top: size(70);
    }
    @include max(991.98) {
        padding-right: 20px;
    }
    .viewmore {
        @include max(991.98) {
            min-width: auto;
            padding-left: size(25);
            padding-right: size(25);
        }
    }
}