@import '../../styles/utilities.scss';

.titledropdownsec {
    text-align: center;
    position: relative;
    z-index: 1;
}
.dropdownwrap {
    margin: size(40) 0 size(45);
    .dropdown {
        display: inline-block;
        @include max(767.98) {
            width: 100%;
        }
        &+.dropdown {
            @include min(768) {
                margin-left: 20px;
            }
            @include min(1200) {
                margin-left: 30px;
            }
            @include max(767.98) {
                margin-top: size(20);
            }
        }
        button {
            background-color: #f2f2f2 !important;
            border-color: #f2f2f2 !important;
            color: $black !important;
            box-shadow: none !important;
            min-width: 245px;
            max-width: 100%;
            width: auto;
            padding: 0 42px 0 26px;
            text-align: left;
            display: flex;
            align-items: center;
            line-height: 46px;
            height: 48px;
            transition: all cubic-bezier(0.38, 0.67, 1, 1) .3s;
            @include min(1200) {
                line-height: 48px;
                height: 50px;
            }
            svg {
                margin-right: size(12);
            }
            @include max(767.98) {
                width: 100%;
            }
            &::after {
                content:'';
                border: none !important;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.629' height='6.374' viewBox='0 0 10.629 6.374'%3E%3Cpath id='Path_76772' data-name='Path 76772' d='M16171.41-16115.123a.747.747 0 0 1-.53-.22.75.75 0 0 1 0-1.061l4.033-4.033-4.033-4.035a.75.75 0 0 1 0-1.061.75.75 0 0 1 1.061 0l5.094 5.1-5.094 5.094A.747.747 0 0 1 16171.41-16115.123Z' transform='translate(-16115.123 -16170.66) rotate(90)' fill='%230e1728'/%3E%3C/svg%3E");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 11px;
                height: 11px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 26px;
            }
            &:hover {
               @include min(1200) {
                color: $white !important;
                svg {
                    fill: $white;
                }
                &::after {
                    filter: brightness(20);
                }
               }
            }
        }
        :global(.dropdown-menu) {
            padding: 17px 0;
            @include border-radius(25px);
            border-color: $line-color;
            margin-top: size(8);
            width: 100%;
            overflow-x: hidden;
            background-color: $white;
            :global(.dropdown-item) {
                padding: 12px 30px;
                /* @include border-radius(25px); */
                color: $black;
                font-weight: $font-medium;
                &:hover {
                    background-color: #f3f3f3;
                }
            }
        }
    }
}

html[dir=rtl] {
    .dropdownwrap {
        .dropdown {
            &+.dropdown {
                @include min(768) {
                    margin-right: 20px;
                    margin-left: auto !important;
                }
                @include min(1200) {
                    margin-right: 30px;
                }
            }
            :global(.dropdown-menu) {
                :global(.dropdown-item) {
                    text-align: right;
                }
            }
            button {
                padding: 0 26px 0 37px;
                text-align: right;
                svg {
                    margin-right: 0;
                    margin-left: size(12);
                }
                &::after {
                    left: 26px;
                    right: auto;
                }
            }
        }
    }
}
.titledropdownsec>h2,.dropdownwrap>*{
    opacity: 0;
    transform: translateY(30px);
    transition: all cubic-bezier(0.38, 0.67, 1, 1) .3s;
    transition: transform .75s cubic-bezier(.38,.005,.215,1),opacity .75s cubic-bezier(.38,.005,.215,1),-webkit-transform .75s cubic-bezier(.38,.005,.215,1);
}
.jobresultsec {
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
    @include min(1600) {
        margin-bottom: size(150);
    }
    min-height: 492px;
    // transition: min-height linear .3s;
    &.loaded {
        height: auto !important;
        min-height: 1px !important;
        .titledropdownsec>h2,.dropdownwrap>*{
            opacity: 1;
            transform: translateY(0px);
        }
        .titledropdownsec {
            .dropdownwrap{
                &>* {
                    transition-delay: .1s;
                    &:nth-child(2) {
                        transition-delay: .15s;
                    }
                }
            }
        }
    }
}