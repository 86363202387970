@import '../../styles/utilities.scss';
.applynowsec {
    margin-bottom: size(50);
    @include min(768) {
        margin-top: 0;
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(120);
    }
}
.sectitle {
    padding-bottom: size(20);
    h2 {
        margin-bottom: 0 !important;
    }
}
.applynowform {
    width: 955px;
    max-width: 100%;
}