@import "../../../styles/utilities.scss";

:global(.search_is_open) {
  @include min(1200) {
    padding-right: 5px;
  }

  .siteheader {
    @include min(1200) {
      padding-right: 5px;
    }
  }
}

:global(.slim-header) {
  --header-top-space: 0;
}

.siteheader {
  position: fixed;
  top: -90px;
  width: 100%;
  left: 0;
  z-index: 99;
  background-color: $white;
  margin-right: 0;
  transition: background-color ease 0s, width ease 0s;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    pointer-events: none;

    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  }

  &.loaded {
    top: 0;
  }

  &_container {
    position: relative;

    @include min(1200) {
      max-width: 1670px;
    }
  }

  .headerwrap {
    padding: 0 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    background-color: $white;
    transition: padding-top ease-in-out .15s;
    will-change: padding-top;

    @include min(1200) {
      padding-top: var(--header-top-space, 10px);
    }

    /* 
    @include min(1600) {
        min-width: 1395px;
    } */
    &>* {
      padding-right: 10px;
      padding-left: 10px;
    }

    .logosec {
      padding-top: 11px;
      padding-bottom: 11px;
      flex-basis: 215px;
      max-width: 215px;

      @include min(1024) {
        img {
          width: 240px;
          max-width: 100%;
        }
      }

      @include min(1200) {
        flex-basis: 240px;
        max-width: 240px;
      }

      @include min(1600) {
        flex-basis: 300px;
        max-width: 300px;
      }

      @include max(1199.98) {
        flex-basis: 30%;
        max-width: 30%;
      }

      @include max(767.98) {
        flex-basis: 50%;
        max-width: 50%;
      }

      a {
        display: inline-block;
        outline: none;
      }
    }

    .headeright {
      display: flex;
      align-items: center;
      flex-basis: calc(100% - 215px);
      max-width: calc(100% - 215px);
      justify-content: flex-end;

      @include min(1200) {
        flex-basis: calc(100% - 240px);
        max-width: calc(100% - 240px);
        position: relative;
      }

      @include min(1600) {
        flex-basis: calc(100% - 300px);
        max-width: calc(100% - 300px);
      }

      @include max(1199.98) {
        flex-basis: 70%;
        max-width: 70%;
        padding-right: 60px;
        justify-content: flex-end;

        @include min(768) {
          position: relative;
        }
      }

      @include max(767.98) {
        flex-basis: 50%;
        max-width: 50%;
        padding-right: 65px;
      }
    }
  }
}

.back-to-screen {
  display: none;
  font-size: 17px;
  position: fixed;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  z-index: 1;
  cursor: pointer;
  right: 2px;
  top: 8px;
  transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  -webkit-transition: all ease-out 0.5s;
}

.header_search {
  cursor: pointer;
  margin-top: 0;
  display: flex;

  @include min(1200) {
    margin-left: size(20);
  }

  &_icon {
    width: 23px;

    @include min(1200) {
      margin-left: 10px;
    }

    svg {
      width: 21px;
      height: 21px;
    }

    @include min(1200) {
      width: 23px;
    }

    position: relative;
    overflow: visible;

    &::before {
      content: '';
      display: block;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      position: absolute;
    }
  }

  &_icon,
  &_icon_close {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0;
    line-height: 0;
    margin-left: auto;

    svg {
      width: 100%;
    }

    svg,
    path {
      fill: $black;
      pointer-events: none;
    }
  }

  &_wrap {
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 1;
    background-color: $white;
    padding: 5px 20px;

    @include min(576) {
      height: 65px;
    }

    @include min(768) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 50px;
      width: 659px;
      max-width: 80%;
      height: auto;
      padding: 0;
      left: auto;
    }

    @include min(1200) {
      right: 30px;
    }

    .header_search {

      &_icon,
      &_icon_close {
        width: 29px;
        display: flex;
        align-items: center;
      }

      &_icon {
        position: absolute;
        left: 20px;
        visibility: visible !important;
        height: 100%;
        justify-content: flex-start;

        @include min(768) {
          left: 0;
        }

        @include min(992) {
          top: 50%;
          transform: translateY(-50%);
        }

        &_close {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          height: 100%;
          justify-content: flex-end;

          @include min(768) {
            right: 0;
          }
        }
      }
    }

  }

  &_control {
    padding: 10px 30px;
    width: 100%;
    outline: none;
    border: 2px solid #ebebeb;
    border-width: 0 0 2px;
    border-radius: 0 !important;

    &:focus,
    &:not(:placeholder-shown) {
      border-color: $primary-color;
    }

    &::placeholder {
      color: currentColor;
      opacity: .6;
    }


  }

  &_result {
    padding: size(22) size(32);
    background-color: $white;
    top: 55px;
    border-radius: 16px;
    visibility: hidden;
    right: 0;
    width: 100%;
    max-width: 100%;
    position: fixed;
    display: none;

    @include min(768) {
      position: absolute;
      right: 50px;
      width: 659px;
      max-width: 80%;
      top: 87px;

      &::before {
        content: "";
        display: block;
        width: 105%;
        height: calc(110%);
        top: -10%;
        left: 0;
        border-radius: 16px;
        position: absolute;
        background-color: rgba($primary-color, 0.14);
        filter: blur(30px);
        z-index: -1;
        pointer-events: none;
      }

      @include min(1200) {
        right: 30px;
      }
    }

    @include max(767.98) {
      border-radius: 0 0 16px 16px;
      filter: drop-shadow(3px -9px 13px rgba($primary-color, 0.5));
    }

    &_item {
      padding: size(16) 0;
      display: flex;
      align-items: center;
      text-decoration: none;

      .link_icon {
        flex-basis: 15px;
        max-width: 15px;

        svg {
          fill: $black;
          transition: all ease 0.3s;
        }
      }

      &_text {
        flex-basis: calc(100% - 15px);
        max-width: calc(100% - 15px);
        font-size: size(16);
        font-weight: $font-medium;
        padding-left: size(14);
        color: $text-off;
        transition: all ease 0.3s;

        span {
          color: #939393;
          transition: all ease 0.3s;
        }
      }

      &:hover {
        @include min(1200) {
          .link_icon {
            svg {
              fill: $primary-color;
            }
          }

          .header_search_result_item_text {
            color: rgba($primary-color, 1);

            span {
              color: rgba($primary-color, 0.5);
            }
          }
        }
      }
    }
  }
}

:global(.search_open) {
  nav ul {
    opacity: 0;
    pointer-events: none;

    @include min(768) {
      transition: opacity linear 0.2s;
    }
  }

  .header_search {
    &_wrap {
      opacity: 1;
      pointer-events: all;

      @include min(768) {
        transition: opacity linear 0.3s;
      }
    }

    &_icon {
      visibility: hidden;
      width: 18px;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: #000 !important;
        }
      }

      @include min(1200) {
        width: 18px;
      }

      &_close {
        svg {
          path {
            fill: #000 !important;
          }
        }
      }
    }

    &_result {
      visibility: visible;
      display: block;
      padding-right: 0.4rem;

      .header_search_result_wrapper {
        max-height: 100vh;
        overflow: hidden;
        overflow-y: scroll;

        @include min(768) {
          max-height: 80vh;
        }
      }
    }
  }
}

html[dir="rtl"] {


  .header_search {

    &_result {
      padding-right: 0rem;
      padding-left: 0.4rem;
      padding: size(22) size(32);
    }
  }

  .headerwrap {

    .headeright {
      @include max(1199.98) {
        padding-right: 0;
        padding-left: 70px;
      }

      @include max(767.98) {
        padding-right: 0;
        padding-left: 55px;
      }


    }
  }

  .header_search {
    margin-right: auto;

    &_wrap {
      right: 0;

      @include min(768) {
        left: 50px;
        right: auto;
      }

      @include min(1200) {
        left: 30px;
      }

      .header_search {
        &_icon {
          left: auto !important;
          right: 20px;
          justify-content: flex-start;

          @include min(768) {
            right: 0;
          }

          &_close {
            left: 20px;
            right: auto !important;
            justify-content: flex-end;

            @include min(768) {
              left: 0;
            }
          }
        }
      }
    }

    &_result {
      left: 0;
      right: auto !important;

      @include min(768) {
        left: 50px;

        &::before {
          left: 0;
        }

        @include min(1200) {
          left: 30px;
        }
      }

      &_item {
        &_text {
          padding-right: size(14);
          padding-left: 0;
        }
      }
    }
  }
}

:global(.show-menu) {
  .header_search {
    visibility: hidden !important;
  }
}

.siteheader {
  &_home {
    :global(.search_open) {
      .header {
        &_search {

          &_control,
          &_wrap {
            @include min(1200) {
              background-color: transparent;
            }
          }

          &_icon,
          &_icon_close {

            svg,
            path {
              @include min(1200) {
                fill: $white !important;
              }
            }
          }

          &_control {
            @include min(1200) {
              color: $white !important;
            }
          }
        }
      }
    }
  }
}

:global(.slim-header),
:global(.loader-visible) {
  .siteheader {
    &_home {
      :global(.search_open) {
        .header {
          &_search {

            &_icon,
            &_icon_close {

              svg,
              path {
                fill: $black !important;
              }
            }

            &_control {
              color: $black !important;
            }
          }
        }
      }
    }
  }
}