@import '../../styles/utilities.scss';
.formtitle {
    &+p {
        margin-top: size(10) !important;
        font-weight: 500;
        opacity: 1;
        filter: blur(0px);
    }
}
.fotmheader {
    @include min(768) {
        padding-bottom: size(10);
    }
    @include min(1200) {
        padding-bottom: size(20);
    }
     + iframe{
        overflow: hidden !important;
        @include min(992){
            height: 425px;
        }
        @include max(991.98){
           min-height: 60vh;
        }
        &::-webkit-scrollbar{
            width: 0 !important;
            display: none !important;
            -ms-overflow-style: none !important;
            scrollbar-width: none !important; 
        }
    }
}
.submitbtn {
    width: 100%;
    font-size: size(18);
    &::before {
        z-index: 0 !important;
    }
    span {
        position: relative;
        z-index: 1;
    }
}
