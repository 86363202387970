@import '../../styles/utilities.scss';
.faqpage {
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
}
.titlehead {
    padding-bottom: size(40);
    @include min(992) {
        padding-bottom: size(50);
    }
    @include min(1200) {
        padding-bottom: size(60);
    }
    @include min(1600) {
        padding-bottom: size(70);
    }
}
.wrapper {
    opacity: 1;
    transition: opacity linear .3s;
    &_loading {
        opacity: 0;
        height: 500px;
        @include min(992) {
            height: 500px;
        }
        @include min(1200) {
            height: 550px;
        }
    }
    @include min(768) {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        &>* {
            padding-left: 10px;
            padding-right: 10px;
            flex-basis: 100%;
            max-width: 100%;
        }
        .tabcol {
            flex-basis: 35%;
            max-width: 35%;
        }
        .resultcol {
            flex-basis: 65%;
            max-width: 65%;
            .accordions {
                @include min(768) {
                    padding-left: size(25);
                }
            }
        }
    }
}
.dropdown {
    margin-bottom: size(35);
    &>button {
        
        border-color: $quinary-color !important;
        color: $quinary-color !important;
        background-color: transparent  !important;
        &:focus {
            background-color: transparent  !important;
            border-color: $quinary-color !important;
            color: $quinary-color !important;
        }
        &:hover {
            border-color: $quinary-color !important;
            color: $quinary-color !important;
            background-color: transparent  !important;
            @include min(1200) {
                background-color: $quinary-color !important;
                color: $white !important;
            }
        }
    }
}
.tabcol {
    @include max(767.98) {
        display: none;
    }
}


/* Tabs */
.tabwrapper {
    max-width: 300px;
    padding-bottom: size(20);
    ul {
        border-top: 2px solid $line-color;
        li {
            font-weight: $font-regular;
            line-height: 1.2;
            position: relative;
            border-bottom: 2px solid $line-color;
            padding: size(18) 0 size(18);
            cursor: pointer;
            color: $black;
            @include transition(all,linear,0.3s);
            font-size: size(18);
            &:hover {
                opacity: .8;
            }
            @include min(1200) {    
                font-size: size(20);
                padding: size(20) 0 size(20);
            }
            &.active {
                color: $primary-color;
                font-weight: $font-medium;
                border-color: $primary-color;
                opacity: 1;
            }
        }
    }
}


.btnwrap {
    text-align: center;
    margin-top: size(38);
    @include min(992) {
        margin-top: size(40);
    }
    @include min(1200) {
        margin-top: size(40);
    }
    @include min(1600) {
        margin-top: size(50);
    }
}
