@import '../../styles/utilities.scss';
.fieldwrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &>* {
        flex-basis: 100%;
        max-width: 100%;
        @include min(768) {
            flex-basis: 420px;
            max-width: calc(50% - 50px);
        }
        @include min(1600) {
            padding-top: size(20);
        }
    }
    /* :global(.form-control), :global(.form-select button) {
        @include max(767.98) {
            border-color: $black !important;
        }
    } */
    .submitbtn {
        display: block;
        width: 100%;
        max-width: 100%;
        &::before {
            z-index: 0 !important;
        }
        span {
            position: relative;
            z-index: 1;
        }
    }
}