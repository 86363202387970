@import "../../../styles/utilities.scss";

.locitem {
  text-align:start !important;
  display: block;
  max-width: 100%;

  @include min(768) {
    width: 350px;
    display: inline-flex;
    flex-direction: column;
    height: 100%;
  }

  @include max(767.98) {
    padding-bottom: 30px;
    border-bottom: 1px solid #e2e2e2;
  }
  .locname {
    font-size: size(20);
    font-weight: $font-semibold;
    display: inline-flex;
    align-items: flex-start;
    text-transform: uppercase;
    @include min(768) {
      font-size: size(18);
    }
    @include min(1200) {
      font-size: size(19);
    }
    @include min(1600) {
      font-size: size(20);
    }
    .icon {
      display: flex;
      align-items: center;
      width: 14px;
      margin-right: size(10);
      margin-top: size(5);
      @include min(992) {
        margin-right: size(16);
        margin-top: size(6);
      }
      @include min(1200) {
        margin-top: size(7);
      }
      svg {
        width: 14px;
        height: 16.55px;
        fill: $primary-color;
      }
    }
  }
  ul {
    position: relative;
    
    @include min(768) {
      padding-bottom: size(36.2);
    }
    li {
      color: $text-off;
      font-size: size(14.5);
      @include min-max(768, 1199.98) {
        font-size: size(14);
      }
      &.mob {
        font-weight: $font-semibold;
        @include min(768) {
          font-weight: $font-medium;
          a {
            font-weight: $font-medium;
          }
        }
        a {
          color: $text-off;
          text-decoration: none;
          /* cursor: default; */
          font-weight: $font-semibold;
          &:hover {
            color: $primary-color;
          }
        }
      }
      & + li {
        margin-top: size(20);
        @include min(1200) {
          margin-top: size(30);
        }
        @include min(1600) {
          margin-top: size(35);
        }
      }
    }
  }

  .date_time {
    white-space: pre-line;
    span {
      display: inline-block;
    }
    & > span {
      display: flex;
      span {
        font-weight: $font-medium;
        & + span {
          padding-left: 4px;
          font-weight: $font-regular;
          text-transform: uppercase;
        }
      }
      & + span {
        margin-top: 8px;
      }
    }
  }
  .addr {
    white-space: pre-line;
  }
  .arw_btn {
    margin-top: auto;
    position: relative;
    padding-top: size(22);
    @include min(768) {
      &::after,
      &::before {
        content: "";
        display: block;
        max-width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: initial;
      }
      &::before {
        width: 282.66px;
        background-color: rgba(#707070, 0.2);
      }
      &::after {
        width: 0;
        background-color: $primary-color;
        @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
      }
    }
  }
  &:hover {
    .arw_btn {
      &::after {
        width: 282.66px;
      }
    }
  }
}
html[dir="rtl"] {
  .locitem {
    .locname {
      .icon {
        margin-right: 0;
        margin-left: size(16);
      }
    }
  }
}
