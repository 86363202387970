@import '../../styles/utilities.scss';
.serviceprocessec {
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(140);
    }
    @include min(1600) {
        margin-bottom: size(170);
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
        &>* {
            padding-left: 15px;
            padding-right: 15px;
            flex-basis: 100%;
            max-width: 100%;
            @include min(992) {
                flex-basis: 50%;
                max-width: 50%;
            }
        }
        .textcol {
            position: relative;
            .circleshape {
                pointer-events: none;
                background: rgb(255,155,93);
                background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
                position: absolute;
                right: 40px;
                @include border-radius(50%);
                filter: blur(50px);
                z-index: -1;
                width: 130px;
                height: 130px;
                bottom: 0;
                opacity: .7;
                right: -50px;
                @include min(768) {
                    right: 10px;
                    width: 180px;
                    height: 180px;
                    opacity: .22;
                }
                @include min(1200) {
                    width: 250px;
                    height: 250px;
                }
            }
        }
        .textwrap {
            position: relative;
            height: 100%;
            @include min(992) {
                max-width: 489px;
            }
            @include max(991.98) {
                margin-bottom: size(50);
            }
            h2 {
                margin-bottom: 0;
                padding-bottom: size(10);
            }
            p {
                color: $black;
            }
            .btn {
                margin-top: size(20);
                @include min(768) {
                    margin-top: size(30);
                }
                @include min(992) {
                    margin-top: size(40);
                }
                @include min(1200) {
                    margin-top: size(50);
                }
            }
        }
        .tabwrapper {
            .steptabs {
                border-top: 1px solid $line-color;
                .tabitem {
                    font-weight: $font-regular;
                    line-height: 1.2;
                    position: relative;
                    border-bottom: 2px solid $line-color;
                    padding: 18px 0 18px;
                    cursor: pointer;
                    color: $black;
                    @include transition(all,ease-in-out,0.3s);
                    position: relative;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 0%;
                        height: 2px;
                        bottom: -2px;
                        background-color: $primary-color;
                    }
                    @include min(768) {    
                        padding: 22px 0 22px;
                    }
                    @include min(1600) {    
                        padding: 26px 0 26px;
                    }
                    .stepcount {
                        display: none;
                        color: $primary-color;
                        font-weight: $font-medium;
                        margin-bottom: size(17);
                        @include min(768) {
                            margin-bottom: size(18);
                        }
                    }
                    .itemtitle {
                        font-size: size(18);
                        font-weight: $font-semibold;
                        @include min(768) {    
                            font-size: size(19);
                        }
                        @include min(1200) {    
                            font-size: size(20);
                        }
                    }
                    .itemcnt {
                        display: none;
                        margin-top: size(5);
                    }
                }
                .active {
                    .tabitem {
                        &:before {
                            // width: 100%;
                            // @include transition(all,ease-in ,5s);
                            animation: progres 5s infinite linear;   
                        }
                        .stepcount {
                            display: block;
                        }
                        .itemcnt {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}