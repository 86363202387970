@import "../../styles/utilities.scss";
.servicecntsec {
  margin-bottom: size(50);
  @include min(768) {
    margin-bottom: size(60);
  }
  @include min(992) {
    margin-bottom: size(80);
  }
  @include min(1200) {
    margin-bottom: size(130);
  }
  @include min(1600) {
    margin-bottom: size(150);
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    align-items: center;
    & > * {
      padding-left: 10px;
      padding-right: 10px;
      flex-basis: 100%;
      max-width: 100%;
    }
    .textcol {
      @include min(992) {
        flex-basis: 50%;
        max-width: 50%;
        .textArea {
          max-width: 90%;
        }
      }
      @include max(991.98) {
        order: 2;
        // .textArea {
        //     text-align: center;
        // }
      }
    }
    .imgcol {
      @include min(992) {
        flex-basis: 50%;
        max-width: 50%;
      }
      @include max(991.98) {
        order: 1;
      }
    }
    &:not(:first-child) {
      margin-top: 50px;
      @include min(1200) {
        margin-top: size(45);
      }
    }
    &:nth-child(even) {
      @include min(992) {
        flex-direction: row-reverse;
        .textArea {
          margin-left: auto;
        }
        .figwrapper {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
}
.figwrapper {
  width: 539px;
  max-width: 100%;
  display: block;
  margin: 0 auto size(15);
  @include min(992) {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: 0;
  }
  @include max(991.98) {
    // margin-left: 0;
  }
  .imgwrap {
    width: 100%;
    padding-bottom: calc((408 / 539) * 100%);
    position: relative;
    @include border-radius(20px);
    @include overflow(hidden);
    margin: 0;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

html[dir="rtl"] {
  .figwrapper {
    @include min(992) {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
