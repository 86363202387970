@import "../../../styles/utilities.scss";
.sitefooter {
  background-color: $dark-bg;
  position: relative;
  padding: 35px 0 25px;
  @include min(768) {
    padding: 55px 0 35px;
  }
  @include min(1200) {
    padding: 76px 0 45px;
  }
  p {
    color: $white;
    a {
      color: $white;
      text-decoration: none;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .ftrcontacts {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: size(10);
    margin-left: -10px;
    margin-right: -10px;
    @include min(768) {
      margin-left: 10px;
      margin-right: 10px;
      padding-bottom: size(55);
    }
    @include min(1200) {
      padding-bottom: size(73.6);
    }
    .phcol {
      &_detail {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
        p {
          color: rgba(255, 255, 255, 0.75);
          font-size: 13.5px;
          @include min(1200) {
            font-size: 15px;
          }
          a {
            display: block;
            width: fit-content;
            font-size: size(18);
            @include min(1200) {
              font-size: size(21);
            }
            @include min(1600) {
              font-size: size(24);
            }
          }
        }
      }
    }
    .cols {
      flex-basis: 100%;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      &.phcol {
        @include min(768) {
          flex-basis: 19%;
          max-width: 19%;
        }
        @include min(992) {
          flex-basis: 19.7%;
          max-width: 19.7%;
        }
      }
      &.mailcol {
        @include min(768) {
          flex-basis: 36.8%;
          max-width: 36.8%;
        }
        @include min(992) {
          flex-basis: 37%;
          max-width: 37%;
        }
        @include min(1200) {
          flex-basis: 47%;
          max-width: 47%;
        }
      }
      &.newsletcol {
        @include min(768) {
          flex-basis: 100%;
          max-width: 100%;
        }
        @include min(992) {
          flex-basis: 43.3%;
          max-width: 43.3%;
        }
        @include min(1200) {
          flex-basis: 33.3%;
          max-width: 33.3%;
        }
        .titlesec {
          @include max(767.98) {
            text-align: center;
            color: $white;
            opacity: 1;
            padding-bottom: size(30);
            font-size: size(19);
            font-weight: $font-regular;
          }
        }
      }
    }
    .titlesec,
    .detail {
      color: $white;
    }
    .titlesec {
      opacity: 0.8;
      font-size: size(14);
      padding-bottom: size(10);
      @include min(992) {
        font-size: size(16);
        padding-bottom: size(20);
      }
    }
    .detail {
      font-size: size(18);

      @include min(992) {
        font-size: size(20);
      }
      @include min(1200) {
        font-size: size(22);
        margin-top: 0;
      }
      @include min(1600) {
        font-size: size(24);
      }
      & > * {
        font-size: size(18);
        @include min(992) {
          font-size: size(20);
        }
        @include min(1200) {
          font-size: size(22);
          margin-top: 0;
        }
        @include min(1600) {
          font-size: size(24);
        }
      }
    }
    .input_holder {
      position: relative;
      @include placeholder {
        color: rgba(#363636, 0.6);
        font-size: size(13);
        @include min(992) {
          font-size: size(14);
        }
      }
      &.error {
        .text_danger {
          position: absolute;
          left: 10px;
          bottom: -25px;
          font-size: 90%;
          color: $red;
        }
        .text_success {
          position: absolute;
          left: 15px;
          bottom: -25px;
          font-size: size(10);
          color: #32d332;
          white-space: nowrap;
          @include min(370) {
            font-size: size(12);
          }
          @include min(992) {
            font-size: size(13);
          }
        }
      }

      display: flex;
      align-items: center;
      .nwsltr {
        &_control {
          border: none;
          outline: none;
          box-shadow: none;
          padding: 0 25px;
          background-color: $white;
          @include border-radius(26px 0 0 26px);
          height: 51px;
          line-height: 51px;
          color: #363636;
          font-size: size(13);
          margin-right: 5px;
          flex-basis: calc(100% - 125px);
          max-width: calc(100% - 125px);
          @include min(768) {
            padding: 0 25px;
            flex-basis: calc(100% - 110px);
            max-width: calc(100% - 110px);
          }
          @include min(992) {
            font-size: size(14);
          }
          @include min(1200) {
            flex-basis: calc(100% - 131px);
            max-width: calc(100% - 131px);
          }
        }
        &_btn {
          @include border-radius(0 26px 26px 0);
          height: 51px;
          line-height: 47px;
          padding: 0 15px;
          min-width: 1px;
          font-size: size(15);
          flex-basis: 120px;
          max-width: 120px;
          border-width: 2px;
          &:hover {
            @include min(1200) {
              border-color: darken($primary-color, 20%);
              background-color: darken($primary-color, 20%);
            }
          }
          @include min(768) {
            flex-basis: 105px;
            max-width: 105px;
          }
          @include min(1200) {
            font-size: size(16);
            padding: 0 27px;
            flex-basis: 131px;
            max-width: 131px;
          }
        }
      }
    }
  }
  .ftr_menu_addr {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    @include min(768) {
      margin-left: 10px;
      margin-right: 10px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: calc(100% - 20px);
        height: 1px;
        background-color: rgba(#b1b1b1, 0.2);
        left: 10px;
        top: 0;
      }
      padding-top: size(50);
    }
    @include min(1200) {
      padding-top: size(66);
    }
    .titlesec {
      font-size: size(18);
      color: $white;
      font-weight: $font-semibold;
      padding-bottom: size(20);
      opacity: 0.75;
      @include min(768) {
        font-size: size(16);
        opacity: 1;
      }
      @include min(1200) {
        font-size: size(17);
        padding-bottom: size(25);
      }
      @include min(1600) {
        font-size: size(18);
        padding-bottom: size(30);
      }
    }

    .menuwrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 100%;
      ul {
        & + ul {
          margin-top: 0 !important;
        }
      }
      li {
        font-size: size(14);
        margin-bottom: size(10);
        &:nth-of-type(3) {
          color: $black;
        }
        &:nth-child(2) {
          color: $black;
        }
        @include min(1200) {
          font-size: size(16);
        }
        a {
          color: rgba($white, 0.75);
          text-decoration: none;
          font-weight: $font-regular;
          &:hover {
            color: $white;
            opacity: 1;
          }
        }
        &.active {
          a {
            color: $white;
          }
        }
      }
    }
    .reachout {
      max-width: 100%;
      @include min(768) {
        width: 235.69px;
        display: block;
        margin-left: auto;
      }
      @include max(767.98) {
        padding-top: size(25);
      }
      p {
        margin-top: 0 !important;
        color: rgba($white, 0.8);
        line-height: 1.75;
        a {
          color: rgba($white, 0.8);
          display: inline-block;
          &:hover {
            color: rgba($white, 1);
          }
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-width: 210px;
        margin-left: auto;
        margin-right: auto;
        @include min(768) {
          max-width: 245px;
          margin-left: -10px;
          margin-right: -5px;
        }
        @include min(1200) {
          margin-top: size(20) !important;
        }
        @include max(767.98) {
          margin-top: size(25) !important;
        }
        li {
          padding-right: 5px;
          a {
            padding: 5px;
            svg {
              fill: $white;
              @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.2s);
            }
            &:hover {
              svg {
                @include min(1200) {
                  fill: rgba($white, 0.6);
                  transform: scale(1.2);
                }
              }
            }
          }
        }
      }
      .logowrap {
        @include max(767) {
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          text-align: center;
        }
      }
      .logoText {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        @include min-max(768, 1200) {
          font-size: 12px;
        }
      }

      img {
        margin: 15px 0;
        margin-top: 30px;
      }
    }

    .cols {
      flex-basis: 100%;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      &.companycol {
        @include min(768) {
          flex-basis: 35%;
          max-width: 35%;
        }
        @include min(992) {
          flex-basis: 37%;
          max-width: 37%;
        }
        .menuwrap {
          // width: 276px;
          width: 190px;
          @include min(992) {
            width: 240px;
          }
          @include min(1200) {
            // width: 350px;
            width: 280px;
          }
          @include min(1600) {
            // width: 375px;
            width: 325px;
          }
        }
      }
      &.servicecol {
        @include min(768) {
          flex-basis: 35%;
          max-width: 35%;
        }
        @include min(992) {
          flex-basis: 39.5%;
          max-width: 39.5%;
        }
        .menuwrap {
          width: 317px;
          @include min(1200) {
            width: 390px;
          }
          @include min(1600) {
            width: 438px;
          }
        }
      }
      &.officecol {
        @include min(768) {
          flex-basis: 30%;
          max-width: 30%;
        }
        @include min(992) {
          flex-basis: 23.5%;
          max-width: 23.5%;
        }
      }
    }

    .mobileview {
      @include min(768) {
        display: none;
      }
      .menudropdown {
        &.active {
          & > .menu_item {
            button {
              color: rgba($white, 1);
            }
          }
        }
        .menu_item {
          background-color: transparent;
          border: none;
          @include border-radius(0);
          padding: size(25) 0;
          border-bottom: 1px solid rgba(#b1b1b1, 0.2);
          button {
            background-color: transparent;
            color: rgba($white, 0.8);
            padding: 0;
            font-size: size(18);
            font-weight: $font-semibold;
            &:after {
              width: 16px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.042' height='8.771' viewBox='0 0 16.042 8.771'%3E%3Cpath id='Path_78870' data-name='Path 78870' d='M801.721 7499.521a.748.748 0 0 1-.53-.22l-7.271-7.271a.75.75 0 0 1 1.061-1.061l6.741 6.74 6.74-6.74a.75.75 0 0 1 1.061 1.061l-7.271 7.271A.748.748 0 0 1 801.721 7499.521Z' transform='translate(-793.7 -7490.75)' fill='%23fff'/%3E%3C/svg%3E");
            }
            &:global(.collapsed) {
              &:after {
                transform: rotateY(180deg);
              }
            }
          }
          :global(.accordion-body) {
            padding: size(24) 0 0;
            ul {
              li {
                &.active {
                  & > a {
                    color: rgba($white, 1);
                  }
                }
                & + li {
                  margin-top: size(19);
                }
                a {
                  color: rgba($white, 0.75);
                  text-decoration: none;
                  font-size: size(15);
                  font-weight: $font-regular;
                  &:hover {
                    color: rgba($white, 1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ftr_btm {
    p {
      text-align: center;
      color: rgba($white, 0.6);
    }
  }
  .btm_strip {
    margin-top: size(25);
    padding: 35px 0 0;
    border-top: 1px solid rgba(#b1b1b1, 0.2);
    @include min(768) {
      margin-top: size(0);
      padding: 60px 0 0;
      border-top: none;
    }
    @include min(992) {
      padding: 60px 0 0;
    }
    @include min(1200) {
      padding: 70px 0 0;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: -10px;
      margin-right: -10px;
      justify-content: center;
      @include min(768) {
        margin-left: 10px;
        margin-right: 10px;
      }

      @include max(1199.98) {
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;
      }
      @include max(991.98) {
        text-align: center;
        align-items: center;
        align-content: center;
      }
      li {
        @include max(991.98) {
          text-align: center;
          align-items: center;
        }
        @include max(1199.98) {
        }
        &:last-child {
          @include max(767.98) {
            display: flex;
            flex-direction: column;
          }
        }
        font-size: size(15);
        color: rgba($white, 0.6);
        padding-left: 10px;
        padding-right: 10px;
        line-height: 1.3;

        // &:last-child {
        //   border: none;
        // }
        &:not(:last-child) {
          border-inline-end: 1px solid rgba($white, 0.4);
        }

        text-align: center;
        @include max(767.98) {
          text-align: center;
          width: 80%;
          border: none;
        }

        &:nth-child(1) {
          @include min-max(768, 991.98) {
            text-align: center;
          }
        }
        &:nth-child(2) {
          @include min(992) {
          }
          @include max(767.98) {
            margin-top: 15px;
          }
        }
        &:nth-child(3) {
          @include max(767.98) {
            margin-top: 15px;
          }
        }

        a {
          color: rgba($white, 0.6);
          &:hover {
            color: $white;
            opacity: 1;
          }
        }
        a {
          text-decoration: none;
          font-weight: $font-regular;
          &.active {
            opacity: 1;
            color: rgba($white, 1);
          }
        }
        .vbar {
          margin-left: 15px;
          margin-right: 15px;
          @include max(767.98) {
            display: none;
          }
          @include min(992) {
            margin-left: 18px;
            margin-right: 18px;
          }
          @include min(1200) {
            margin-left: 24px;
            margin-right: 24px;
          }
        }
      }
    }
  }
}
html[dir="rtl"],
.arabic {
  .sitefooter {
    .ftrcontacts {
      .input_holder {
        .nwsltr {
          &_control {
            @include border-radius(0 26px 26px 0);
            margin-left: 5px;
            margin-right: 0;
          }
          &_btn {
            @include border-radius(26px 0 0 26px);
          }
        }
      }
    }
    .ftr_menu_addr {
      .mobileview {
        .menudropdown {
          .menu_item {
            button {
              &:after {
                margin-left: 0;
                margin-right: auto;
              }
            }
          }
        }
      }
    }
  }
}
