@import "../../../../styles/utilities.scss";
/* Navigation */

.shimmer {
  display: inline-flex;

  span {
    & + span {
      margin-left: size(30);
    }
  }
}

@include min(1200) {
  .mainav {
    display: flex;
    justify-content: flex-end;
    flex-basis: calc(100% - 190px);
    max-width: calc(100% - 190px);
    padding-right: 12px;

    // overflow: hidden;
    @include min(1600) {
      flex-basis: calc(100% - 205px);
      max-width: calc(100% - 205px);
      padding-right: 30px;
    }

    > ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      > li {
        display: inline-block;
        // position: relative;
        line-height: 1;
        font-size: size(16);
        font-weight: $font-medium;
        color: $text-off;

        // &::after {
        //   content: '';
        //   position: absolute;
        //   width: 100%;
        //   transform: scale(0);
        //   transform-origin: left;
        //   transition: transform .3s ease;
        //   height: 2px;
        //   bottom: 0;
        //   background-color: #F27705;
        // }

        &.active {
          & > a {
            color: #f27705 !important;

            // &::before {
            //   transform: scale(1);
            // }

            // pointer-events: none;
          }
        }

        &.lang {
          margin: 0;
          display: inline-flex;
          align-items: center;

          .vbar {
            display: inline-block;
            width: 1px;
            height: 15px;
            background-color: #d3d3d3;
            margin-left: 12.5px;
            margin-right: 12.5px;
          }

          a {
            display: inline-block;
            position: relative;
            color: rgba($text-off, 0.35);

            /* font-size: size(18); */
            &:hover {
              // color: $text-off;
              color: #24b6ff;
            }

            &::before,
            &::after {
              display: none;
            }

            &.activeLang {
              color: $black;
              pointer-events: none;
              opacity: 0.4;
            }
          }
        }

        > a {
          display: block;
          padding-left: 0;
          padding-right: 0;
          text-decoration: none;
          position: relative;
          color: $text-off;
          padding: 26.5px 20px;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: calc(100% - var(--size));
            transform: scale(0);
            transform-origin: left;
            transition: transform 0.3s ease;
            height: 2px;
            border-radius: 2px;
            bottom: 0;
            left: calc(var(--size) * .54);
            background-color: #f27705;
            
            --size: #{size(40)};
            @include max(1599) {
              
            --size: #{size(28)};
            }
          }

          @include max(1599) {
            padding: 24px 14px;
          }


          @include max(1400){
            padding: 24px 11px;
          }

          @include max(1300){
            padding: 24px 8px;
          }

          &:hover {
            // color: #41bcf9;
            color: #f27705 !important;
          }

          // &:before {
          //   position: absolute;
          //   content: "";
          //   width: 0;
          //   left: 0;
          //   bottom: -5px;
          //   height: 2px;
          //   background: $primary-color;
          //   @include transition(all, ease, 0.5s);
          //   display: none;
          // }

          &.service {
            &::after {
              content: "";
              position: absolute;
              bottom: -60px;
              left: 20%;
              background-repeat: no-repeat;
              background-size: contain;
              background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49.857" height="45" viewBox="0 0 49.857 45"><defs><filter id="Polygon_15" x="0" y="0" width="49.857" height="45" filterUnits="userSpaceOnUse"><feOffset dx="1" input="SourceAlpha"/><feGaussianBlur stdDeviation="3" result="blur"/><feFlood flood-opacity="0.098"/><feComposite operator="in" in2="blur"/><feComposite in="SourceGraphic"/></filter></defs><g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(%23Polygon_15)"><path id="Polygon_15-2" data-name="Polygon 15" d="M12.483,5.84a4,4,0,0,1,6.89,0L28.3,20.968A4,4,0,0,1,24.853,27H7a4,4,0,0,1-3.445-6.032Z" transform="translate(8 9)" fill="%23fff"/></g></svg>');
              width: 45px;
              height: 45px;
              // z-index: 0;
              opacity: 0;
            }

            &.dropdown {
              &::after {
                animation: fadeUp2 0.4s ease-in-out forwards;
                animation-delay: 0.2s;
              }
            }
          }

          &.active {
            &:before {
              width: 100%;
            }
          }
        }

        &:hover {
          > a {
            @include transition(all, ease, 0.5s);

            // &:before {
            //   width: 100%;
            // }

            &::before {
              transform: scale(1);
            }
          }

          > ul {
            display: block;
          }

          &::after {
            transform: scale(1);
          }
        }
      }
    }
  }

  .mob-btn {
    display: none;
  }
}

@include max(1199.98) {
  .mainav {
    display: none;

    & + * {
      display: none !important;
    }

    .new {
      &::after {
        content: "New";
        width: 30px;
        height: 30px;
        display: inline-block;
        background-color: red;
        position: absolute;
      }
    }
  }
}

.mainav {
  // position: relative;
}

html[dir="rtl"] {
  .mainav {
    justify-content: flex-start;

    @include min(1200) {
      padding-right: 0 !important;
      padding-left: 22px;
    }

    @include min(1600) {
      padding-left: 30px;
    }

    ul {
      li {
        &.lang {
          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

// .menugrid {
//   position: absolute;
//   background-color: #fff;
//   padding: size(55);
//   top: 63px;
//   width: 100%;
//   display: grid;
//   right: 15px;
//   max-width: size(1241);
//   grid-template-columns: repeat(4, 1fr);
//   grid-auto-rows: auto;
//   gap: 30px;
//   animation: fadeUp .4s ease-in-out;
//   border-bottom: 2px solid $primary-color;
//   border-radius: 0 0px 5px 5px;
//   margin-top: 20px;

//   &.active {
//     display: grid;
//   }

//   &__subtitle {
//     font-weight: 600;
//   }

//   &__list {
//     display: block;
//     margin-top: 20px !important;
//   }

//   &__item {
//     display: block;
//   }

//   &__item_inner {
//     display: block;
//     font-size: 14px;
//     text-decoration: none;
//     text-transform: capitalize;
//     line-height: 2;

//     &:hover {
//       color: $primary-color;
//     }

//     a {
//       text-decoration: inherit;
//       color: inherit;
//     }
//   }

// }

ul + li {
  margin: 0;
  padding: 0;
}

@keyframes fadeUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeUp2 {
  0% {
    bottom: -100px;
    opacity: 0;
  }

  100% {
    bottom: -20px;
    opacity: 1;
  }
}

.card_header {
  display: flex;
  flex-direction: column;

  .icon {
    margin-bottom: 15px;
    width: 30px;
    height: 30px;
  }
}

.menugrid__subtitle {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

// .menugrid {
//   background: $white;
//   position: absolute;
//   // margin: 0 !important;
//   padding: 12px 0;
//   min-width: 261px;
//   display: none;
//   top: 75px;
//   row-gap: 80px;
//   // -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
//   // -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
//   // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
//   // border-bottom: 2px solid $primary-color;

//   li {
//     // padding: 0;
//     line-height: 1.5;
//     font-size: size(14);

//     &:hover {
//       @include transition(all, ease, 0.3s);
//     }

//     a {
//       display: block;
//       color: #000;
//       // padding: 12px 45px;
//       text-decoration: none;

//     }

//     &.active {
//       &>a {
//         color: $primary-color;
//         pointer-events: none;
//       }
//     }

//     &:hover {
//       >ul {
//         display: block;
//         left: 250px;
//         top: 0;
//       }

//       >a {
//         color: $primary-color;
//         text-decoration: none;
//         font-weight: $font-medium;
//       }
//     }
//   }
// }

.dropdown_outer {
  padding-top: 50px;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0px;
}

.new_item {
  background-color: $primary-color;
  border-radius: 2px;
  padding: 2px 3px;
  margin-inline-start: 5px;
  display: inline;
  text-transform: uppercase;
  color: #fff;
  animation: flash-anim 1s ease-in both infinite;
  font-size: 12px !important;
}

@keyframes flash-anim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.menugrid {
  position: absolute;
  background-color: #fff;
  padding: size(66) size(65);
  top: 0px;
  width: 100%;
  // display: grid;
  row-gap: 80px;
  display: flex;
  z-index: 2;
  // max-width: size(1241);
  // grid-template-columns: repeat(4, 1fr);
  // grid-auto-rows: auto;
  // gap: 30px;
  animation: fadeUp 0.4s ease-in-out;
  // box-shadow: 1px 0px 6px #00000019;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.09);
  border-radius: 5px;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: -25px;
  //   left: 45%;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49.857" height="45" viewBox="0 0 49.857 45"><defs><filter id="Polygon_15" x="0" y="0" width="49.857" height="45" filterUnits="userSpaceOnUse"><feOffset dx="1" input="SourceAlpha"/><feGaussianBlur stdDeviation="3" result="blur"/><feFlood flood-opacity="0.098"/><feComposite operator="in" in2="blur"/><feComposite in="SourceGraphic"/></filter></defs><g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(%23Polygon_15)"><path id="Polygon_15-2" data-name="Polygon 15" d="M12.483,5.84a4,4,0,0,1,6.89,0L28.3,20.968A4,4,0,0,1,24.853,27H7a4,4,0,0,1-3.445-6.032Z" transform="translate(8 9)" fill="%23fff"/></g></svg>');
  //   width: 45px;
  //   height: 45px;

  //   z-index: -1;
  // }

  &.active {
    display: grid;
  }

  &__subtitle {
    font-weight: 600;

    a {
      color: #000000;
      cursor: default;

      &:hover {
        color: #000;
      }
    }
  }

  &__list {
    display: block;
    margin-top: 20px !important;
    position: unset !important;
    // margin-top: 10px !important;
  }

  &__item {
    display: block;

    span {
      font-weight: 600;
      font-size: 16px;
    }
  }

  &__item_inner {
    display: block;
    font-size: 14px;
    text-decoration: none;
    text-transform: capitalize;
    line-height: 2;
    margin-bottom: 9px;

    &:last-child {
      margin-bottom: 0;
    }

    // &:hover {
    //   color: $primary-color;
    // }

    a {
      text-decoration: inherit;

      color: #000000;

      &:hover {
        color: #f27705;
      }
    }
    :global(.active) {
      color: #f27705;
    }
  }
}
