@import '../../styles/utilities.scss';
.branch_loc_sec {
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(100);
    }
    @include min(1600) {
        margin-bottom: size(128);
    }
    opacity: 0;
    transform: translateY(20px);
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), .3s);
    &.loaded {
        opacity:1;
        transform: translateY(0px);
        height: auto !important;
        min-height: 1px !important;
    }
    .locimg {
        max-width: 85px;
        margin-left: auto;
        margin-right: auto;
        margin: 0 auto size(20);
        position: relative;
        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: calc((130/130)*100%);
        }
        @include min(992) {
            max-width: 85px;
            margin: 0 auto size(45);
        }
        @include min(1200) {
            max-width: 95px;
        }
        @include min(1600) {
            max-width: 130px;
        }
        img {
            display: block;
            margin: auto;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            -o-object-fit: cover;
            top: 0;
            left: 0;
        }
    }
    .branch_loc_title {
        text-align: center;
    }
    .shortcnt {
        max-width: 845px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding-bottom: size(21);
        p{
            font-size: size(18);
            @include min(768) {    
                font-size: size(20);
            }
            @include min(1200) {    
                font-size: size(22);
            }
            @include min(1600) {    
                font-size: size(24);
            }
        }
    }
}

.btnwrap {
    text-align: center;
    margin-top: size(30);
    @include min(992) {
        margin-top: size(35);
    }
    @include min(1200) {
        margin-top: size(40);
    }
    @include min(1600) {
        margin-top: size(50);
    }
}

html[dir=rtl] {
    .branchloc {
        .locitem {
            .locname {
                .icon {
                    margin-right: 0;
                    margin-left: size(16);
                }
            }
            ul {
                &::after,&::before {
                    right: 0;
                    left: auto;
                }
            }
        }
    }
}