@import '../../styles/utilities.scss';
.moneyexchangesec {
    position: relative;
    margin-bottom: size(40);
    @include min(768) {
        margin-bottom: size(50);
    }
    @include min(992) {
        margin-bottom: size(60);
    }
    @include min(1200) {
        margin-bottom: size(70);
    }
    @include min(1600) {
        margin-bottom: size(90);
    }
    opacity: 0;
    transform: translateY(20px);
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), .3s);
    &.loaded {
        opacity:1;
        transform: translateY(0px);
        height: auto !important;
        min-height: 1px !important;
    }
    .circleshape {
        pointer-events: none;
        @include min(768) {
            width: 90px;
            height: 200px;
            background: rgb(255,155,93);
            position: absolute;
            z-index: 0;
            top: -10%;
            opacity: .45;
            filter: blur(60px);
            right: -30px;
            @include border-radius(50%)
        }
        @include min(1200) {
            width: 150px;
            height: 500px;
            right: -90px;
        }
    }
    .wrapper {
        &>* {
            flex-basis: 100%;
            max-width: 100%;
            @include min(768) {
                flex-basis: 50%;
                max-width: 50%;
            }
        }
    }
    .imgsec {
        text-align: center;
        position: relative;
        .appviewrap {
            position: relative;
        }
        .shapewrap {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 1;
            left: 0;
        }
        .imgwrap {
            margin: 0;
            display: inline-block;
            position: relative;
            padding-bottom: 38px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 30px;
            z-index: 1;
            /* transform: rotate(-5deg); */
            @include min(992) {
                padding-bottom: 38px;
                padding-left: 23.25%;
                padding-right: 40px;
            }
            @include max(767.98) {
                max-width: 270px;
            }
            &::before {
                content: '';
                display: block;
                width: 245px;
                height: 13px;
                @include border-radius(50%);
                position: absolute;
                right: 0;
                bottom: 0;
                background: rgb(255,151,87);
                background: radial-gradient(circle, rgba(255,151,87,1) 0%, rgba(255,151,87,.3) 66%);
                filter: blur(6px);
                opacity: .35;
            }
            &>img {
                display: block;
                margin: 0 auto;
                position: relative;
                z-index: 1;
            }
        }
        .shapewrap {
            .shapes {
                position: absolute;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                color: $white;
                text-align: center;
                font-weight: $font-medium;
                width: 40px;
                height: 42px;
                line-height: 42px;
                @include min(768) {
                    width: 48px;
                    height: 50px;
                    line-height: 50px;
                }
                @include min(992) {
                    width: 78px;
                    height: 82px;
                    line-height: 82px;
                }
                &.shapes_01 {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='78.262' height='81.629' viewBox='0 0 78.262 81.629'%3E%3Cpath id='Polygon_8' data-name='Polygon 8' d='M38.741 0A15 15 0 0 1 51.687 7.423l7 11.963a15 15 0 0 1 0 15.154l-7 11.963a15 15 0 0 1-12.946 7.423H24.382A15 15 0 0 1 11.436 46.5l-7-11.963a15 15 0 0 1 0-15.154l7-11.963A15 15 0 0 1 24.382 0Z' transform='translate(46.701) rotate(60)' fill='%235c4fce'/%3E%3C/svg%3E");
                    top: 17.5%;
                    left: 9%;
                    filter: drop-shadow(15px 20px 13px rgba(#898EFF,.3));
                    font-size: size(13);
                    @include min(768) {
                        font-size: size(15);
                        left: 0%;
                    }
                    @include min(992) {
                        top: 9.5%;
                        left: 0%;
                        font-size: size(25);
                    }
                }
                &.shapes_02 {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='82.802' height='85.792' viewBox='0 0 82.802 85.792'%3E%3Cpath id='Polygon_7' data-name='Polygon 7' d='M40.637 0A15 15 0 0 1 53.664 7.563l7.885 13.812a15 15 0 0 1 0 14.874L53.664 50.061a15 15 0 0 1-13.027 7.563H25.158a15 15 0 0 1-13.027-7.563L4.246 36.249a15 15 0 0 1 0-14.874L12.131 7.563A15 15 0 0 1 25.158 0Z' transform='translate(49.904) rotate(60)' fill='%23fd6b52'/%3E%3C/svg%3E");
                    
                    font-weight: $font-bold;
                    left: 6%;
                    bottom: 37%;
                    filter: drop-shadow(15px 20px 13px rgba(#FD6B52,.3));
                    font-size: size(8);
                    @include min(768) {
                        font-size: size(12);
                        left: 0%;
                    }
                    @include min(992) {
                        bottom: 27%;
                        left: 0%;
                        font-size: size(16);
                    }
                }
                &.shapes_03 {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='78.051' height='81.408' viewBox='0 0 78.051 81.408'%3E%3Cpath id='Polygon_6' data-name='Polygon 6' d='M38.613 0A15 15 0 0 1 51.559 7.423l6.959 11.89a15 15 0 0 1 0 15.154l-6.959 11.89a15 15 0 0 1-12.946 7.423H24.339a15 15 0 0 1-12.946-7.423L4.435 34.467a15 15 0 0 1 0-15.154l6.959-11.89A15 15 0 0 1 24.339 0Z' transform='translate(46.574) rotate(60)' fill='%23f27705'/%3E%3C/svg%3E");
                    top: 19%;
                    right: 9%;
                    font-size: size(14);
                    @include min(768) {
                        font-size: size(18);
                    }
                    @include min(992) {
                        top: 15%;
                        right: 9%;
                        font-size: size(25);
                    }
                }
                &.shapes_04 {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='86.534' height='90.256' viewBox='0 0 86.534 90.256'%3E%3Cpath id='Polygon_9' data-name='Polygon 9' d='M43.745 0A15 15 0 0 1 56.691 7.423L65.36 22.235a15 15 0 0 1 0 15.154L56.691 52.2a15 15 0 0 1-12.946 7.423H26.05A15 15 0 0 1 13.1 52.2L4.435 37.389a15 15 0 0 1 0-15.154L13.1 7.423A15 15 0 0 1 26.05 0Z' transform='translate(51.636) rotate(60)' fill='%2334c66e'/%3E%3C/svg%3E");
                    bottom: 17%;
                    right: 6%;
                    font-size: size(14);
                    @include min(768) {
                        font-size: size(18);
                        right: 5%;
                    }
                    @include min(992) {
                        bottom: 14%;
                        right: -2%;
                        font-size: size(25);
                    }
                }
            }
        }
        .videoplay {
            position: absolute;
            top: 11px;
            right: 121px;
            z-index: 0;
            width: 102px;
            height: 212px;
            @include border-radius(15px);
            @include overflow(hidden);
            @include min(768) {
                right: 151px;
                width: 122px;
                height: 269px;
            }
            @include min(992) {
                right: 177px;
                width: 131px;
                height: 278px;
                top: 17px;
            }
            @include min(1200) {
                right: 210px;
                width: 158px;
                height: 341px;
                top: 19px;
            }
            @include min(1600) {
                top: 21px;
                right: 225px;
                width: 178px;
                height: 377px;
            }
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    .cntwrap {
        max-width: 100%;
        width: 490px;
        @include min(768) {
            padding-top: size(10);
        }
        @include min(768) {
            padding-top: size(30);
        }
        @include min(1200) {
            padding-top: size(55);
            padding-left: size(20);
        }
        @include min(1600) {
            padding-top: size(73.5);
        }
        @include max(767.98) {
            text-align: center;
        }
        h2 {
            margin-bottom: size(20);
            @include min(992) {
                margin-bottom: size(25);
            }
            @include min(1200) {
                margin-bottom: size(30);
            }
            @include min(1600) {
                margin-bottom: size(35);
            }
        }
        .shrtcnt {
            margin-bottom: size(25);
            @include min(992) {
                margin-bottom: size(30);
            }
            @include min(1200) {
                margin-bottom: size(35);
            }
            @include min(1600) {
                margin-bottom: size(40);
            }
        }
    }
    .dwnldapp {
        margin-top: size(20);
        @include min(992) {
            margin-top: size(35);
        }
        @include min(1200) {
            margin-top: size(45);
            padding-left: size(20);
        }
        @include min(1600) {
            margin-top: size(50.5);
        }
        @include max(767.98) {
            text-align: center;
        }
        header {
            padding-bottom: size(10);
            font-size: size(20);
            color: $primary-color;
            font-weight: $font-medium;
        }
        .appstore {
            display: inline-flex;
            align-items: center;
            @include max(1599.98) {
                width: 500px;
                max-width: 100%;
            }
            li {
                @include min(1200) {
                    padding: 7px;
                    border: 1px solid $black;
                    @include border-radius(5px);
                    width: 286px;
                    flex-basis: 286px;
                    max-width: 50%;
                    &:hover {
                        -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
                        background-repeat: no-repeat;
                        animation: loading 1s infinite;
                    }
                    a {
                        display: flex;
                        align-items: center;
                        max-width: calc(100% - 14px);
                        @include min(1200) {
                            img {
                                &:nth-child(1) {
                                    max-width: 32%;
                                    flex-basis: 32%;
                                }
                                &:nth-child(2) {
                                    max-width: calc(68% - size(15));
                                    flex-basis: calc(68% - size(15));
                                    @include min(1600) {
                                        max-width: calc(68% - size(29));
                                        flex-basis: calc(68% - size(29));
                                    }
                                }
                            }
                        }
                        img+img {
                            margin-left: size(15);
                            @include min(1600) {
                                margin-left: size(29);
                            }
                        }
                    }
                }
                img {
                    @include max(1199.98) {
                        display: none;
                    }
                    &.mble {
                        display: block;
                        @include min(1200) {
                            display: none;
                        }
                    }
                }
                &+li {
                    margin-left: size(15);
                    @include min(992) {
                        margin-left: size(20);
                    }
                    @include min(1200) {
                        margin-left: size(30);
                    }
                }
            }
        }
    }
}
html[dir=rtl] {
    .moneyexchangesec {
        .dwnldapp {
            @include min(1200) {
                padding-left: 0;
                padding-right: size(20);
            }
            .appstore {
                li {
                    &+li {
                        margin-left: 0 !important;
                        margin-right: size(15);
                        @include min(992) {
                            margin-right: size(20);
                        }
                        @include min(1200) {
                            margin-right: size(30);
                        }
                    }
                }
            }
        }
        .cntwrap {
            @include min(1200) {
                padding-left: 0;
                padding-right: size(20);
            }
        }
    }
}