@import "../../styles/utilities.scss";

.title_n_tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: size(38) 0 size(25);
  & > * {
    transform: translateY(30px);
    opacity: 0;
    transition: all cubic-bezier(0.38, 0.67, 1, 1) 0.3s;
    transition: transform 0.75s cubic-bezier(0.38, 0.005, 0.215, 1),
      opacity 0.75s cubic-bezier(0.38, 0.005, 0.215, 1),
      -webkit-transform 0.75s cubic-bezier(0.38, 0.005, 0.215, 1);
  }
  @include min(768) {
    padding: size(60) 0 size(40);
  }
  @include min(992) {
    padding: size(70) 0 size(40);
  }
  @include min(1200) {
    padding: size(90) 0 size(60);
  }
  @include max(767.98) {
    flex-wrap: wrap;
    justify-content: flex-start;
    & > * {
      flex-basis: 100%;
      max-width: 100%;
      & + * {
        margin-top: size(25);
      }
    }
  }
  .titlesec {
    h2 {
      margin: 0;
    }
  }
  .tabswrap {
    ul {
      display: flex;
      align-items: center;
      li {
        & + li {
          margin-left: size(8);
          @include min(768) {
            margin-left: size(15);
          }
          @include min(992) {
            margin-left: size(22);
          }
          @include min(1200) {
            margin-left: size(32);
          }
        }
        .tabtn {
          min-width: 1px;
          padding: 0 size(20) !important;
          height: 34px;
          line-height: 32px;
          font-size: size(13);
          &::before {
            display: none !important;
          }
          @include min(768) {
            min-width: 100px;
            height: 40px;
            font-size: size(16);
            line-height: 36px;
          }
          @include min(1200) {
            min-width: 137px;
            height: 50px;
            line-height: 46px;
            font-size: size(18);
          }
          &:hover {
            border-color: #fddcbc;
            color: $primary-color;
          }
          &.active,
          &.active:focus,
          &.active:hover {
            border-color: #fddcbc;
            color: $primary-color;
            pointer-events: none;
            background-color: transparent;

            -webkit-box-shadow: inset 0px 0px 0px 1px rgba(242, 119, 5, 1) !important;
            -moz-box-shadow: inset 0px 0px 0px 1px rgba(242, 119, 5, 1) !important;
            box-shadow: inset 0px 0px 0px 1px rgba(242, 119, 5, 1) !important;
            &::before {
              top: -25px;
            }
          }
        }
      }
    }
  }
}

.mediaslist {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  transform: translateY(15px);
  opacity: 0;
  transition: all cubic-bezier(0.38, 0.67, 1, 1) 0.3s;
  & > * {
    padding: 25px 15px;
    flex-basis: 420px;
    width: 420px;
    max-width: 100%;
    padding: 9px 15px;
    @include min(768) {
      max-width: 50%;
      padding: 15px 15px;
    }
    @include min(992) {
      max-width: 33.33%;
    }
    @include min(1200) {
      max-width: 25%;
      padding: 25px 15px;
    }
  }
  .newitem {
    position: relative;
    height: 100%;
    text-decoration: none;
    color: $text-off;
    font-weight: $font-regular;
    display: block;

    -webkit-box-shadow: inset 0px 0px 0px 1px rgba(209, 209, 209, 1) !important;
    -moz-box-shadow: inset 0px 0px 0px 1px rgba(209, 209, 209, 1) !important;
    box-shadow: inset 0px 0px 0px 1px rgba(209, 209, 209, 1) !important;
    @include border-radius(15px);
    @include overflow(hidden);
    transform: translateY(15px);
    opacity: 0;
    transition: all cubic-bezier(0.38, 0.67, 1, 1) 0.3s;

    &.gallery {
      .postimg {
        cursor: pointer;
        padding-bottom: calc((196 / 325.31) * 100%);
      }
    }
    .details {
      height: 100%;

      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 25px 25px 30px;
      @include min(992) {
        padding: 25px 25px;
      }
      @include min(1600) {
        padding: 41px 31px;
      }
      .postype {
        color: $primary-color;
        margin-bottom: size(10);
        font-size: size(14);
        @include min(768) {
          font-size: size(15);
        }
        @include min(992) {
          font-size: size(16);
        }
        @include min(1200) {
          font-size: size(19);
        }
      }
      .postitle {
        color: $black;
        font-weight: $font-medium;
        font-size: size(18);
        margin-bottom: size(25);
        transition: all cubic-bezier(0.38, 0.67, 1, 1) 0.2s;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        @include min(992) {
          margin-bottom: size(30);
          font-size: size(20);
        }
        @include min(1200) {
          margin-bottom: size(25);
        }
        @include min(1600) {
          margin-bottom: size(35);
          font-size: size(20);
        }
      }
      .cntsec {
        padding-bottom: size(25);
        p {
          @include overflow(hidden);
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          & + p {
            margin-top: size(10) !important;
          }
        }
      }
    }
    .postimg {
      margin: 0;
      @include border-radius(15px 15px 0 0);
      @include overflow(hidden);
      position: relative;
      padding-bottom: calc((200 / 400) * 100%);
      width: 400px;
      max-width: 100%;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.5s cubic-bezier(0.38, 0.67, 1, 1);
      }
      & + .details {
        @include border-radius(0 0 15px 15px);
        height: auto;
      }
    }
    .postdate {
      margin-top: auto;
    }

    &:hover {
      background-repeat: no-repeat;
      @include min(1200) {
        transform: translateY(-5px) !important;
      }
      .postimg {
        img {
          // transform: scale(1.05);
        }
      }
      .details {
        .postitle {
          // color: $primary-color;
        }
      }
    }
  }
}
.btnwrap {
  margin-top: size(35);
  text-align: center;
  @include min(768) {
    margin-top: size(45);
  }
  @include min(992) {
    margin-top: size(55);
  }
  @include min(1200) {
    margin-top: size(70);
  }
}

.mediasection {
  position: relative;
  margin-bottom: size(50);
  min-height: 100vh;
  @include min(768) {
    margin-bottom: size(60);
  }
  @include min(992) {
    margin-bottom: size(80);
  }
  @include min(1200) {
    margin-bottom: size(100);
  }
  @include min(1600) {
    margin-bottom: size(130);
  }
  &.loaded {
    height: auto !important;
    min-height: 1px !important;
    .mediaslist {
      transform: translateY(0px);
      opacity: 1;
      transition-delay: 0.1s;
      .newitem {
        transform: translateY(0px);
        opacity: 1;
        transition-delay: 0.1s;
      }
    }
    .title_n_tab {
      & > * {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }
}
html[dir="rtl"] {
  .title_n_tab {
    .tabswrap {
      ul {
        li {
          & + li {
            margin-right: size(8);
            @include min(768) {
              margin-right: size(15);
            }
            @include min(992) {
              margin-right: size(22);
            }
            @include min(1200) {
              margin-right: size(32);
            }
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}
.media_shimmer {
  &_el {
    min-height: 10px;
    display: block;
    background-color: rgba(209, 209, 209, .3);
    background-image: linear-gradient(to right,  0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }
  .newitem {
    box-shadow: inset 0px 0px 0px 1px rgba(209, 209, 209, .3) !important;
    .postimg {
      span {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
    .details {
      .postype {
        max-width: 60px;
        margin-top: size(10);
        margin-bottom: size(20);
        span {
          height: 18px;
        }
      }
      .postitle {
        span {
            height: 15px;
            &+span {
                margin-top: 15px;
            }
        }
      }
    }
  }
}
