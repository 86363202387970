@import "../../styles/utilities.scss";

.circleshape {
  position: absolute;
  z-index: -1;
  filter: blur(50px);
  background: rgb(255, 155, 93);
  background: radial-gradient(
    circle,
    rgba(255, 155, 93, 1) 0%,
    rgba(249, 249, 249, 0) 100%
  );
  opacity: 0.75;
  top: -0%;
  left: -400px;
  width: 400px;
  height: 400px;
  @include border-radius(50%);
  @include min(1200) {
    left: -600px;
    width: 600px;
    height: 600px;
  }
  pointer-events: none;
}
.realtedjobsec {
  position: relative;
  margin-bottom: size(50);
  @include min(768) {
    margin-top: 0;
    margin-bottom: size(60);
  }
  @include min(992) {
    margin-bottom: size(80);
  }
  @include min(1200) {
    margin-bottom: size(130);
  }
}
.headingsec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .titlesec {
    h2 {
      margin-bottom: 0;
    }
  }
}

.overflow {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.relatedjobs {
  margin-top: size(25);
  @include min(768) {
    margin-top: size(35);
  }
  @include min(992) {
    margin-top: size(40);
  }
  @include min(1200) {
    margin-top: size(50);
  }
  .wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow: visible;
    margin-left: -6px;
    margin-right: -6px;
    @include min(992) {
      margin-left: -10px;
      margin-right: -10px;
    }
    @include min(1200) {
      margin-left: -28px;
      margin-right: -28px;
    }
    & > * {
      padding-left: 6px;
      padding-right: 6px;
      flex-grow: 1;
      text-decoration: none;
      &:last-child {
        padding-right: 20px;
      }
      @include min(992) {
        padding-left: 10px;
        padding-right: 10px;
        &:last-child {
          padding-right: 20px;
        }
      }
      @include min(1200) {
        padding-left: 28px;
        padding-right: 28px;
      }
    }
    .realteditem {
      min-width: 265px;
      width: 100%;
      max-width: 325px;
      padding: size(25);
      @include border-radius(15px);
      border: 1px solid #d1d1d1;
      background-color: $white;
      height: 100%;
      @include min(768) {
        padding: size(30) size(32);
      }
      @include min(1200) {
        padding: size(35) size(40);
      }
      @include min(1200) {
        padding: size(39) size(48);
      }
      @include min(1200) {
        transform: translateY(0px);
        transition: transform linear 0.2s;
        &:hover {
          transform: translateY(-5px);
        }
      }
      .title {
        font-size: size(18);
        margin-bottom: size(17.5);
        font-weight: $font-semibold;
        color: $black;
        @include min(768) {
          font-size: size(19);
        }
        @include min(1200) {
          font-size: size(20);
        }
      }
      .keyvalue {
        color: $black;
        font-size: size(15);
        font-weight: $font-medium;
        white-space: nowrap;
        word-break: break-all;
        display: flex;
        flex-wrap: wrap;
        text-transform: uppercase;
        @include min(1200) {
          font-size: size(16);
        }
        & > * {
          display: block;
        }
        .key {
          width: 100%;
          max-width: 40px;
          flex-basis: 40px;
        }
        .value {
          max-width: calc(100% - 55px);
          flex-basis: calc(100% - 55px);
          text-overflow: ellipsis;
          @include overflow(hidden);
        }
        & + .keyvalue {
          margin-top: size(5);
        }
      }
      .date {
        font-size: size(15);
        color: $text-alt;
        margin-top: size(29);
        @include min(1200) {
          margin-top: size(34);
          font-size: size(14);
        }
      }
    }
  }
}
.smcontainer {
  max-width: 100%;
  width: 1084px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
}
.btnwrap {
  text-align: center;
  margin-top: size(35);
}
