@import '../../styles/utilities.scss';
.circleshape {
    background: rgb(255,205,93);
    background: radial-gradient(circle, rgba(255,205,93,1) 0%, rgba(249,249,249,0) 100%);
    position: absolute;
    @include border-radius(50%);
    opacity: .08;
    z-index: -1;
    filter: blur(80px);
    top: 50px;
    right: -120px;
    pointer-events: none;
    @include min(768) {
        right: -280px;
        width: 500px;
        height: 500px;
    }
    @include min(1200) {
        right: -400px;
        width: 700px;
        height: 700px;
        filter: blur(50px);
    }
}
.enquiresec {
    padding: size(40) 0 size(50);
    @include min(768) {
        padding: size(65) 0 size(80);
    }
    @include min(992) {
        padding: size(75) 0 size(90);
    }
    @include min(1200) {
        padding: size(85) 0 size(100);
    }
    @include min(1600) {
        padding: size(93) 0 size(105);
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
        &>* {
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            @include min(768) {
                flex-basis: 50%;
                max-width: 50%;
            }
            @include max(767.98) {
                &:nth-child(1) {
                        order: 2;
                }
                &:nth-child(2) {
                        order: 1;
                }
            }
        }
    }
}
.formouterwrap, .cntsec .titlesec,.cntsec .cnt, .contactdetails>*>ul>li {
    opacity: 0;
    transform: translateY(10px);
    @include transition(all, linear, .3s);
}
.formouterwrap {
    width: 420px;
    max-width: 100%;
    margin: 0 auto;
    transition-delay: .3s;
}
.cntsec {
    .titlesec {
        padding-bottom: size(25);
        @include min(768) {
            padding-bottom: size(30);
        }
        @include min(1200) {
            padding-bottom: size(40);
        }
        h1,.h1 {
            margin: 0;
            font-size: size(25);
            @include min(768) {    
                font-size: size(30);
            }
            @include min(992) {    
                font-size: size(35);
            }
            @include min(1200) {    
                font-size: size(45);
            }
            @include min(1600) {    
                font-size: size(50);
            }
        }
    }
    .cnt {
        padding-bottom: size(50);
        color: $black;
        transition-delay: .1s;
        @include min(768) {
            max-width: 290px;
            min-height: 255px;
        }
        @include min(992) {
            max-width: 479px;
            min-height: 195px;
        }
    }
}

.loaded {
    height: auto !important;
    min-height: 1px !important;
    .formouterwrap, .cntsec .titlesec,.cntsec .cnt, .contactdetails>*>ul>li {
        opacity:1;
        transform: translateY(0px);
    }
    .contactdetails{
        &>*{
            &>ul{
                &>li {
                    transition-delay: .15s;
                    &:nth-child(2) {
                        transition-delay: .2s;
                    }
                    &:nth-child(3) {
                        transition-delay: .25s;
                    }
                }
            }
        }
    }
}