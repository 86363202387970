@import '../../styles/utilities.scss';
.vimisionsec {
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
    @include min(1600) {
        margin-bottom: size(160);
    }
}
.wrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: #F9F8F7;
    @include border-radius(20px);
    position: relative;
    padding: size(25) size(25);
    @include min(992) {
        flex-wrap: nowrap;
        gap: 35px;
        justify-content: space-between;
        padding: size(30) size(30);
    }
    @include min(992) {
        padding: size(50) size(50);
    }
    @include min(1200) {
        padding: size(50) size(70);
    }
    &>* {
        @include min(992) {
            flex-basis: auto;
            width: 100%;
            max-width: 100%;
            flex-grow: 1;
            position: relative;
            &:not(:first-child) {
                
                &::before {
                    content: '';
                    width: 2px;
                    height: calc(100% - 60px);
                    position: absolute;
                    top: 50%;
                    left: -24px;
                    transform: translate(0%,-50%);
                    background-color: #BEB6AE;
                    opacity: .2;
                }
            }
        }
        @include max(991.98) {
            &+* {
                padding-top: size(60);
                position: relative;
                &::before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    top: 29px;
                    left: 0;
                    background-color: #BEB6AE;
                    opacity: .2;
                }
            }
        }
    }
}
.cntsec {
    .titlesec {
        color: $text-off;
        position: relative;
        padding-top: size(17);
        margin-bottom: 0;
        @include min(992) {
            padding-bottom: size(5);
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 25px;
            height: 3px;
            left: 0;
            top: 0;
            background-color: $primary-color;
        }
    }
}

html[dir=rtl] {
    .cntsec {
        .titlesec {
            &::before {
                left: auto;
                right: 0;
            }
        }
    }
}