@import '../../styles/utilities.scss';
.usratibenefitsec {
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
    @include min(1600) {
        margin-bottom: size(150);
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        align-items: center;
        &>* {
            padding-left: 10px;
            padding-right: 10px;
            flex-basis: 100%;
            max-width: 100%;
        }
        .textcol {
            @include min(992) {
                flex-basis: 40%;
                max-width: 40%;
            }
            @include max(991.98) {
                order: 2;
            }
        }
        .imgcol {
            @include min(992) {
                flex-basis: 60%;
                max-width: 60%;
            }
            @include max(991.98) {
                order: 1;
            }
        }
    }
}
.figwrapper {
    width: 539px;
    max-width: 100%;
    display: block;
    margin: 0 auto size(40);
    @include min(992) {
        margin-bottom: 0;
        margin-left: 0;
        margin-right: auto;
    }
    .imgwrap {
        width: 100%;
        padding-bottom: calc((418/539)*100%);
        position: relative;
        @include border-radius(20px);
        @include overflow(hidden);
        margin: 0;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

html[dir=rtl] {
    .figwrapper {
        @include min(992) {
            margin-left: auto;
            margin-right: 0;
        }
    }
}