@import '../../styles/utilities.scss';
.aboutbanner {
    padding: size(40) 0 size(40);
    @include min(768) {
        padding: size(50) 0 size(40);
        min-height: 505px;
    }
    @include min(992) {
        padding: size(60) 0 size(40);
        min-height: 527px;
    }
    @include min(1200) {
        padding: size(80) 0 size(110);
        min-height: 630px;
    }
    @include min(1600) {
        padding: size(90) 0 size(134);
        min-height: 700px;
    }
    position: relative;
    text-align: center;
    @include min(768) {
        .circleshape {
            width: 400px;
            height: 400px;
            background: radial-gradient(circle, rgba(255,155,93,.69) 0%, rgba(255,255,255,0) 60%);
            position: absolute;
            z-index: 0;
            bottom: -70px;
            opacity: .65;
            filter: blur(50px);
            left: 59%;
            transform: translateX(-50%);
            z-index: -1;
            pointer-events: none;
        }
    }
    @include min(1200) {
        .circleshape {
            width: 650px;
            height: 650px;
        }
    }
    :global(.container) {
        position: relative;
        z-index: 1;
    }
    .bnrtext {
        max-width: 915px;
        display: block;
        margin: auto;
        min-height: 270px;
        @include min(480) {
            min-height: 265px;
        }
        @include min(768) {
            min-height: 265px;
        }
        @include min(1200) {
            min-height: 300px;
        }
        @include min(1600) {
            min-height: 335px;
        }
        &>* {
            transform: translateY(25px) scale(.9);
            transition: all ease .5s;
            opacity: 0;
            transition: transform .75s cubic-bezier(.38,.005,.215,1),opacity .75s cubic-bezier(.38,.005,.215,1),-webkit-transform .75s cubic-bezier(.38,.005,.215,1);
            
        }
        .smtitle {
            font-weight: $font-medium;
            color: $primary-color;
            line-height: 1;
            font-size: size(16);
            transition-delay: .1s;
            @include min(768) {    
                font-size: size(18);
            }
            @include min(1200) {    
                font-size: size(20);
            }
        }
        h1 {
            transition-delay: .2s;
            span {
                display: inline-block;
            }
        }
        p {
            transition-delay: .3s;
            font-size: size(16);
            @include min(768) {    
                font-size: size(18);
            }
            @include min(1200) {    
                font-size: size(20);
            }
        }
    }
    .godwn_btn {
        width: 60px;
        height: 60px;
        @include border-radius(50%);
        border: 1.5px solid $text-off;
        outline: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        margin: size(30.5)  auto size(15);
        @include min(992) {
            margin: size(25.5) auto size(50.5);
        }
        @include min(1200) {
            margin: size(50.5) auto 0;
        }
        svg {
            fill: $text-off;
        }
        &:hover {
            border-color: $primary-color;
            background-color: $primary-color;
            svg {
                fill: $white;
            }
        }
    }
    &.loaded {
        height: auto !important;
        min-height: 1px !important;
        .bnrtext {
            &>* {
                transform: translateY(0) scale(1);
                opacity: 1;
            }
        }
    }
}