@import '../../styles/utilities.scss';
.circleshape_one, .circleshape_two {
    background: rgb(255,155,93);
    background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
    position: absolute;
    @include border-radius(50%);
    opacity: .3;
    z-index: -1;
    pointer-events: none;
}
.circleshape_one {
    filter: blur(80px);
    width: 200px;
    height: 200px;
    bottom: 210px;
    left: 0px;
    opacity: .06;
    @include min(768) {
        width: 500px;
        height: 500px;
    }
    @include min(1200) {
        width: 700px;
        height: 700px;
        filter: blur(50px);
    }
}
.circleshape_two {
    filter: blur(80px);
    width: 200px;
    height: 200px;
    bottom: 80px;
    left: 400px;
    @include min(768) {
        width: 500px;
        height: 500px;
        @include max(991.98) {
            opacity: 0;
        }
    }
    @include min(1200) {
        width: 700px;
        height: 700px;
        opacity: .2;
        filter: blur(50px);
    }
}
.ovalshape {
    background: rgb(255,205,93);
    background: radial-gradient(circle, rgba(255,205,93,1) 0%, rgba(249,249,249,0) 100%);
    position: absolute;
    @include border-radius(50%);
    opacity: .25;
    z-index: -1;
    width: 200px;
    height: 500px;
    top: 100px;
    right: 0px;
    filter: blur(60px);
    @include min(992) {
        filter: blur(80px);
        bottom: -150px;
        right: 0;
        width: 400px;
        height: 550px;
    }
    @include min(1200) {
        width: 600px;
        height: 750px;
        opacity: .24;
        filter: blur(50px);
    }
}
.roundedrecshape {
    @include min(992) {
        display: block;
        position: absolute;
        @include border-radius(20px);
        left: 38px;
        background-color: $primary-color;
        opacity: .14;
        width: calc(100% - 76px);
        height: 150px;
        z-index: -1;
        bottom: -16px;
        filter: blur(50px);
    }
    @include min-max(768,991.98) {
        display: block;
        position: absolute;
        width: 200px;
        height: 200px;
        @include border-radius(50%);
        filter: blur(100px);
        background-color: $primary-color;
        right: -100px;
        opacity: .2;
        z-index: -1;
    }
}

.officeaddrsec {
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(50);
    }
    @include min(992) {
        margin-bottom: size(60);
    }
    @include min(1200) {
        margin-bottom: size(70);
    }
    @include min(1600) {
        margin-bottom: size(90);
    }
    .officeaddress {
        position: relative;
        @include border-radius(15px);
        background-color: $line-color-2;
        @include min(992) {
        background-color: $white;
            @include border-radius(20px);
            padding: size(40) size(40);
        }
        @include min(1200) {
            padding: size(70) size(70);
        }
        @include min(1600) {
            padding: size(90) size(100);
        }
        .wrapper {}
        .addrbox {
            font-size: size(16);
            color: $text-off;
            @include min(992) {
                font-size: size(17);
            }
            @include min(1200) {
                font-size: size(19);
            }
            @include min(1600) {
                font-size: size(20);
            }
            @include max(991.98) {
                padding: size(30) size(30) size(42);
            }
            @include max(767.98) {
                padding: size(25) size(25) size(42);
            }
            .addrloc {
                display: inline-flex;
                align-items: center;
                font-weight: $font-semibold;
                padding-top: size(20);
                font-size: size(18);
                @include min(768) {
                    padding-top: size(15);
                }
                @include min(1200) {
                    font-size: size(20);
                    padding-top: size(32);
                    padding-bottom: size(5);
                }
                .icon {
                    flex-basis: 14px;
                    max-width: 14px;
                    display: flex;
                    align-items: center;
                    svg {
                        width: 14px;
                        height: 16.5px;
                        fill: $primary-color;
                    }
                    &+span {
                        flex-basis: calc(100% - 14px);
                        max-width: calc(100% - 14px);
                        padding-left: size(16);
                    }
                }
            }
            ul {
                padding-bottom: size(10);
                @include min(1200) {
                    padding-bottom: size(30);
                }
                li {
                    font-size: size(18);
                    @include min(1200) {
                        font-size: size(20);
                    }
                    &:nth-child(2) {
                        font-weight: $font-medium;
                    }
                    a {
                        color: $black;
                        text-decoration: none;
                        &:hover {
                            color: $primary-color;
                        }
                    }
                    &+li {
                        margin-top: size(25);
                        @include min(768) {
                            margin-top: size(20);
                        }
                        @include min(1200) {
                            margin-top: size(30);
                        }
                    }
                }
            }
        }
        .figwrap {
            width: 100%;
            max-width: 100%;
            margin-left: auto;
            @include min(992) {
                width: 625px;
            }
            .imgwrap {
                width: 100%;
                padding-bottom: calc((447/625)*100%);
                position: relative;
                margin: 0;
                @include border-radius(15px 15px 0 0);
                @include overflow(hidden);
                @include min(992) {
                    @include border-radius(15px);
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    -o-object-fit: cover;
                    position: absolute;
                }
            }
        }
    }
}

html[dir=rtl] {
    .officeaddrsec {
        .officeaddress {
            .addrbox {
                .addrloc {
                    .icon {
                        &+span{
                            padding-left: 0;
                            padding-right: size(16);
                        }
                    }
                }
            }
        }
    }
}