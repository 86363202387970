@import '../../styles/utilities.scss';
.circleshape_one, .circleshape_two {
    background: rgb(255,155,93);
    background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
    position: absolute;
    @include border-radius(50%);
    opacity: .3;
    z-index: -1;
    pointer-events: none;
}
.circleshape_one {
    filter: blur(80px);
    width: 200px;
    height: 200px;
    bottom: 210px;
    left: 0px;
    opacity: .06;
    @include min(768) {
        width: 500px;
        height: 500px;
    }
    @include min(1200) {
        width: 700px;
        height: 700px;
        filter: blur(50px);
    }
}
.circleshape_two {
    filter: blur(80px);
    width: 200px;
    height: 200px;
    bottom: 300px;
    left: 400px;
    @include min(768) {
        width: 500px;
        height: 500px;
        @include max(991.98) {
            opacity: 0;
        }
    }
    @include min(1200) {
        width: 700px;
        height: 700px;
        opacity: .3;
        filter: blur(50px);
    }
}
.ovalshape {
    background: rgb(255,205,93);
    background: radial-gradient(circle, rgba(255,205,93,1) 0%, rgba(249,249,249,0) 100%);
    position: absolute;
    @include border-radius(50%);
    opacity: .3;
    z-index: -1;
    width: 200px;
    height: 500px;
    top: 100px;
    right: -100px;
    filter: blur(60px);
    pointer-events: none;
    @include min(992) {
        filter: blur(80px);
        bottom: -150px;
        right: 0;
        width: 400px;
        height: 550px;
    }
    @include min(1200) {
        width: 600px;
        height: 750px;
        opacity: .24;
        filter: blur(50px);
    }
}
.roundedrecshape {
    @include min(992) {
        display: block;
        position: absolute;
        @include border-radius(20px);
        left: 38px;
        background-color: $primary-color;
        opacity: .14;
        width: calc(100% - 76px);
        height: 150px;
        z-index: -1;
        bottom: -16px;
        filter: blur(50px);
    }
    @include min-max(768,991.98) {
        display: block;
        position: absolute;
        width: 200px;
        height: 200px;
        @include border-radius(50%);
        filter: blur(100px);
        background-color: $primary-color;
        right: -100px;
        opacity: .2;
        z-index: -1;
    }
}
.buybacktermsec {
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(140);
    }
    @include min(1600) {
        margin-bottom: size(170);
    }
}
.tandcwraper {
    position: relative;
    min-height: 700px;
    @include min(992) {
        background-color: $white;
        @include border-radius(20px);
        padding: size(40);
    }
    @include min(1200) {
        padding: size(65);
    }
    @include min(1600) {
        padding: size(80);
    }
    .titlesec {
        text-align: center;
        margin-bottom: size(30);
        @include min(992) {
            margin-bottom: size(40);
        }
        @include min(1200) {
            margin-bottom: size(50);
        }
    }
    .tandc {
        max-width: 1075px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        ul {
            li {
                &::before {
                    background-color: $text-alt;
                }
            }
        }
    }
}