@import '../../styles/utilities.scss';

.aboutusec {
    position: relative;
    margin-bottom: size(40);
    @include min(768) {
        margin-bottom: size(50);
    }
    @include min(992) {
        margin-bottom: size(60);
    }
    @include min(1200) {
        margin-bottom: size(70);
    }
    @include min(1600) {
        margin-bottom: size(100);
    }
    opacity: 0;
    transform: translateY(20px);
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), .3s);
    &.loaded {
        opacity:1;
        transform: translateY(0px);
        height: auto !important;
        min-height: 1px !important;
    }
    
    &::before, &::after {
        content: '';
        display: none;

    }
    
    &::before{
        
        background: rgb(255,155,93);
        background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
        position: absolute;
        @include border-radius(50%);
        opacity: .3;
        z-index: -1;
        pointer-events: none;
        
        filter: blur(80px);
        width: 200px;
        height: 200px;
        bottom: 210px;
        left: 0px;
        opacity: .06;
        @include min(768) {
            width: 500px;
            height: 500px;
        }
        @include min(1200) {
            width: 700px;
            height: 700px;
            filter: blur(50px);
        }
    }
    &::after  {

        
        background: rgb(255,205,93);
        background: radial-gradient(circle, rgba(255,205,93,1) 0%, rgba(249,249,249,0) 100%);
        position: absolute;
        @include border-radius(50%);
        opacity: .3;
        z-index: -1;
        width: 200px;
        height: 500px;
        top: 100px;
        right: -100px;
        filter: blur(60px);
        @include min(992) {
            top: auto;
            filter: blur(80px);
            bottom: -180px;
            right: 0;
            width: 400px;
            height: 550px;
        }
        @include min(1200) {
            width: 600px;
            height: 750px;
            opacity: .24;
            filter: blur(50px);
        }
    }
}
.wrapper {
    @include border-radius(20px);
    background-color: $white;
    position: relative;
    padding: 35px 15px;
    @include min(768) {
        padding: 25px 25px;
        min-height: 350px;
    }
    @include min(992) {
        padding: 40px 40px;
        min-height: 400px;
    }
    @include min(1200) {
        padding: 70px 70px;
        min-height: 500px;
    }
    @include min(1600) {
        padding: 100px 100px;
        min-height: 600px;
    }
    @include max(767.98) {
        background-color: $line-color-2;
        @include border-radius(20px);
    }
    text-align: center;


    
    -webkit-box-shadow: -10px -10px 55px -8px rgba(242,119,5,0.07);
    -moz-box-shadow: -10px -10px 55px -8px rgba(242,119,5,0.07);
    box-shadow: -10px -10px 55px -8px rgba(242,119,5,0.07);

    &::before, &::after {
        content: '';
        display: none;

    }
    &::before {
        @include min(992) {
            display: block;
            position: absolute;
            @include border-radius(20px);
            left: 38px;
            background-color: $primary-color;
            opacity: .14;
            width: calc(100% - 76px);
            height: 150px;
            z-index: -1;
            bottom: -16px;
            filter: blur(50px);
        }
        @include min-max(768,991.98) {
            display: block;
            position: absolute;
            width: 200px;
            height: 200px;
            @include border-radius(50%);
            filter: blur(100px);
            background-color: $primary-color;
            right: -100px;
            opacity: .2;
            z-index: -1;
        }
    }
    &::after {
        background: rgb(255,155,93);
        background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
        position: absolute;
        @include border-radius(50%);
        opacity: .3;
        z-index: -1;
        pointer-events: none;
        
        filter: blur(80px);
        width: 200px;
        height: 200px;
        bottom: 300px;
        left: 400px;
        @include min(768) {
            width: 500px;
            height: 300px;
            @include max(991.98) {
                opacity: 0;
            }
        }
        @include min(1200) {
            width: 600px;
            height: 400px;
            opacity: .3;
            filter: blur(50px);
        }
    }
    .shortcnt {
        width: 880px;
        max-width: 100%;
        margin: 0 auto size(35);
        font-size: size(15);
        @include min(768) {
            margin: 0 auto size(38);
            font-size: size(16);
        }
        @include min(1200) {
            margin: 0 auto size(49);
        }
    }
    .countsec {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-width: 1068px;
        margin: 0 auto size(10);
       
        @include min(768) {
            margin: 0 auto size(35);
        }
        @include min(992) {
            margin: 0 auto size(45);
        }
        @include min(1200) {
            margin: 0 auto size(50);
        }
        @include min(1600) {
            margin: 0 auto size(63);
        }
        @include max(767.98) {
            text-align: left;
            margin-left: -25px;
            margin-right: -25px;
        }
        &>* {
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            @include max(767.98) {
                flex-basis: 50%;
                max-width: 50%;
                padding-left: 40px;
                padding-right: 40px;
                margin-bottom: size(30);
            }
            &+* {
                &::before {
                    content: '';
                    display: block;
                    width: 1px;
                    opacity: .28;
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    transform: translateY(-50%);
                    background-color: #707070;
                    height: 100%;
                    max-height: 100%;
                    @include min(768) {
                        left: -25%;
                        height: 52px;
                        max-height: 80%;
                    }
                    @include min(992) {
                        left: -75%;
                    }
                }
            }
            @include max(767.98) {
                &::before {
                    display: none;
                }
                &:nth-child(even) {
                    &::before {
                        display: block;
                    }
                }
            }
        }
        .itemtImg{
           width: size(79);
           height:  size(79);
           margin: auto;
           img{
            width: 100%;
            height: 100%;
            object-fit: contain;
           }

        }
        .count {
            font-size: size(25);
            font-weight: $font-semibold;
            color: $primary-color;
            line-height: 1;
            margin-bottom: size(10);
            overflow: hidden;
            position: relative;
            display: flex;
            margin-top: 30px;

            

            @include min(768) {    
                font-size: size(28);
            }
            @include min(1200) {    
                font-size: size(33);
                margin-bottom: size(15);
            }
            @include min(1600) {    
                font-size: size(38);
                margin-bottom: size(18);
            }

            span{
                position: relative;
                display: inline-block;
                margin: auto;
            }
        }
        .itemtitle {
            font-size: size(17);
            color: $text-off;
            font-weight: $font-semibold;
            @include min(768) {    
                font-size: size(18);
            }
            @include min(1200) {    
                font-size: size(20);
            }
        }
    }
}
html[dir=rtl] {
    .countsec {
        
        &>* {
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            @include max(767.98) {
                flex-basis: 50%;
                max-width: 50%;
                padding-left: 40px;
                padding-right: 40px;
                margin-bottom: size(30);
            }
            &+* {
                &::before {
                    left: auto !important;
                    right: 0%;
                    @include min(768) {
                        right: -25%;
                    }
                    @include min(992) {
                        right: -75%;
                    }
                }
            }
        }
    }
}

.aboutUs-el{
    @include min(768){
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}