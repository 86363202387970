@import '../../styles/utilities.scss';
.erorcircle {
    background: rgb(255,205,93);
    background: radial-gradient(circle, rgba(255,205,93,1) 0%, rgba(255,255,255,0) 66%);
    position: absolute;
    z-index: 0;
    top: 0%;
    opacity: .25;
    filter: blur(50px);
    z-index: -1;
    right: -16%;
    width: 400px;
    height: 400px;
    @include min(992) {
        width: 500px;
        height: 500px;
    }
    @include min(992) {
        width: 687px;
        height: 687px;
    }
}

.wrapper {
    position: relative;
}
.errorpagesec {
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    text-align: center;
}
.figwrap {
    position: relative;
    max-width: 100%;
    display: block;
    width: 300px;
    margin: auto auto size(90);
    @include min(768) {
        width: 400px;
        margin: auto auto size(70);
    }
    @include min(1200) {
        width: 500px;
    }
    @include min(1600) {
        width: 604px;
    }
    .imgwrap {
        position: relative;
        padding-bottom: calc((400/604)*100%);
        margin: 0;
        width: 100%;
        img {
            object-fit: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}
.notes {
    h1 {
        font-size: size(20);
        margin-bottom: size(33);
        @include min(768) {    
            font-size: size(22);
        }
        @include min(992) {    
            font-size: size(24);
        }
        @include min(1200) {    
            font-size: size(26);
        }
        @include min(1600) {    
            font-size: size(28);
        }
    }
    .back_btn {
        @include min(1200) {
            padding: 0 size(32);
            height: 55px;
            line-height: 53px;
        }
    }
}