@import "../../styles/utilities.scss";
.featuresec {
  margin-bottom: size(50);
  @include min(768) {
    margin-bottom: size(60);
  }
  @include min(992) {
    margin-bottom: size(80);
  }
  @include min(1200) {
    margin-bottom: size(85);
  }
  @include min(1600) {
    margin-bottom: size(100);
  }
  .headingsec {
    text-align: center;
    margin-bottom: size(30);
    @include min(768) {
      margin-bottom: size(40);
    }
    @include min(992) {
      margin-bottom: size(50);
    }
    @include min(1200) {
      margin-bottom: size(70);
    }
    @include min(1600) {
      margin-bottom: size(85);
    }
  }
}
.wrapper {
  width: 1064px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.featurelist {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .halfcircleshape {
    pointer-events: none;
    position: absolute;
    width: 80%;
    height: 150px;
    background: rgb(242, 119, 5);
    background: linear-gradient(
      180deg,
      rgba(242, 119, 5, 1) 0%,
      rgba(249, 249, 249, 0) 100%
    );
    border-top-left-radius: 100%;
    border-top-right-radius: 50%;
    filter: blur(50px);
    opacity: 0.3;
    left: 30%;
    z-index: -1;
    top: -30px;
    &_mobile {
      position: absolute;
      width: 350px;
      height: 350px;

      background: rgb(255, 155, 93);
      background: radial-gradient(
        circle,
        rgba(255, 155, 93, 1) 0%,
        rgba(249, 248, 247, 0) 100%
      );

      border-radius: 50%;

      z-index: -1;
      top: 30%;
      right: 0;
      opacity: 0.2;
      filter: blur(50px);
      @include min(768) {
        visibility: hidden;
      }
    }
  }
  & > * {
    flex-basis: 100%;
    max-width: 100%;
    padding: 10px 0px;
    @include min(768) {
      flex-basis: 50%;
      max-width: 50%;
      padding: 10px 15px;
    }
    @include min(992) {
      padding: 15px 20px;
    }
    @include min(1200) {
      padding: 19px 24px;
    }
  }
  .featurebox {
    border: 1px solid $line-color;
    @include border-radius(20px);
    padding: 20px 20px;
    background-color: $white;
    height: 100%;
    @include min(768) {
      padding: 25px 25px;
    }
    @include min(992) {
      padding: 30px 30px;
    }
    @include min(1200) {
      padding: 40px 50px;
    }
    .iconsec {
      margin: 0 0 size(13);
      position: relative;
      width: 63px;
      max-width: 100%;
      &::before {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: calc((83 / 83) * 100%);
      }
      @include min(1200) {
        width: 83px;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        -o-object-fit: cover;
      }
    }
    .titlesec {
      color: $black;
      font-weight: $font-medium;
      font-size: size(18);
      margin-bottom: size(8);
      @include min(768) {
        font-size: size(20);
      }
      @include min(1200) {
        font-size: size(22);
        margin-bottom: size(10);
      }
      @include min(1600) {
        font-size: size(24);
      }
    }
    .shortcnt {
      p {
        color: $black;
      }
    }
  }
}
