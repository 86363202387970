@import "../../styles/utilities.scss";

.home_banner_slider {
  position: relative;
  opacity: 0;
  &.banner_visible {
    animation: fadeAnim 1s cubic-bezier(0, 0.25, 0.55, 1) 1 forwards;
  }
  &_slide {
    position: relative;
  }
  &_image,
  &_video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    z-index: -2;
    height: min(100vh, 490px);
    @include min(768) {
      height: min(100vh, 420px);
    }
    @include min(1200) {
      height: min(100vh, 673px);
    }
    @include min(1600) {
      height: min(100vh, 803px);
    }
    @include max(575.98) {
      height: 450px;
    }
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include max(991.98) {
        object-position: 86% 50%;
      }
    }
  }
  &_contents {
    display: flex;
    align-items: center;
    padding: size(80) 0;
    height: min(100vh, 490px);
    @include min(768) {
      height: min(100vh, 420px);
    }
    @include min(1200) {
      padding: size(80) 0;
      height: min(100vh, 673px);
    }
    @include min(1600) {
      padding: size(100) 0;
      height: min(100vh, 803px);
    }
    @include max(575.98) {
      height: 450px;
      align-items: flex-start;
    }
    .banner_overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .slide_content_wrap {
      width: 100%;
      margin-top: -90px;
      @include max(575.98) {
        margin-top: 50px;
      }
      .plane_content {
        &_title {
          font-size: size(25);
          font-weight: $font-bold;
          line-height: 1.24;
          max-width: 300px;
          color: #fff;
          margin-bottom: 15px;
        }
        &_description {
          font-size: size(16);
          font-weight: $font-regular;
          line-height: 1.31;
          max-width: 380px;
          color: #fff;
          margin-bottom: 33px;
        }
      }
      .card_content {
        display: flex;
        // transform: translateY(75%);
        &_wrap {
          max-width: 328px;
          height: 100%;
          padding: 35px 32px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        &_image {
          width: 179px;
          height: auto;
          margin-bottom: 0;
          position: relative;
          img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            object-fit: cover;
          }
        }
        &_contents {
          background-color: #fff;
        }
        &_title {
          // font-size: size(20);
          font-size: size(18);
          font-weight: $font-medium;
          line-height: 1.25;
          // margin-bottom: 34px;
          margin-bottom: 25px;
        }
      }
    }
  }
  &_title {
    font-size: size(70);
    font-weight: $font-semibold;
    line-height: 1.26;
    color: #fff;
    max-width: 800px;
    @include max(1599.98) {
      font-size: size(55);
    }
    @include max(767.98) {
      font-size: size(52);
    }
    @include max(575.98) {
      font-size: size(36);
      line-height: 1.25;
      max-width: 299px;
    }
  }
  &_progress {
    position: relative;
    // display: flex;
    // align-items: center;
    z-index: 9;
    padding-left: 12px;
    &_wrap {
      position: absolute;
      bottom: 26%;
      left: 0;
      width: 100%;
      @include max(575.98) {
        bottom: 32%;
      }
    }
    .progress {
      &_current_slide,
      &_total_slides {
        font-size: size(16);
        font-weight: $font-medium;
        color: #fff;
        width: 20px;
      }
      &_total_slides {
        text-align: right;
      }
      &_bar {
        width: 166px;
        height: 2px;
        background-color: rgba(#fff, 0.6);
        margin: 0 8px;
        position: relative;
        .progress_fill {
          width: 0;
          transform-origin: left;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #fff;
          &:global(.initial-fill-anim) {
            animation: progressFill 5.1s linear 1 forwards;
          }
          &:global(.fill-anim) {
            animation: progressFill 5.1s linear 1 forwards;
          }
        }
      }
    }
  }
}
@keyframes progressFill {
  0% {
    width: 0;
  }
  99.3% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

:global {
  .homepage.modal-open {
    overflow: hidden;
    height: 100vh;
    width: 100%;
  }
  .homepage.slim-header {
    .site-header {
      background-color: #fff !important;
      &::before {
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1) !important;
      }
    }
    nav {
      > ul {
        > li {
          > a {
            color: #000 !important;
            transition: color ease 0.3s, text-shadow ease 0.3s;
            &:hover {
              color: $primary-color !important;
              text-shadow: 0 0 0 #012b5d;
            }
          }
        }
      }
      button {
        svg {
          path {
            fill: #000 !important;
          }
        }
      }
    }
  }
  .homepage:not(.loader-visible) {
    @include min(1200) {
      padding-top: 0;
      .site-header,
      .header-wrap {
        background-color: transparent;
      }
      .site-header {
        &::before {
          box-shadow: none;
        }
        nav {
          > ul {
            > li {
              > a {
                color: #fff;

                &:hover {
                  // color: #41bcf9;
                  color: #00008B;
                }
              }
            }
          }
        }
        button {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  .anim-title {
    .new-line {
      overflow: hidden;
      display: block;
      span {
        display: block;
        opacity: 0;
        transform: translateY(100%);
        transition: 0.7s ease-out all;
      }
    }
  }
  .swiper-slide-active {
    .anim-title {
      span {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  .custom_fraction {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 210px;
    top: 50%;
    transform: translateY(-50%);
    color: transparent;
    .swiper-pagination-current,
    .swiper-pagination-total {
      z-index: 9;
      color: #fff;
    }
    // .swiper-pagination-current {
    //     left: 0;
    // }
    // .swiper-pagination-total {
    //     right: 0;
    // }
  }
}

@media (max-height: 574.98px) {
  .home_banner_slider_progress_wrap {
    bottom: 35%;
  }
}

/* @media (max-height: 767.98px) {
    .card_content {
        // transform: translateY(0) !important;
    }
}
 */
@keyframes fadeAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
