@import '../../styles/utilities.scss';
.banktrasfersec {
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
    @include min(1600) {
        margin-bottom: size(150);
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        &>* {
            padding-left: 10px;
            padding-right: 10px;
            flex-basis: 100%;
            max-width: 100%;
        }
        .textcol {
            @include min(992) {
                flex-basis: 40%;
                max-width: 40%;
            }
            @include max(991.98) {
                order: 2;
            }
        }
        .imgcol {
            @include min(992) {
                flex-basis: 60%;
                max-width: 60%;
            }
            @include max(991.98) {
                order: 1;
            }
        }
        .textwrap {
            @include min (992) {
                max-width: 489px;
            }
            h2 {
                padding-bottom: 10px;
                margin-bottom: 0;
            }
            .btn {
                margin-top: size(20);
                @include min(768) {    
                    margin-top: size(30);
                }
                @include min(1200) {    
                    margin-top: size(40);
                }
                @include min(1600) {    
                    margin-top: size(50);
                }
            }
        }
        .devicewrap {
            position: relative;
            .imgwrap {
                max-width: 100%;
                @include border-radius(20px);
                background-color: #FFF9F5;
                margin-left: auto;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                width: 240px;
                @include min(768) {
                    width: 400px;
                }
                @include min(992) {
                    width: 350px;
                }
                @include min(1600) {
                    width: 449px;
                }
                @include max(991.98) {
                    margin-bottom: size(50);
                }
                .imgsec {
                    width: 100%;
                    padding-bottom: calc((558/449)*100%);
                    position: relative;
                }
                img {
                    max-width: 100%;
                    margin: 0 auto;
                    max-height: 93%;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .trasferoute {
                display: inline-block;
                position: absolute;
                max-width: 50%;
                /* left: 0; */
                bottom: 25px;
                width: 250px;
                .box {
                    background-color: $white;
                    padding: size(20);
                    @include border-radius(10px);
                    @include min(1200) {
                        @include border-radius(20px);
                    }
                    &>header {
                        font-weight: $font-semibold;
                        color: $black;
                        line-height: 1;
                        font-size: size(10);
                        padding-bottom: size(10);
                        @include min(568) {    
                            font-weight: $font-medium;
                            font-size: size(14);
                        }
                        @include min(768) {    
                            font-size: size(18);
                        }
                        @include min(1200) {    
                            padding-bottom: size(16.5);
                            font-size: size(20);
                        }
                    }
                    .fromto {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: space-between;
                        max-width: 190px;
                        &>* {
                            flex-basis: calc(50% - 22px);
                            max-width: calc(50% - 22px);
                        }
                        .icon {
                            max-width: 32px;
                            line-height: 0;
                            max-width: 16px;
                            flex-basis: 16px;
                            @include min(768) {    
                                max-width: 22px;
                                flex-basis: 22px;
                            }
                            @include min(992) {    
                                max-width: 32px;
                                flex-basis: 32px;
                            }
                            svg {
                                width: 16px;
                                height: 11px;
                                @include min(768) {    
                                    width: 22px;
                                    height: 16px;
                                }
                                @include min(992) {    
                                    width: 32px;
                                    height: 22px;
                                }
                            }
                            &+span {
                                padding-left: 10px;
                                max-width: calc(100% - 16px);
                                flex-basis: calc(100% - 16px);
                                font-size: size(9);
                                @include min(568) {    
                                    font-size: size(12);
                                }
                                @include min(768) {    
                                    max-width: calc(100% - 22px);
                                    flex-basis: calc(100% - 22px);
                                    font-size: size(13);
                                }
                                @include min(992) {    
                                    max-width: calc(100% - 32px);
                                    flex-basis: calc(100% - 32px);
                                    font-size: size(15);
                                }
                                @include min(1200) {    
                                    font-size: size(16);
                                }
                            }
                        }
                        .from,.to {
                            display: flex;
                            align-items: center;
                        }
                        .toarw {
                            flex-basis: 11px;
                            max-width: 11px;
                            display: flex;
                            align-items: center;
                            svg {
                                width: 11px;
                                height: 11px;
                                fill: $primary-color;
                            }
                        }
                    }
                    .timelaps {
                        border-top: 1px solid $line-color;
                        padding-top: size(13);
                        margin-top: size(12);
                        @include min(768) {  
                            padding-top: size(15);
                            margin-top: size(15);  
                        }
                        @include min(992) {    
                            padding-top: size(20);
                            margin-top: size(22);
                        }
                        @include min(1200) {   
                            padding-top: size(23);
                            margin-top: size(25); 
                        }
                        header {
                            font-size: size(9);
                            @include min(568) {    
                                font-size: size(12);
                            }
                            @include min(768) {
                                font-size: size(15);
                            }
                            @include min(992) {
                                font-size: size(15);
                            }
                        }
                        .hours {
                            font-weight: $font-medium;
                            font-size: size(11);
                            @include min(568) {    
                                font-size: size(14);
                            }
                            @include min(768) {
                                font-size: size(15);
                            }
                            @include min(992) {
                                font-size: size(17);
                            }
                            @include min(1200) {
                                font-size: size(18);
                            }
                        }
                    }
                }
                @include min(768) {
                    right: 300px;
                    bottom: 49px;
                    width: 306px;
                }
                @include min(992) {
                    right: 260px;
                    bottom: 35px;
                    width: 250px;
                }
                @include min(1600) {
                    right: 339px;
                    bottom: 49px;
                    width: 306px;
                    .box {
                        padding: size(35);
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    width: calc(100% + 5px);
                    height: calc(100% + 5px);
                    @include border-radius(20px);
                    right: -10px;
                    bottom: -10px;
                    background-color: $primary-color;
                    filter: blur(12px);
                    opacity: .11;
                    z-index: -1;
                }
            }
        }
    }
}

html[dir=rtl] {
    .banktrasfersec {
        .wrapper {
            .devicewrap {
                .imgwrap {
                    margin-left: 0;
                    margin-right: auto;
                }
                .trasferoute {
                    left: 50%;
                    // padding: size(20);
                    @include min(768) {
                        left: 300px;
                    }
                    @include min(992) {
                        left: 260px;
                    }
                    @include min(1600) {
                        left: 339px;
                    }
                    right: auto !important;
                    &::before {
                        left: -10px;
                    }
                    .fromto {
                        .icon {
                            &+span {
                                padding-left: 0;
                                padding-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}