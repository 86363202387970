@import '../../styles/utilities.scss';

.certification_tabsec {
    position: relative;
    z-index: 1;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
    @include min(1600) {
        margin-bottom: size(150);
    }
    .sectitle {
        padding-bottom: size(25);
        @include min(768) {
            padding-bottom: size(30);
        }
        @include min(1200) {
            padding-bottom: size(49);
        }
        h2 {
            margin-bottom: 0;
        }
    }
    .wrapper {
        display: none;
        @include min(992) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
            &>* {
                padding-left: 10px;
                padding-right: 10px;
                flex-basis: 100%;
                max-width: 100%;
            }
            .tabcol {
                flex-basis: 43%;
                max-width: 43%;
            }
            .resultcol {
                flex-basis: 57%;
                max-width: 57%;
            }
        }
    }
    .all_btn {
        margin-top: size(22);
        @include min(768) {
            margin-top: size(30);
        }
        @include min(1200) {
            margin-top: size(42);
        }
    }
    .accordianwrap {
        padding-top: 5px;
        @include min(992) {
            display: none;
        }
        .service_acc {
            position: relative;
            :global(.accordion-item) {
                border-width: 2px 0  2px;
                @include border-radius(0px);
            }
            .acc_head {
                position: relative;
                button {
                    background-color: transparent;
                    padding: 25px 0;
                    padding-right: 25px;
                    font-size: size(18);
                    font-weight: $font-medium;
                    &::before,&::after {
                        content: '';
                        width: 16px;
                        height: 2px;
                        @include border-radius(4px);
                        background: $primary-color;
                        position: absolute;
                        right: 0;
                        @include transition(all,ease-in-out,0.3s);
                        transform: rotate(0deg);
                    }
                    /* &::before {
                        display: none;
                    } */
                    &:not(.collapsed) {
                        color: $primary-color;
                    }
                    &:global(.collapsed) {
                        color: $black;
                        &::before {
                            display: block;
                            transform: rotate(-90deg);
                        }
                        &::before,&::after {
                            background: $black;
                        }
                    }
                }
            }
            .acc_content {
                position: relative;
                padding: 0 0 25px;
                color: $black;
                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    height: 2px;
                    bottom: -2px;
                    background-color: $primary-color;
                }
                header {
                    font-size: size(18);
                    font-weight: $font-medium;
                    &+p {
                        margin-top: 20px !important;
                    }
                }
            }
        }
    }
    
    /* Tabs */
    .tabwrapper {
        ul {
            border-top: 2px solid $line-color;
            li {
                font-size: size(20);
                font-weight: $font-regular;
                line-height: 1.2;
                position: relative;
                border-bottom: 2px solid $line-color;
                padding: 20px 0 20px;
                cursor: pointer;
                color: $black;
                @include transition(all, linear, 0.3s);
                &:hover {
                    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
                    background-repeat: no-repeat;
                    animation: loading 1s alternate;
                    color: $primary-color;
                }
                @include min(768) {    
                    font-size: size(22);
                    padding: 29px 0 29px;
                }
                @include min(1200) {    
                    font-size: size(24);
                }   
                @include min(1600) {    
                    font-size: size(26);
                    padding: 34px 0 34px;
                }
                &.active {
                    color: $primary-color;
                    border-color: $primary-color;
                    opacity: 1;
                }
            }
        }
    }
    /* Tab Results */
    
    .awards {
        display: inline-flex;
        align-items: center;
        margin: size(15) 0 size(10);
        max-width: 71%;
        @include min(768) {
            margin: 0 0 size(30);
        }
        @include min(1200) {
            margin: 0 0 size(50);
        }
        .award_figwrap {
            position: relative;
            &:nth-child(odd) {
                margin-right: size(28);
                @include min(768) {
                    margin-right: size(40);
                }
            }
            &:nth-child(even) {
                margin-left: size(28);
                @include min(768) {
                    margin-left: size(40);
                }
                &::before {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 100%;
                    background-color: $line-color;
                    left: -27px;
                    position: absolute;
                    top: 0;
                    @include min(768) {
                        left: -39px;
                    }
                }
            }
            figure {
                margin-bottom: 0;
            }
        }
    }
    .resultwrapper {
        .result_item {
            max-width: 100%;
            display: block;
            margin-left: auto;
            width: 395px;
            @include min(1200) {
                width: 517px;
                margin-right: 20px;
            }
            .figwrap {
                line-height: 0;
                width: 517px;
                max-width: 100%;
                margin: 0 0 size(15);
                @include min(768) {
                    margin: 0 0 size(22);
                }
                @include min(1200) {
                    margin: 0 0 size(32);
                }
                .item_img {
                    padding-bottom: calc((330/517)*100%);
                    position: relative;
                    width: 100%;
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        -o-object-fit: contain;
                    }
                }
            }
            .itemtitle {
                color: $text-off;
                font-weight: $font-medium;
                width: 445px;
                max-width: 100%;
                margin: 0 !important;
                padding-bottom: size(30);
                @include min(768) {
                    padding-bottom: size(40);
                }
                @include min(1200) {
                    padding-bottom: size(50.3);
                }
            }
            .shortcnt {
                color: $black;
            }
        }
    }

    &.servicepage_tab {
        .tabwrapper {
            padding-top: 0 !important;
            ul {
                li {
                    font-size: size(20);
                    padding: 15px 0 15px;
                    @include min(768) {    
                        font-size: size(22);
                        padding: 18px 0 18px;
                    }
                    @include min(1200) {    
                        font-size: size(24);
                    }
                    @include min(1600) {    
                        font-size: size(24);
                        padding: 24px 0 24px;
                    }
                }
            }
        }
        .resultwrapper {
            .result_item {
                .figwrap {
                    margin: 0 0 size(30);
                    @include min(768) {
                        margin: 0 0 size(40);
                    }
                    @include min(1200) {
                        margin: 0 0 size(70);
                    }
                }
                .item_img {
                    @include overflow(hidden);
                    @include border-radius(20px);
                }
            }
        }
        
        .all_btn {
            margin-top: size(30);
            @include min(768) {
                margin-top: size(40);
            }
            @include min(1200) {
                margin-top: size(50);
            }
        }
    }
}

html[dir=rtl] {
    .awards {
        .award_figwrap {
            &:nth-child(odd) {
                margin-left: size(28);
                margin-right: 0;
                @include min(768) {
                    margin-left: size(40);
                    margin-right: 0;
                }
            }
            &:nth-child(even) {
                margin-left: 0;
                margin-right: size(28);
                @include min(768) {
                    margin-left: 0;
                    margin-right: size(40);
                }
                &::before {
                    right: -27px;
                    @include min(768) {
                        right: -39px;
                    }
                }
            }
        }
    }
    .certification_tabsec {
        .resultwrapper {
            .result_item {
            }
        }
    }
}