@import '../../styles/utilities.scss';
.circleshape {
    pointer-events: none;
    @include min(768) {
        width: 200px;
        height: 200px;
        background: rgb(255,155,93);
        
        background: radial-gradient(circle, rgba(255,155,93,.69) 0%, rgba(255,255,255,0) 60%);
        position: absolute;
        z-index: 0;
        bottom: 80%;
        opacity: .78;
        filter: blur(80px);
        right: -100px;
        @include border-radius(50%)
    }
    @include min(1200) {
        bottom: 0%;
        width: 500px;
        height: 700px;
        right: -340px;
    }
}
.aboutfinancesec {
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(100);
    }
    @include min(1600) {
        margin-bottom: size(130);
    }
}
.wrapper {
    &>* {
        flex-basis: 100%;
        max-width: 100%;
        @include min(992) {
            flex-basis: 48%;
            max-width: 48%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            &+* {
                flex-basis: 52%;
                max-width: 52%;
            }
        }
    }
}
.figwrap {
    display: block;
    margin: auto;
    text-align: center;
    width: 486px;
    max-width: 100%;
    @include max(991.98) {
        padding-bottom: size(40);
    }
    @include max(767.98) {
        padding-bottom: size(15);
    }
    @include max(480.98) {
        width: 486px;
    }
    .imgwrap {
        display: inline-block;
        margin: 0 !important;
        position: relative;
        width: 100%;
        padding-bottom: calc((535/600)*100%);
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
            left: 0;
            top: 0;
        }
    }
}
.cntwrap {
    @include max(991.98) {
        text-align: center;
    }
    @include max(767.98) {
        text-align: left;
    }
    h2 {
        margin-bottom: size(22.5);
        @include min(768) {
            margin-bottom: size(30);
        }
        @include min(1200) {
            margin-bottom: size(40);
        }
    }
}