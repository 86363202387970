@import "../../../../styles/utilities.scss";

.mainav {
  display: none;
}

@include max(1199.98) {
  /*==== Menu ====*/
  .mainav {
    display: block;
    position: fixed;
    max-width: 100%;
    padding: 10px 22px 22px;
    @include transition(all, cubic-bezier(0.65, 0.05, 0.36, 1), 0.245s);
    z-index: 3;
    width: 100%;
    overflow: auto;
    visibility: hidden;

    background: $white;
    height: auto;
    right: -100%;
    &::-webkit-scrollbar {
      display: none;
    }
    top: 55px;
    @include min(768) {
      top: 65px;
    }
    @include min(992) {
      top: 75px;
    }
    @include min(1600) {
      top: 79px;
    }

    ul {
      margin: 0;
      padding: 0;
      display: block;
      margin-top: 0px;

      &.mnav {
        margin-bottom: 30px;
      }

      & > li {
        // transform: translateY(-3px) ;
        visibility: hidden;
        @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.5s);
      }

      li {
        display: block;
        position: relative;
        border-bottom: 1px solid $line-color;

        a {
          text-decoration: none;
          font-size: size(20);
          letter-spacing: 0.05rem;
          display: inline-block;
          padding: 17px 0;
          font-weight: 500;
          padding-left: 0;
          padding-right: 0;
          color: $title-color;
          font-size: 18px;
        }

        &.lang {
          padding: 17px 0;
          text-decoration: none;
          font-size: 16px;
          letter-spacing: 0.05rem;
          padding: 17px 0;
          font-weight: 500;
          padding-left: 0;
          padding-right: 0;
          color: $title-color;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          span {
            width: 20px;
            height: 20px;
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
          }

          ul {
            width: 100%;

            li {
              a {
                padding-left: 30px;
                padding-bottom: 0;
              }
            }
          }
        }

        li {
          border-bottom: none;

          a {
            font-size: size(18);
            padding: 6px 0;
            padding-left: size(20);
          }
        }
      }
    }
  }

  .mob_btn {
    position: absolute;
    margin: 0px;
    background-color: transparent;
    cursor: pointer;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 30px;
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px;
    overflow: visible;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 30px);
      height: calc(100% + 40px);
      top: -20px;
      left: -15px;
    }

    @include max(767.98) {
      width: 22px;
      height: 16px;
      padding: 6px 10px;
      right: 20px;
    }

    span {
      position: absolute;
      display: block;
      width: 100%;

      height: 2px;
      @include border-radius(3px);
      background: $black;
      transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
      top: 0;
      left: 0;

      & + span {
        top: 9px;
        @include max(767.98) {
          top: 7px;
        }
        & + span {
          top: 18px;
          @include max(767.98) {
            top: 15px;
          }
        }
      }
    }

    .icon-left-1 {
      display: none;
      background: none;
      height: 0;
      width: 0;
    }
  }

  body {
    &:global(.show-menu) {
      // height:: 100vh;
      overflow: hidden;
      background: $white;
      :global(#scroll-container) {
        visibility: hidden;
      }
      .mainav {
        opacity: 1;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        transform: scale(1, 1);
        padding: 10px 22px 22px;
        visibility: visible;

        right: 0%;
        height: calc(100% - 55px);
        @include min(768) {
          height: calc(100% - 65px);
        }
        @include min(992) {
          height: calc(100% - 75px);
        }
        @include min(1600) {
          height: calc(100% - 79px);
        }
        ul {
          & > li {
            visibility: visible;
            // transform: translateY(0px) ;
            &.active {
              .menudropdown {
                .menu_item {
                  .parentmenu {
                    & > button {
                      &:global(.active){
                        color: $primary-color !important;
                      
                      }
                    }
                  }
                }
              }
              & > a {
                color: $primary-color;
              }
            }
          }
        }
      }

      .overlay {
        position: fixed;
        top: 60;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: none;
      }

      .mob_btn {
        // opacity: 0;
        // z-index: -1;
        z-index: 9;

        span {
          opacity: 0;
          top: 7px;
          @include min(768) {
            top: 10px;
          }

          & + span {
            & + span {
              top: 7px;
              @include min(768) {
                top: 10px;
              }
            }
          }

          &:first-child {
            opacity: 1;
            transform: rotate(45deg);
          }

          &:last-child {
            opacity: 1;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

.menudropdown {
  .menu_item {
    border: none;

    .parentmenu {
      button {
        display: block;
        padding: 17px 0;
        padding-left: 0;
        padding-right: size(30);
        @include border-radius(0);
        background-color: transparent !important;
        border-color: transparent !important;
        font-weight: $font-semibold;

        font-size: 18px;
        letter-spacing: 0.05rem;
        font-weight: $font-medium;
        color: $black;
        display: flex;
        align-items: center;
        svg {
          fill: $black;
        }
        span {
          display: flex;
          margin-right: size(11);
          align-items: center;
        }

        &:after {
          content: "";
          width: 16px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17.17" height="9.129" viewBox="0 0 17.17 9.129"><path id="Path_82805" data-name="Path 82805" d="M5701.256,1526.441l7.876-7.318,7.173,7.318" transform="translate(-5700.196 -1518.373)" fill="none" stroke="%23000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></svg>');
          transform: rotateY(0deg);
          position: absolute;
          right: 10px;
        }

        &:global(.collapsed) {
          color: $black !important;
          svg {
            fill: $black;
          }
          &:after {
            // transform: rotateY(180deg);
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17.17" height="9.129" viewBox="0 0 17.17 9.129"><path id="Path_82806" data-name="Path 82806" d="M0,7.318,7.876,0l7.173,7.318" transform="translate(16.11 8.379) rotate(180)" fill="none" stroke="%23888" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></svg>');
          }
        }
      }
      &.activeLang {
        pointer-events: none;
        button {
          color: $primary-color;
          svg {
            fill: $primary-color;
          }
        }
      }
    }

    :global(.accordion-body) {
      padding: size(5) 0 size(22);
    }
  }
}

.submenudrop_down {
  .parentmenu {
    font-size: 16px;
    button {
      padding: 15px 0 !important;
    }
  }
}

html[dir="rtl"] {


  .mob_btn{

    @include max(1199.98){
      right: 0;
      left: 0;
    }

    @include max(768.98){
      left: 22px;
    }
  }

  .mainav {
    ul {
      li {
        li {
          a {
            padding-right: size(20);
            padding-left: 0;
          }
        }
      }
    }
  }

  .menudropdown {
    .menu_item {
      .parentmenu {
        &:global(.disabled) {
          opacity: 0.5;
          pointer-events: none;
        }
        button {
          padding-left: size(30);
          padding-right: 0;

          span {
            margin-right: 0;
            margin-left: size(11);
          }

          &:after {
            left: 10px;
            right: auto;
          }
        }
      }

      :global(.accordion-body) {
        padding: size(5) 0 size(22);
      }
    }
  }

  .mob_btn {
    // left: 20px;
    right: auto;
  }
}
.submenu_body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.submenu_list {
  li {
    a {
      color: #434343 !important;
    }
    &.active{
      a{
        color: $primary-color !important;
      }
    }
  }
}

.new_item {
  background-color: $primary-color;
  border-radius: 2px;
  padding: 2px 3px;
  margin-inline-start: 5px;
  display: inline;
  text-transform: uppercase;
  color: #fff;
  animation: flash-anim 1s ease-in both infinite;
  font-size: 12px !important;
}
