@import "../../styles/utilities.scss";
.foldbefore,
.bottomshape,
.foldroundrec {
  position: absolute;
  filter: blur(50px);
  z-index: -1;
  @include min(768) {
    display: none;
  }
}
@include min(768) {
  .foldbefore {
    background: rgba(255, 155, 93, 0.21);
    background: radial-gradient(circle, rgba(255, 155, 93, 0.21) 0%, rgba(255, 255, 255, 0) 66%);
    @include border-radius(50%);
    width: 500px;
    height: 900px;
    opacity: 1;
    bottom: -90px;
    left: -180px;
  }
  .bottomshape {
    left: 50%;
    transform: translateX(-50%);
    @include border-radius(20px);
    background-color: $primary-color;
    opacity: 0.14;
    width: calc(100% - 76px);
    height: 150px;
    bottom: -26px;
  }
}
@include max(991.98) {
  .foldroundrec {
    width: calc(100% + 30px);
    height: calc(100% + 20px);
    bottom: -10px;
    left: -15px;
    opacity: 0.12;
    background: rgb(255, 119, 0);
    background: linear-gradient(0deg, rgba(255, 119, 0, 1) 0%, rgba(255, 205, 93, 1) 100%);
  }
}
.currencyfold {
  position: relative;
  margin-top: 5px;
  padding-bottom: size(50);
  min-height: 870px;
  @include min(768) {
    margin-top: 0;
    padding-bottom: size(60);
    min-height: 964px;
  }
  @include min(992) {
    padding-bottom: size(80);
    min-height: 837px;
  }
  @include min(1200) {
    padding-bottom: size(120);
    min-height: 712px;
  }
  @include min(1600) {
    padding-bottom: size(150);
    min-height: 757px;
  }
  // @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
  &.home {
    .currencyform {
      @include max(1199.98) {
        margin-top: size(-70);
      }
    }
  }
  form {
    position: relative;
    display: block;
    &::before,
    &::after {
      content: "";
      display: none;
    }
    &::before {
      width: 100px;
      height: 100%;
      top: 0;
      left: -30px;
      opacity: 0.15;
      background: rgba(255, 205, 93, 1);
      border-radius: 20px;
      filter: blur(60px);
      position: absolute;
      z-index: -1;
    }
    &::after {
      width: 500px;
      width: 40%;
      height: 300px;
      top: -90px;
      left: -90px;
      opacity: 0.08;
      background: rgba(255, 155, 93, 1);
      border-radius: 50%;
      filter: blur(80px);
      position: absolute;
      z-index: -1;
    }
  }
  .currencyform {
    @include border-radius(20px);
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 25px 5px 25px;

    @include min(1200) {
      margin-top: size(-70);
    }
    @include min(1600) {
      margin-top: size(-90);
    }

    -webkit-box-shadow: 0px 10px 85px -8px rgba(242, 119, 5, 0.12);
    -moz-box-shadow: 0px 10px 85px -8px rgba(242, 119, 5, 0.12);
    box-shadow: 0px 10px 85px -8px rgba(242, 119, 5, 0.12);
    + &::before,
    &::after {
      content: "";
      display: none;
    }
    &::before {
      position: absolute;
      filter: blur(50px);
      z-index: -1;

      left: 50%;
      transform: translateX(-50%);
      @include border-radius(20px);
      background-color: $primary-color;
      opacity: 0.14;
      width: calc(100% - 76px);
      height: 150px;
      bottom: -26px;
    }
    &::after {
      width: 510px;
      height: 510px;
      background: rgb(255, 205, 93);
      background: radial-gradient(circle, rgba(255, 205, 93, 1) 0%, rgba(255, 255, 255, 0) 69%);

      @include border-radius(50%);
      position: absolute;
      bottom: -90px;
      right: -150px;
      opacity: 0.21;
      transition: 0.4s;
      // filter: blur(40px);
      z-index: -1;
    }
    @include min(768) {
      padding: 30px 20px 30px;
    }
    @include min(992) {
      padding: 40px 20px 30px;
    }
    @include min(1200) {
      padding: 40px 35px 30px;
    }
    @include min(1600) {
      padding: 45px 35px 40px;
    }
    .formsec,
    .graphsec {
      opacity: 0;
      transition: opacity linear 0.3s;
    }
    &.swapped {
      .formsec {
        .currency {
          &_from {
            z-index: 2;
          }
          &_to {
            z-index: 1;
          }
        }
      }
    }
    .formsec {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 100%;
      max-width: 100%;
      padding-top: 35px;
      .currency {
        &_from {
          z-index: 1;
          flex-basis: 100%;
          max-width: 100%;
          @include min(1200) {
            flex-basis: calc(50% - 60px);
            max-width: calc(50% - 60px);
          }
        }
        &_btnsec {
          flex-basis: 100%;
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-top: 0px;
          margin-bottom: 40px;
          @include min(768) {
            margin-top: 10px;
            margin-bottom: 60px;
          }
          @include min(1200) {
            flex-basis: 120px;
            max-width: 120px;
            margin-top: -18px;
            margin-bottom: 0;
          }
          .convertbtn {
            width: 88px;
            height: 88px;
            @include border-radius(50%);
            background-color: $tertiary-color;
            border: none;
            outline: none;
            box-shadow: none;
            @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            @include max(1199.98) {
              transform: rotate(90deg);
              width: 45px;
              height: 45px;
            }
            &::before {
              content: "";
              display: block;
              position: absolute;
              width: calc(100% + 30px);
              height: calc(100% + 30px);
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &:hover {
              @include min(992) {
                background-color: rgba($primary-color, 1);
              }
            }
            .icons {
              display: inline-flex;
              svg {
                fill: $white;

                transition: all cubic-bezier(0.38, 0.67, 1, 1) 0.3s;
                @include max(1199.98) {
                  width: 15px;
                  height: 15px;
                }
              }
            }
            &:hover {
              /* background-color: $primary-color; */
            }
          }
        }
        &_to {
          z-index: 2;
          flex-basis: 100%;
          max-width: 100%;
          @include min(1200) {
            flex-basis: calc(50% - 60px);
            max-width: calc(50% - 60px);
          }
        }
        &_from,
        &_to {
          display: flex;
          flex-wrap: wrap;
          opacity: 0;
          // transform: translateY(20px);
          transition: all cubic-bezier(0.38, 0.67, 1, 1) 0.3s;
          position: relative;

          @include max(767.98) {
            padding-left: 20px;
            padding-right: 20px;
          }
          & > * {
            @include min(768) {
              padding-left: 10px;
              padding-right: 10px;
            }
            @include min(1600) {
              padding-left: 20px;
              padding-right: 20px;
            }
          }
          .cols {
            &:first-child {
              flex-basis: 52.5%;
              max-width: 52.5%;
              .input_holder {
                & > label {
                  color: $text-off;
                }
                .form_control {
                  color: $text-off;
                  font-weight: $font-bold;
                  @include max(1199.98) {
                    padding-right: size(20);
                    white-space: nowrap;
                    @include overflow(hidden);
                    -o-text-overflow: ellipsis;
                    -ms-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
            &:last-child {
              flex-basis: 47.5%;
              max-width: 47.5%;
            }
            @include min(768) {
              &:first-child {
                flex-basis: 60%;
                max-width: 60%;
              }
              &:last-child {
                flex-basis: 40%;
                max-width: 40%;
              }
            }
          }
          .input_holder {
            &.active {
              label {
                top: -10px;
                @include min(768) {
                  top: -17px;
                }
              }
            }
          }
        }
      }
      .form_select {
        & > button {
          position: relative;
          & + div {
            position: absolute !important;
          }
        }
      }
      .input_holder,
      .select_holder,
      .form_select {
        position: relative;
        margin-top: 0;
        margin-bottom: size(20);
        label {
          font-size: size(13);
          font-weight: $font-medium;
          color: $text-off;
          @include min(1200) {
            font-size: size(16);
          }
        }
        .form_control,
        .form_select button {
          font-size: 20px;
          font-weight: $font-semibold;
          min-width: auto;
          @include min(768) {
            font-size: size(26);
          }
          @include min(1200) {
            font-size: size(28);
          }
          @include min(1600) {
            font-size: size(30);
          }
        }
        button {
          display: flex;
          align-items: center;
          svg,
          .img {
            max-width: 100%;
          }
          .flag {
            display: flex;
            align-items: center;
            margin-right: size(12);
            flex-basis: 30px;
            max-width: 30px;
            @include min(1600) {
              margin-right: 18px;
            }
          }
          span {
            opacity: 0.7;
            display: block;
            flex-basis: calc(100% - 42px);
            max-width: calc(100% - 42px);
            font-weight: $font-medium;
            color: $text-off;
            @include min(1600) {
              flex-basis: calc(100% - 48px);
              max-width: calc(100% - 48px);
            }
          }
        }
      }
      .form_select {
        :global(.dropdown-menu) {
          padding: size(2) size(12) size(18);
          position: relative;
          overflow: hidden;
          ul {
            overflow: auto;
            max-height: 175px;
          }
          :global(.dropdown-item) {
            padding: size(12) 0 size(12);
            line-height: 1;
            border-bottom: 1px solid rgba(112, 112, 112, 0.1);
            background-color: transparent;
            display: flex;
            align-items: center;
            .flag {
              display: flex;
              align-items: center;
              margin-right: size(12);
              flex-basis: 25px;
              max-width: 25px;
              @include min(1600) {
                margin-right: 18px;
              }
            }
            span {
              opacity: 0.7;
              display: block;
              flex-basis: calc(100% - 37px);
              max-width: calc(100% - 37px);
              @include min(1600) {
                flex-basis: calc(100% - 43px);
                max-width: calc(100% - 43px);
              }
            }
          }
        }
        .down {
          line-height: 0;
          text-align: center;
          padding: size(12) 0 size(12);
          cursor: pointer;
          width: 100%;
          margin: 0;
          &:hover {
            background-color: rgba($line-color, 0.25);
            @include border-radius(25px);
          }
          svg {
            fill: #9e9e9e;
            display: inline-block;
          }
        }
      }
      .select_holder,
      .input_holder:global(.active),
      .input_holder.active {
        position: relative;
        label {
          color: #939393;
          position: absolute;
          transform: translateY(-50%);
          top: -15px;
          @include min(768) {
            top: -25px;
          }
        }
        .form_control,
        .form_select button {
          color: rgba($black, 1);
          border-bottom-color: $line-color !important;
          @include min(768) {
            color: rgba($black, 0.7);
          }
        }
      }
      .btm {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 22px 20px 0;
        @include min(768) {
          padding: 23px 20px 0;
        }
        & > * {
          @include min(768) {
            flex-basis: calc(50% - 55px);
            max-width: calc(50% - 55px);
          }
          @include min(1200) {
            flex-basis: 50%;
            max-width: 50%;
          }
          @include min(1600) {
            flex-basis: calc(50% - 80px);
            max-width: calc(50% - 80px);
          }
        }
        .refresh {
          display: flex;
          align-items: center;
          @include max(767.98) {
            flex-basis: 100%;
            max-width: 100%;
          }
          .icons {
            flex-basis: 10px;
            max-width: 15px;
            display: flex;
            align-items: center;
            svg {
              fill: $text-alt;
            }
          }
          p {
            margin-top: 0 !important;
            line-height: 1;
            color: #939393;
            font-size: size(14);
            font-weight: $font-medium;
            flex-basis: 100%;
            max-width: 100%;
            margin-left: 0;
            // padding-left: 8px;
            @include min(768) {
              flex-basis: calc(100% - 15px);
              max-width: calc(100% - 15px);
              // margin-left: 8px;
            }
            span {
              color: $black;
            }
          }
        }
        .rate_btn {
          font-size: 14px;
          @include min(768) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 15px;
          }
          @include max(767.98) {
            width: 100%;
            margin-top: size(25);
          }
          a {
            color: $black;
            @include max(767.98) {
              display: none;
            }
            &:hover {
              color: rgba($primary-color, 1);
            }
          }
          .btn {
            min-width: 1px;
            padding: 0 15px;
            @include min(992) {
              margin-left: 15px;
            }
            @include min(1200) {
              padding: 0 20px;
              margin-left: 30px;
            }
            @include max(767.98) {
              width: 100%;
            }
          }
        }
      }
    }
    .graphsec {
      // display: none !important;

      flex-basis: calc(100% - 40px);
      max-width: calc(100% - 40px);
      min-height: 300px;
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      flex-wrap: wrap;
      @include min(768) {
        padding: 40px 0 0;
        border-top: 2px solid #f0f0f0;
      }
      @include min(992) {
        margin-top: 50px;
        padding: 50px 0 0;
      }
      @include min(1200) {
        margin-top: 60px;
        padding: 60px 0 0;
      }
      & > * {
        flex-basis: 100%;
        max-width: 100%;
      }
      .currency_status {
        @include min(992) {
          flex-basis: 41%;
          max-width: 41%;
        }
        &_to {
          font-size: size(25);
          font-weight: $font-semibold;
          line-height: 1.2;
          padding-bottom: 25px;
          @include min(768) {
            font-size: size(30);
            padding-bottom: 30px;
          }
          @include min(1200) {
            font-size: size(35);
            padding-bottom: 50px;
          }
          @include min(1600) {
            font-size: size(45);
            padding-bottom: 70px;
          }
          small {
            font-weight: $font-medium;
            display: block;
          }
        }
        &_time {
          margin-bottom: size(10);
          font-weight: $font-medium;
        }
        &_disclaimer {
          font-weight: $font-regular;
          color: #171515;

          font-size: size(13);
          @include min(1200) {
            font-size: size(14);
          }
          &_text {
            display: flex;
            align-items: center;
            span {
              &:first-child {
                width: 15px;
                flex-basis: 15px;
                max-width: 15px;
                display: flex;
                align-items: center;
              }
              &:last-child {
                padding-left: 8px;
              }
              display: block;
            }
          }
        }
      }
      .graphwrap {
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
        width: 743px;
        max-width: 100%;
        position: relative;
        // background-color: rgba($primary-color, 0.2);
        @include border-radius(5px);
        margin-top: size(45);

        min-height: 250px;
        & > * {
          width: 100%;
        }
        @include min(992) {
          flex-basis: 59%;
          max-width: 59%;
          margin-top: 0;
        }
        @include min(1200) {
          justify-content: flex-end;
          height: 100%;
          margin-top: 0;
        }

        .graph_loader {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba($white, 1);
          z-index: 1;
          opacity: 0;
          pointer-events: none;
          &_loading {
            opacity: 1;
            pointer-events: all;
          }
          .top_bars {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include min(992) {
              padding-left: 30px;
            }
            span {
              display: inline-block;
              width: 35px;
              height: 14px;
              border-radius: 10px;
            }
          }
          .result_view {
            height: calc(100% - 40px);
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            @include min(992) {
              padding-left: 30px;
            }
            span {
              display: block;
              width: 100%;
              height: 2px;
              margin-top: 10px;
            }
          }
        }
      }
      .graph_dates {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-bottom: 25px;

        padding-left: 0px;
        @include min(370) {
          padding-left: 15px;
        }
        @include min(1200) {
          margin-bottom: auto;
          padding-left: 30px;
        }
        .item {
          color: $text-off;
          font-size: size(14);
          font-weight: $font-medium;
          padding: 4px 10px;
          border-radius: 11px;
          line-height: 1;
          &:last-child {
            // padding-right: 0;
          }
          @include min(768) {
            font-size: size(16);
          }
          @include min(1200) {
            font-size: size(17);
            font-weight: $font-semibold;
          }
          &_active {
            background-color: rgba(#fff4ea, 1);
            color: rgba($primary-color, 1);
          }
        }
        &_chart {
          position: relative;
          /* text {
            font-size: size(13) !important;
            color: $text-off !important;
            font-weight: $font-medium !important;
            @include min(768) {
              font-size: size(14) !important;
            }
            @include min(1200) {
              font-size: size(16) !important;
            }
          } */
          svg {
            g {
              g {
                &:last-of-type {
                  /*  text {
                    font-size: size(13) !important;
                    color: $text-off !important;
                    font-weight: $font-medium !important;
                    @include min(768) {
                      font-size: size(14) !important;
                    }
                    @include min(1200) {
                      font-size: size(16) !important;
                    }
                  } */
                }
              }
            }
          }
        }
      }
      .activeClass {
        color: red;
      }
    }

    .shape {
      &_bottom {
        width: 100%;
        height: 108px;
        background-color: rgba($primary-color, 1);
        border-radius: 20px;
        position: absolute;
        left: 0;
        bottom: -20px;
        z-index: -1;
        filter: blur(45px);
        opacity: 0.15;
      }
      &_top_left {
        width: 500px;
        width: 40%;
        height: 300px;
        top: -90px;
        left: -90px;
        opacity: 0.08;
        background: rgba(255, 155, 93, 1);
        border-radius: 50%;
        filter: blur(80px);
        position: absolute;
        z-index: -1;
      }
      &_top_left_center {
        width: 100px;
        height: 100%;
        top: 0;
        left: -30px;
        opacity: 0.15;
        background: rgba(255, 205, 93, 1);
        border-radius: 20px;
        filter: blur(60px);
        position: absolute;
        z-index: -1;
      }
    }
  }

  &.loaded {
    opacity: 1;
    transform: translateY(0px);
    height: auto !important;
    min-height: 1px !important;
    position: relative;
    z-index: 2;
    .currencyform {
      .formsec,
      .graphsec {
        opacity: 1;
      }
      .formsec {
        .currency {
          &_from,
          &_to {
            opacity: 1;
            // transform: translateY(0px);
          }
        }
      }
    }
  }
}
html[dir="rtl"] {
  .currencyfold {
    .currencyform {
      .formsec {
        .btm {
          .rate_btn {
            .btn {
              margin-left: 0 !important;
              margin-right: 10px;
              @include min(992) {
                margin-right: 15px;
              }
              @include min(1200) {
                padding: 0 20px;
                margin-right: 30px;
              }
            }
          }
          .refresh {
            p {
              margin-right: 8px;
              margin-left: 0;
            }
          }
        }
        .input_holder,
        .select_holder,
        .form_select {
          button {
            .flag {
              margin-left: size(12);
              margin-right: 0;
              @include min(1600) {
                margin-left: size(18);
                margin-right: 0;
              }
            }
          }
        }
      }
      .graphsec {
        .graphwrap {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
}
@keyframes pulse {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
.currencyform {
  .form_shimmer {
    /* -webkit-filter: blur(4px);
  filter: blur(4px); */
    max-width: 100%;
    width: 100%;
    // pointer-events: none;

    .graphsec {
      border-color: rgba($primary-color, 0.1);
      .currency_status {
        &_to {
          small {
            width: 40%;
            height: 35px;

            @include min(768) {
              height: 45px;
            }
          }
          span {
            display: block;
            margin-top: 15px;
            height: 35px;
            width: 55%;

            @include min(768) {
              height: 45px;
            }
          }
        }
        &_time {
          max-width: 80%;
          height: 20px;

          @include min(768) {
            height: 25px;
          }
        }
        &_disclaimer {
          max-width: 90%;
          height: 15px;
        }
      }
    }
    .formsec {
      label {
        width: 95px;
        height: 20px;

        top: -15px !important;
      }

      .input_holder,
      .select_holder {
        & > span {
          display: block;
          width: 100%;
          height: 40px;

          display: flex;
          align-items: center;
          justify-content: flex-start;
          @include min(768) {
            height: 50px;
            margin-top: 0;
          }
          span {
            width: 30px;
            height: 15px;
            display: block;
            margin-left: 10px;
          }
        }
      }
      .btm {
        .refresh {
          span {
            display: block;
            height: 14px;
            width: 50%;
          }
        }
        .rate_btn {
          span {
            display: block;
            height: 14px;
            width: 50%;
          }
        }
      }
    }

    .convertbtn {
    }
  }
}
.form_shimmer_shine {
  background-color: rgba($primary-color, 0.1) !important;
  background-image: linear-gradient(to right, 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}
