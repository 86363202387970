@import '../../styles/utilities.scss';

.fieldwrapper {
    /* :global(.form-control), :global(.form-select button) {
        @include max(767.98) {
            border-color: $black !important;
        }
    } */
    :global(.uploadwraper) {
        :global(.uploadlabel) {
            @include min(768) {
                flex-basis: 200.5px;
                max-width: 46%;
            }
        }
        :global(.filetypes) {
            @include min(768) {
                flex-basis: calc(100% - 200.5px);
                max-width: 54%;
            }
            @include min(1600) {
                padding-left: size(30);
            }
        }
    }
    .submitbtn {
        display: block;
        width: 100%;
        max-width: 100%;
        &::before {
            z-index: 0 !important;
        }
        span {
            position: relative;
            z-index: 1;
        }
    }
}