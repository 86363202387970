@import '../../styles/utilities.scss';
.roundedrec, .ovalshape, .ovalshape_left, .circleshape {
    position: absolute;
    z-index: -1;
    filter: blur(50px);
    pointer-events: none;
}
@include min(768) {
    .ovalshape {
        background: rgb(255,155,93);
        background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
        width: 80%;
        height: 115%;
        @include border-radius(50%);
        opacity: .3;
        left: 15%;
        bottom: -50px;
        &_left {
            background: rgb(255,155,93);
            background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
            width: 30%;
            max-width: 600px;
            height: 450px;
            @include border-radius(50%);
            opacity: .1;
            left: 0%;
            top: -50px;
        }
    }
    .roundedrec {
        background: rgb(255,119,0);
        width: 100%;
        height: 60%;
        max-height: 151px;
        @include border-radius(20px);
        opacity: .15;
        bottom: 0;
        left: -4%;
        pointer-events: none;
    }
    .circleshape {
        background: rgb(255,205,93);
        background: radial-gradient(circle, rgba(255,205,93,1) 0%, rgba(249,249,249,0) 100%);
        opacity: .25;
        top: -40px;
        right: -15%;
        width: 300px;
        height: 280px;
        pointer-events: none;
        @include min(1200) {
            width: 487px;
            height: 479px;
        }
    }
}
.designationview {
    padding: size(50) 0;
    position: relative;
    @include min(768) {
        padding: size(70) 0;
    }
    @include min(1200) {
        padding: size(80) 0;
    }
}
.wrapper {
    position: relative;
    @include min(768) {
        background-color: $white;
        @include border-radius(20px);
        padding: size(30);
    }
    @include min(992) {
        padding: size(40);
    }
    @include min(1200) {
        padding: size(50);
    }
}
.detailwrap {
    width: 1084px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include min(768) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>* {
            &:first-child {
                padding-right: size(30);
                @include min(768) {
                    min-width: 400px;
                    max-width: 100%;
                }
                @include min(1200) {
                    width: 675px;
                    min-width: 500px;
                }
            }
            &>* {
                width: 100%;
            }
        }
    }
    h1 {
        font-size: size(25);
        margin-bottom: size(15);
        @include min(768) {    
            font-size: size(28);
        }
        @include min(1200) {    
            font-size: size(30);
        }
        @include min(1600) {    
            font-size: size(35);
        }
        min-height: 40px;
    }
    .shinedesig {
        min-height: 30px;
    }
    .desigdetail {
        font-size: size(14);
        font-weight: $font-medium;
        @include min(768) {
            font-size: size(16);
        }
        @include min(992) {
            font-size: size(18);
        }
        @include min(1200) {
            font-size: size(20);
        }
        span {
            display: inline-block;
            &:first-child {
                text-transform: uppercase;
            }
        }
    }
    .shinepostdetail {
        min-height: 20px;
        margin-top: size(6);
    }
    .postdetail {
        font-size: size(14);
        font-weight: $font-medium;
        color: $text-alt;
        margin-top: size(20);
        @include max(767.98) {
            margin-top: size(14);
        }
    }
    .applynow {
        @include max(767.98) {
            font-size: size(18);
            display: block;
            width: 100%;
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white;
            margin-top: size(35);
            &:hover {
                background-color: $quinary-color;
                border-color: $quinary-color;
                color: $white;
            }
        }
    }
}
html[dir=rtl] {
    .detailwrap {
        @include min(768) {
            &>* {
                &:first-child {
                    padding-right: 0;
                    padding-left: size(30);
                }
            }
        }
    }
}