@import '../../styles/utilities.scss';
.allbranches {
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
}
.branchlist {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    margin-left: -10px;
    margin-right: -10px;
    &>* {
        text-align: center;
        padding: 0 10px 30px;
        flex-basis: 100%;
        max-width: 100%;
        @include min(768) {
            padding: 0 10px 50px;
            flex-basis: 50%;
            max-width: 50%;
        }
        @include min(992) {
            flex-basis: 31%;
            max-width: 31%;
            &:first-child, &:nth-child(3n+1) {
                flex-basis: 35%;
                max-width: 35%;
                text-align: left;
            }
            &:nth-child(3n) {
                text-align: right;
            }
            &.fisrtof {
                flex-basis: 35%;
                max-width: 35%;
            }
        }
        @include min(1200) {
            padding: 0 10px 80px;
        }
        @include min(1200) {
            padding: 0 10px 110px;
        }
    }
}
.loadmore {
    @include max(767.98) {
        min-width: auto;
        padding-left: 25px;
        padding-right: 25px;
        margin-top: size(5);
    }
}