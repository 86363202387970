@import "../../styles/utilities.scss";
.branchlocpage {
}
.titlehead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $line-color;
  padding: 0 0 size(30);
  margin-bottom: size(30);
  @include min(768) {
    padding: size(30) 0;
    margin-bottom: size(45);
  }
  @include min(1200) {
    padding: size(36) 0;
    margin-bottom: size(60);
  }
  @include min(1200) {
    margin-bottom: size(80);
  }
  @include max(767.98) {
    flex-wrap: wrap;
    & > * {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}
.titlesec {
  h2 {
    margin: 0 0 size(25);
    @include min(768) {
      margin: 0;
    }
  }
}
.location {
  display: flex;
  align-items: center;
  @include max(767.98) {
    flex-wrap: wrap;
    & > * {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  .preciseloc {
    text-decoration: none;
    color: $black;
    font-weight: $font-semibold;
    margin-right: size(25);
    display: inline-flex;
    align-items: center;
    background: transparent;
    border-color: black;
    white-space: nowrap;
    @include min(1200) {
      margin-right: size(40);
    }
    &:hover {
      background: black;
    }
    @include max(767.98) {
      order: 2;
      margin: size(25) 0 0;
      font-size: size(14);
    }
    .icon {
      margin-right: size(11);
      display: flex;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        @include min(768) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .dropdown {
    @include max(767.98) {
      order: 1;
      button,
      a {
        font-size: size(14);
      }
    }
    button {
      background-color: $white !important;
      border-color: $black !important;
      // text-transform: uppercase;
      padding: 0 27px 0 25px !important;
      span {
        max-width: 85%;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }
    }
    .search_dropdown_result {
      &_search {
        position: relative;
        padding: 0 size(25) size(10);
        &_wrap {
          position: relative;
          input {
            height: 35px;
            border: none;
            border-bottom: 1px solid #e9e8ec;
            color: rgba($black, 1);
            padding-left: 23px;
            box-shadow: none;
            outline: none;
            width: 100%;
            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &:-moz-placeholder {
              color: rgba($black, 1) !important;
              text-transform: capitalize;
              font-size: size(13);
            }
          }
          button {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 100%;
            background-color: transparent;
            outline: none;
            box-shadow: none;
            display: flex;
            border: none;
            padding: 0 !important;
            align-items: center;
          }
        }
        &_list {
          max-height: 190px;
          overflow: auto;
          a {
            white-space: inherit;
            font-size: size(14);
            &:first-child {
              text-transform: capitalize;
            }
          }
          span {
            text-transform: initial;
            padding: size(12) size(20);
            display: block;
          }
        }
      }
    }
    :global(.dropdown-menu) {
      max-height: 254px;
      overflow: hidden;
      text-transform: uppercase;
      white-space: unset !important;
    }
  }
}
.pathName {
  text-transform: capitalize;
}

html[dir="rtl"] {
  .location {
    .preciseloc {
      @include min(768) {
        margin-left: size(40);
        margin-right: 0;
      }
      .icon {
        margin-right: 0;
        margin-left: size(11);
      }
    }
    .dropdown {
      button {
        justify-content: flex-end;
      }
    }
  }
}
