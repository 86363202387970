@import '../../styles/utilities.scss';

.clientestimonials {
    margin-bottom: size(40);
    @include min(768) {
        margin-bottom: size(50);
    }
    @include min(992) {
        margin-bottom: size(60);
    }
    @include min(1200) {
        margin-bottom: size(80);
    }
    @include min(1600) {
        margin-bottom: size(100);
    }
    opacity: 0;
    transform: translateY(20px);
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), .3s);
    &.loaded {
        opacity:1;
        transform: translateY(0px);
        height: auto !important;
        min-height: 1px !important;
    }
    .reviewtitle {
        text-align: center;
        .reviewicon {
            display: inline-block;
            margin: 0 0 size(30);
            max-width: 81px;
            @include min(768) {
                margin: 0 0 size(30);
                max-width: 85px;
            }
            @include min(992) {
                margin: 0 0 size(40);
                max-width: 90px;
            }
            @include min(1200) {
                margin: 0 0 size(50);
                max-width: 108px;
            }
        }
        h2 {
            margin-top: 0 !important;
            margin: 0 0 size(25);
            @include min(768) {
                margin: 0 0 size(25);
            }
            @include min(1200) {
                margin: 0 0 size(35);
            }
        }
    }
    .reviewslider {
        position: relative;
        @include max(767.98) {
            padding-bottom: 32px;
        }
        :global(.swiper-slide) {
            opacity: 0 !important;
            @include transition(opacity,ease-in-out,0.15s);
            &:global(.swiper-slide-active) {
                opacity: 1 !important;
                // transition-delay: .2s;
            }
        }
        .slideitem {
            max-width: 590px;
            display: block;
            margin: auto;
            text-align: center;
            padding: 10px 0;
            .cnt {
                margin-bottom: size(30);
                @include min(768) {
                    margin-bottom: size(30);
                }
                @include min(992) {
                    margin-bottom: size(40);
                }
                @include min(1200) {
                    margin-bottom: size(50);
                }
                p {
                    color: $quinary-color;
                    font-weight: $font-regular;
                    line-height: 1.43;
                    font-size: size(17);
                    @include min(1200) {
                        font-size: size(18);
                    }
                }
            }
            .author {
                display: inline-flex;
                align-items: center;
                @include max(767.98) {
                    flex-wrap: wrap;
                    text-align: center;
                    justify-content: center;
                }
                .avatar {
                    margin: 0;
                    width: 55px;
                    flex-basis: 55px;
                    height: 55px;
                    @include border-radius(50%);
                    @include overflow(hidden);
                    position: relative;
                    line-height: 0;
                    span {
                        font-size: size(20);
                        font-weight: $font-semibold;
                        color: $white;
                        line-height: 55px;
                        @include border-radius(50%);
                        background-color: $secondary-color;
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        top: 0;
                        left: 0;
                    }
                }
                .authname {
                    max-width: 100%;
                    flex-basis: 100%;
                    @include min(768) {
                        padding-left: size(25);
                        max-width: calc(100% - 55px);
                        flex-basis: auto;
                    }
                    @include min(1200) {
                        padding-left: size(30);
                    }
                    @include max(767.98) {
                        text-align: center;
                    }
                    header {
                        font-weight: $font-medium;
                        color: $black;
                        text-align: left;
                        font-size: size(18);
                        @include min(992) {
                            font-size: size(19);
                        }
                        @include min(1200) {
                            font-size: size(20);
                        }
                        @include max(767.98) {
                            text-align: center;
                        }
                        .loc {
                            display: block;
                            color: $text-alt;
                            font-size: size(15);
                            font-weight: $font-regular;
                            @include min(992) {
                                font-size: size(16);
                            }
                        }
                    }
                }
            }
        }
        .swiperarw {
            position: absolute;
            top: calc(50% - 50px);
            transform: translateY(-50%);
            width: 19px;
            height: 19px;
            z-index: 1;
            display: flex;
            align-items: center;
            cursor: pointer;
            @include max(767.98) {
                display: none;
            }
            transition: opacity linear .2s;
            @include min(1200) {
                &:hover {
                   svg {
                    fill: darken($primary-color, 10%);
                   }
                }
            }
            svg {
                fill: $primary-color;
            }
            &.swiper_prev {
                left: 0;
            }
            &.swiper_nxt {
                right: 0;
            }
            &:global(.swiper-button-disabled) {
                pointer-events: none;
                svg {
                    fill: #BABABA;
                }
            }
        }
        :global(.swiper-pagination) {
            bottom: 0;
            @include min(768) {
                display: none;
            }
            span {
                background-color: #D8D8D8;
                opacity: 1;
                &:global(.swiper-pagination-bullet-active) {
                    background-color: $primary-color;
                }
            }
        }
    }
}

html[dir=rtl] {
    .clientestimonials {
        .reviewslider {
            .slideitem {
                .author {
                    .authname {
                        @include min(768) {
                            padding-left: 0 !important;
                            padding-right: size(25);
                        }
                        @include min(1200) {
                            padding-right: size(30);
                        } 
                        header {
                            @include min(768) {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}