@import "../../../styles/utilities.scss";
.equirydetail {
  &_ph {
    &:not(:last-child) {
      margin-bottom: size(20);
    }
    p {
      font-size: size(14);
      a {
        margin-top: 3px;
        display: block;
        width: fit-content;
        font-size: size(18);
        @include min(1200) {
          font-size: size(20);
        }
      }
    }
  }
  & > ul {
    & > li {
      & + li {
        margin-top: size(30);
        @include min(768) {
          margin-top: size(40);
        }
      }
      span {
        display: block;
        color: $black;
        opacity: 0.8;
        padding-bottom: size(20);
        line-height: 1;
      }
      a {
        text-decoration: none;
        color: $black;
        font-weight: $font-medium;
        font-size: size(18);
        @include min(1200) {
          font-size: size(20);
        }
        &:hover {
          color: $primary-color;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 0 !important;
        // margin-left: -5px;
        margin-right: -5px;
        max-width: 195px;
        @include min(992) {
            max-width: 250px;
        }
        @include min(1200) {
            max-width: 285px;
        }
        li {
          padding-right: 5px;
          &:nth-child(1) {
            a {
              svg {
                width: 10.23px;
                height: 20.46px;
                @include min(1200) {
                  width: 13.14px;
                  height: 26.28px;
                }
              }
            }
          }
          &:nth-child(2) {
            a {
              svg {
            width: 22.38px;
            height: 18.76px;
                @include min(1200) {
                  width: 28.74px;
                  height: 24.09px;
                }
              }
            }
          }
          &:nth-child(3) {
            a {
              svg {
            width: 20.46px;
            height: 20.46px;
                @include min(1200) {
                  width: 26.28px;
                  height: 26.28px;
                }
              }
            }
          }
          &:nth-child(4) {
            a {
              svg {
            width: 27.27px;
            height: 20.46px;
                @include min(1200) {
                  width: 35.02px;
                  height: 26.28;
                }
              }
            }
          }
          a {
            svg {
              fill: $text-alt;
              @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
            }
            &:hover {
              svg {
                @include min(1200) {
                  fill: $primary-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
