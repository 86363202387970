@import '../../styles/utilities.scss';
.whatsnewsec {
    position: relative;
    margin-bottom: size(50);
    opacity: 0;
    transform: translateY(20px);
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), .3s);
    &.loaded {
        opacity:1;
        transform: translateY(0px);
        height: auto !important;
        min-height: 1px !important;
    }
    @include min(992) {
        margin-bottom: size(60);
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 40%;
            max-height: 100px;
            max-width: 800px;
            position: absolute;
            top: 20px;
            left: 55%;
            transform: translateX(-50%);
            @include border-radius(50% 50% 0 0);
            background: rgb(242,119,5);
            background: linear-gradient(180deg, rgba(242,119,5,1) 0%, rgba(249,249,249,0.20211834733893552) 77%);
            filter: blur(50px);
            opacity: .15;
        }
    }
    @include min(1200) {
        margin-bottom: size(70);
    }
    @include min(1600) {
        margin-bottom: size(90);
    }
    &:global(.container) {
        position: relative;
        z-index: 1;
    }
}
.secheader {
    display: flex;
    align-items: center;
    padding-bottom: size(25);
    justify-content: center;
    @include max(767.98) {
        text-align: center;
    }
    @include min(768) {
        justify-content: space-between;
        padding-bottom: size(30);
    }
    @include min(1200) {
        padding-bottom: size(49);
    }
    .sectitle {
        @include max(767.98) {
            width: 100%;
            text-align: center;
        }
        h2 {
            margin-bottom: 0;
        }
    }
    .viewall {
        display: none;
        @include min(768) {
            display: flex;
        }
    }
}
.postlistwrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;
    &>* {
        padding-left: 10px;
        padding-right: 10px;
        flex-basis: 420px;
        width: 420px;
        max-width: 100%;
        @include min(768){
            max-width: 50%;
        }
        @include min(1200) {
            max-width: 33.33%;
        }
        transition: transform cubic-bezier(0.38, 0.67, 1, 1) .3s;
        transform: translateY(0px);
        &:hover {
            @include min(1200) {
                transform: translateY(-3px);
            }
            .newitem {
                .postimg {
                    img {
                        // transform: scale(1.05);
                    }
                }
            }
        }
    }
    .newitem {
        position: relative;
        height: 100%;
        text-decoration: none;
        color: $text-off;
        font-weight: $font-regular;
        // transition: all cubic-bezier(0.38, 0.67, 1, 1) .3s;
        -webkit-box-shadow: inset 0px 0px 0px 1px rgba(209,209,209,1) !important;
        -moz-box-shadow: inset 0px 0px 0px 1px rgba(209,209,209,1) !important;
        box-shadow: inset 0px 0px 0px 1px rgba(209,209,209,1) !important;
        @include border-radius(15px);
        @include overflow(hidden);
        display: block;
        cursor: pointer;
        .details {
            padding: 30px 20px 20px;
            height: 100%;

            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            @include min(768) {
                padding: 40px 30px 30px;
            }
            @include min(992) {
                padding: 45px 35px 35px;
            }
            @include min(1200) {
                padding: 50px 40px 40px;
            }
            .postype {
                font-size: size(19);
                color: $primary-color;
                margin-bottom: size(20);
            }
            .postitle {
                font-size: size(20);
                color: $black;
                margin-bottom: size(20);
                font-weight: $font-medium;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
                @include min(768) {
                    margin-bottom: size(30);
                }
                @include min(992) {
                    margin-bottom: size(40);
                }
                @include min(1200) {
                    margin-bottom: size(50);
                }
            }
            .cntsec {
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;  
                @include overflow(hidden);
                margin-bottom: 20px;
                p {
                    &+p {
                        margin-top: size(10) !important;
                    }
                }
            }
        }
        .postimg {
            margin: 0;
            @include border-radius(15px 15px 0 0);
            @include overflow(hidden);
            position: relative;
            padding-bottom: calc((200/400)*100%);
            width: 400px;
            max-width: 100%;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1);
            }
            &+.details {
                @include border-radius(0 0 15px 15px);
                height: auto;
            }
        }
        .postdate {
            margin-top: auto;
        }
    }
}
.btnwrap {
    display: block;
    text-align: center;
    margin: size(20) 0 0;
    .btn {
        min-width: 1px;
    }
}