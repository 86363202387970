@import '../../styles/utilities.scss';
.jobrequirement {
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
}