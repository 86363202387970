@import "../../../styles/utilities.scss";

.resultwrap {
  opacity: 0;
  transform: translateY(30px);
  @include transition(all, linear, 0.3s);

  transition: transform 0.75s cubic-bezier(0.38, 0.005, 0.215, 1),
    opacity 0.75s cubic-bezier(0.38, 0.005, 0.215, 1),
    -webkit-transform 0.75s cubic-bezier(0.38, 0.005, 0.215, 1);

  @include max(767.98) {
    display: none;
    & + :global(.pagination) {
      display: none;
    }
  }
  .resultitem {
    display: flex;
    flex-wrap: wrap;
    padding: size(28) 0;
    border-top: 1px solid $line-color;
    border-bottom: 1px solid $line-color;
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
    cursor: pointer;
    position: relative;
    text-decoration: none;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: calc(100% + 2px);
      top: -1px;
      left: 0;
      background-color: #f9f9f9;
      display: none;
    }
    &:hover {
      background-color: #f9f9f9;
      &::before {
        display: block;
      }
    }
    & + .resultitem {
      border-top: none;
    }
    & > * {
      padding-left: size(20);
      padding-right: size(20);
      position: relative;
      z-index: 1;
      @include min(1200) {
        padding-left: size(30);
        padding-right: size(30);
      }
      @include min(1600) {
        padding-left: size(40);
        padding-right: size(40);
      }
      span {
        color: #555c68;
        font-weight: $font-medium;
        display: block;
        padding-bottom: size(8);
        font-size: size(14);
        @include min(768) {
          font-size: size(15);
        }
        @include min(992) {
          font-size: size(16);
        }
      }
      .valuename {
        font-size: size(17);
        font-weight: $font-semibold;
        color: $black;
        @include min(992) {
          font-size: size(18);
        }
        @include min(1200) {
          font-size: size(19);
        }
        @include min(1600) {
          font-size: size(20);
        }
      }
    }
    .role {
      max-width: 25%;
      flex-basis: 25%;
    }
    .department {
      max-width: 28.5%;
      flex-basis: 28.5%;
    }
    .location {
      max-width: 24.9%;
      flex-basis: 24.9%;
    }
    .eligibility {
      max-width: 21.6%;
      flex-basis: 21.6%;
    }
  }
  &.loaded {
    transition-delay: 0.3s;
    opacity: 1;
    transform: translateY(0px);
    height: auto !important;
    min-height: 1px !important;
  }
}
