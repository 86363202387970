@import "../../styles/utilities.scss";

.exchangerates_sec {
  margin-bottom: size(60);
  @include min(768) {
    margin-bottom: size(80);
  }
  @include min(992) {
    margin-bottom: size(110);
  }
  @include min(1200) {
    margin-bottom: size(150);
  }
  @include min(1600) {
    margin-bottom: size(190);
  }
  opacity: 0;
  transform: translateY(20px);
  @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), 0.3s);
  & > :global(.container) {
    @include max(480) {
      padding-left: 0;
      padding-right: 0;
      & > * {
        padding-left: 15px;
        padding-right: 15px;
      }
      .ratesecwrap {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
  &.loaded {
    opacity: 1;
    transform: translateY(0px);
    height: auto !important;
    min-height: 1px !important;
  }
  .ratestitle {
    padding-bottom: size(20);
    @include min(768) {
      text-align: center;
      padding-bottom: size(30);
    }
    @include min(992) {
      padding-bottom: size(40);
    }
    @include min(1200) {
      padding-bottom: size(50);
    }
    @include min(1600) {
      padding-bottom: size(60);
    }
  }
  .ratesecwrap {
    min-height: 150px;
    @include min(768) {
      background-color: $line-color-2;
      @include border-radius(20px);
      padding: 25px 35px;
      min-height: 160px;
    }
    @include min(992) {
      padding: 30px 45px;
      min-height: 190px;
    }
    @include min(1200) {
      padding: 50px 66px;
      min-height: 200px;
    }
    @include min(1600) {
      min-height: 220px;
    }
    .rateswrap {
      display: flex;
      flex-wrap: wrap;
      pointer-events: none;
      /* @include max(1199.98) {
                pointer-events: none;
            } */
      & > * {
        & + * {
          &::before {
            @include min(768) {
              content: "";
              display: block;
              height: 80px;
              max-height: 80%;
              width: 1px;
              background-color: rgba(#707070, 0.2);
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: -13px;
            }
            @include min(992) {
              left: -40px;
            }
            @include min(1200) {
              left: -50px;
            }
            @include min(1600) {
              left: -90px;
            }
          }
        }
      }
      :global(.swiper-wrapper) {
        transition-timing-function: linear;
        // transition-duration: .2s!important;
        & > * {
          position: relative;
          & + * {
            &::before {
              @include min(768) {
                content: "";
                display: block;
                height: 80px;
                max-height: 80%;
                width: 1px;
                background-color: rgba(#707070, 0.2);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -35px;
              }
              @include min(992) {
                left: -60px;
              }
              @include min(1200) {
                left: -60px;
              }
              @include min(1600) {
                left: -90px;
              }
            }
          }
        }
      }
      .rateitem {
        margin: auto;
        text-align: left;
        display: block;
        @include min(768) {
          display: inline-block;
        }
        @include max(767.98) {
          background-color: $line-color-2;
          @include border-radius(15px);
          padding: size(25);
        }
        .flag {
          position: relative;
          width: 43px;
          &::before {
            content: "";
            width: 100%;
            display: block;
            padding-bottom: calc((28.31 / 43.42) * 100%);
          }
          margin: 0 0 size(14);
          @include min(768) {
            margin: 0 0 size(11.6);
          }
          & > * {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            top: 0;
            left: 0;
          }
        }
        .countryname {
          margin: 0 0 size(7);
          color: #202124;
          font-weight: 500;
          @include min(768) {
            font-weight: 400;
            margin: 0 0 size(16);
          }
        }
        .rates {
          font-weight: $font-medium;
          font-size: size(19);
          @include min(768) {
            font-size: size(22);
          }
          @include min(1200) {
            font-size: size(25);
          }
          @include min(1600) {
            font-size: size(30);
          }
          span {
            color: $text-alt;
            margin-left: size(10);
            @include min(768) {
              margin-left: size(15);
            }
            @include min(1200) {
              margin-left: size(19);
            }
          }
        }
      }
    }
  }
  .ratescnt {
    text-align: center;
    padding-top: size(20);
    @include min(768) {
      padding-top: size(26);
    }
    @include min(1200) {
      padding-top: size(32);
    }
    p {
      color: #939393;
      font-weight: $font-medium;
      & > a {
        text-transform: capitalize;
      }
    }
    .viewall {
      margin-top: size(25);
      @include min(768) {
        margin-top: size(35);
      }
      @include min(1200) {
        margin-top: size(40);
      }
      @include min(1600) {
        margin-top: size(50);
      }
    }
  }
}

html[dir="rtl"] {
  .exchangerates_sec {
    .rateswrap {
      :global(.swiper-wrapper) {
        & > * {
          &::before {
            @include min(768) {
              left: auto !important;
              right: -35px;
            }
            @include min(992) {
              right: -60px;
            }
            @include min(1200) {
              right: -60px;
            }
            @include min(1600) {
              right: -90px;
            }
          }
        }
      }
      .rateitem {
        text-align: right;
      }
    }
  }
}
.rateslider {
  :global {
    .swiper-slide {
      width: max-content;
    }
  }
}
