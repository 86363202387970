@import '../../../styles/utilities.scss';

.joblistmobview {
    @include min(768) {
        display: none;
    }
    .resultitem {
        display: flex;
        align-items: center;
        padding: size(22) 0;
        border-top: 1px solid $line-color ;
        border-bottom: 1px solid $line-color ;
        text-decoration: none;
        &+.resultitem {
            border-top: none;
        }
        .rolename {
            flex-basis: calc(100% - 15px);
            max-width: calc(100% - 15px);
            font-weight: $font-semibold;
            font-size: size(16);
            color: $black;
            .desig {
                font-weight: $font-medium;
                font-size: size(13);
                color: #555C68;
                margin-top: size(8);
            }
        }
        svg {
            flex-basis: 15px;
            max-width: 15px;
        }
    }
    .btn {
        display: block;
        margin: size(36.6) auto auto;
        min-width: auto;
        height: 50px;
        line-height: 48px;
    }
}
html[dir=rtl]{
    .joblistmobview {
        .resultitem {
            svg {
                transform: rotateY(180deg);
            }
        }
    }
}