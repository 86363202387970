@import '../../styles/utilities.scss';

.circleshape {
    background: rgb(255,205,93);
    background: radial-gradient(circle, rgba(255,205,93,1) 0%, rgba(249,249,249,0) 100%);
    position: absolute;
    @include border-radius(50%);
    opacity: .4;
    filter: blur(80px);
    z-index: -1;
    width: 320px;
    height: 270px;
    right: -20px;
    top: 220px;
    pointer-events: none;
    @include min(768) {
        width: 300px;
        height: 300px;
        right: 40px;
    }
    @include min(1200) {
        width: 450px;
        height: 450px;
        opacity: .24;
        filter: blur(50px);
        right: 220px;
    }
    @include max(767.98) {
        top: 170px;
    }
}
.circleshape_two {
    background: rgb(255,155,93);
    background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
    position: absolute;
    z-index: -1;
    left: 40px;
    bottom: 0;
    @include border-radius(50%);
    opacity: .2;
    filter: blur(50px);
    pointer-events: none;
    @include min(768) {
        width: 300px;
        height: 300px;
    }
    @include min(1200) {
        width: 400px;
        height: 400px;
    }
}
.carrerbnr {
    opacity: 0;
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1),0.3s);
    padding: size(36) 0 size(50);
    position: relative;
    min-height: 620px;
    @include min(768) {
        padding: size(60) 0 size(75);
        min-height: 778px;
    }
    @include min(992) {
        min-height: 545px;
    }
    @include min(1200) {
        padding: size(70) 0 size(120);
        min-height: 595px;
    }
    @include min(1600) {
        padding: size(76) 0 size(127);
        min-height: 608px;
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        &>* {
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
        .textcol {
            padding-top: size(33);
            @include min(768) {
                padding-top: size(60);
            }
            @include min(992) {
                fallback: 48%;
                max-width: 48%;
            }
            @include min(1200) {
                padding-top: size(70);
            }
            @include min(1600) {
                padding-top: size(76);
            }
            @include max(991.98) {
                order: 2;
            }
            .textwrap {
                max-width: 100%;
                &>* {
                    transition: all cubic-bezier(0.38, 0.67, 1, 1) .5s;
                    opacity: 0;
                    transform: translateY(30px);
                    transition: transform .75s cubic-bezier(.38,.005,.215,1),opacity .75s cubic-bezier(.38,.005,.215,1),-webkit-transform .75s cubic-bezier(.38,.005,.215,1);
                }
                @include min(992) {
                    width: 675px;
                }
                @include max(991.98) {
                    text-align: center;
                }
                .smtitle {
                    font-weight: $font-medium;
                    color: $primary-color;
                    line-height: 1;
                    font-size: size(16);
                    @include min(768) {    
                        font-size: size(18);
                    }
                    @include min(1200) {    
                        font-size: size(20);
                    }
                }
                h1 {
                    font-size: size(25);
                    margin-bottom: 0;
                    transition-delay: .1s;
                    @include min(768) {    
                        font-size: size(30);
                    }
                    @include min(992) {    
                        font-size: size(35);
                    }
                    @include min(1200) {    
                        font-size: size(40);
                        padding-bottom: size(5);
                    }
                    @include min(1600) {    
                        font-size: size(50);
                        padding-bottom: size(15);
                    }
                    span {
                        display: inline-block;
                    }
                }
                p {transition-delay: .15s;}
                .bnrbtn {
                    transition-delay: .2s;
                    margin-top: size(20);
                    @include min(768) {    
                        margin-top: size(30);
                    }
                    @include min(1200) {    
                        margin-top: size(40);
                    }
                    @include min(1600) {    
                        margin-top: size(50);
                    }
                    @include max(991.98) {
                        background-color: $primary-color;
                        border-color: $primary-color;
                        color: $white;
                        &:hover {
                            background-color: $secondary-color;
                            border-color: $secondary-color;
                        }
                    }
                }
            }
        }
        .imgcol {
            position: relative;
            @include min(992) {
                fallback: 52%;
                max-width: 52%;
            }
            @include max(991.98) {
                order: 1;
            }
            .figwrap {
                width: 400px;
                max-width: 100%;
                margin: 0 auto;
                transition: all cubic-bezier(0.38, 0.67, 1, 1) .3s;
                transform: scale(.8);
                @include max(480) {
                    width: 260px;
                }
            }
            .imgsec {
                margin: 0;
                padding-bottom: calc((405/400)*100%);
                position: relative;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    &.loaded, &:global(.loaded) {
        opacity: 1;
        height: auto !important;
        min-height: 1px !important;
        .wrapper {
            .textcol {
                .textwrap {
                    &>* {
                        opacity: 1;
                        transform: translateY(0px);
                    }
                }
            }
            .imgcol {
                .figwrap {
                    transform: scale(1);
                }
            }
        }
    }
}
html[dir=rtl] {
    .carrerbnr {
        .circleshape {
            left: -20px;
            @include min(768) {
                left: 40px;
            }
            right: auto !important;
        }
        .circleshape_two {
            left: auto !important;
            right: 40px;
        }
    }
}