@import '../../../styles/utilities.scss';
.gallery_slider {
    max-width: 1033px;
    --swiper-navigation-size: 30px;
    @include min(768) {
        --swiper-navigation-size: 40px;
    }
    :global(.swiper-button-prev), :global(.swiper-button-next) {
        width: var(--swiper-navigation-size) !important;
        height: var(--swiper-navigation-size) !important;
        background-color: rgba(#000, .4);
        border-radius: 50%;
        transform: translateZ(0);
        &::after {
            color: $white;
            font-size: calc(var(--swiper-navigation-size) * .4);
            transform: scale(1);
            will-change: transform;
            transition: transform ease-in-out .2s;
        }
        &:hover {
            &::after {
                transform: scale(.8);
            }
        }
    }
    figure {
        margin: 0;
        img {
            display: block;
            margin: auto;
        }
    }
}