@import '../../styles/utilities.scss';
.culture_valuesec {
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
    @include min(1600) {
        margin-bottom: size(150);
    }
    opacity: 0;
    transform: translateY(20px);
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), .3s);
    &.loaded {
        opacity:1;
        transform: translateY(0px);
        height: auto !important;
        min-height: 1px !important;
    }
}
.outerwrap {
    width: 1089px;
    max-width: 100%;
    display: block;
    margin: auto;
}
.figwrap {
    width: 517px;
    max-width: 100%;
    .imgwrap {
        position: relative;
        padding-bottom: calc((330/517)*100%);
        margin: 0;
        @include border-radius(15px);
        @include overflow(hidden);
        @include min-max(768,991.98) {
            padding-bottom: calc((410/517)*100%);
        }
        @include max(767.98) {
            margin-bottom: size(25.7);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            -o-object-fit: cover;
            position: absolute;
        }
    }
}

.textwrap {
    max-width: 437px;
    display: block;
    @include min(768) {
        margin-left: auto;
    }
    @include min(1600) {
        padding-right: size(30);
    }
    @include max(767.98) {
        text-align: center;
    }
    h2 {
        margin-bottom: 0;
        @include min(1200) {
            padding-bottom: 10px;
        }
    }
    .arw_link {
        margin-top: size(20);
        @include min(992) {
            margin-top: size(30);
        }
        @include min(1200) {
            margin-top: size(35);
        }
        @include min(1600) {
            margin-top: size(40);
        }
    }
}

html[dir=rtl] {
    .textwrap {
        margin-left: 0 !important;
        @include min(768) {
            margin-right: auto;
        }
    }
}