@import '../../styles/utilities.scss';

.loader_gif {
    position: fixed;
    z-index: 9999;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all .3s cubic-bezier(0.38, 0.67, 1, 1);
    background-color: $white;
    z-index: 1;
    height: calc(100vh - 58px);
    top: 55px;
    
    @include min(768) {
        height: calc(100vh - 75px);
        top: 65px;
    }
    @include min(992) {
      top: 75px;
    }
    @include min(1600) {
        height: calc(100vh - 79px);
        top: 79px;
    }
    img {
        max-width: 100px;
        display: block;
    }
}