@import "../../styles/utilities.scss";
.cookies_wrapper {
  background: #2c373b;
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0px;
  padding: 15px;
  padding-right: 80px;
  .cookies_content {
    font-size: size(14);
    font-weight: $font-regular;
    color: #fff;
    margin-right: size(20);
    margin-top: 2px !important;
    @include min(768) {
      margin-bottom: 0;
    }
    @include min(1200) {
      font-size: size(15);
    }
  }
  .cookies_accept_button {
    @include border-radius(26px);
    padding: 15px;
    min-width: 1px;
    font-size: size(13);
    height: 35px;
    border-width: 2px;
    line-height: 0.2 !important;
    margin-right: 15px;

    @include min(1200) {
      padding: 0 27px;
      height: 40px;
      &:hover {
        border-color: darken($primary-color, 20%) !important;
        background-color: #2c373b !important;
      }
    }
  }
  .cookies_close {
    border: 1px solid $primary-color;
    background-color: $primary-color;
    border-radius: 50%;
    color: $white;
    width: 25px;
    height: 25px;
    line-height: 23px;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
    transition: all ease-in-out .15s ;
    will-change: background-color, border-color;
    &:hover {
      background-color: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
    }
  }
}
