@import '../../styles/utilities.scss';
.circleshape_one, .circleshape_two {
    background: rgb(255,155,93);
    background: radial-gradient(circle, rgba(255,155,93,1) 0%, rgba(249,249,249,0) 100%);
    position: absolute;
    @include border-radius(50%);
    opacity: .3;
    z-index: -1;
    pointer-events: none;
}
.circleshape_one {
    filter: blur(80px);
    width: 200px;
    height: 200px;
    bottom: 210px;
    left: 0px;
    opacity: .06;
    @include min(768) {
        width: 500px;
        height: 500px;
    }
    @include min(1200) {
        width: 700px;
        height: 700px;
        filter: blur(50px);
    }
}
.circleshape_two {
    filter: blur(80px);
    width: 200px;
    height: 200px;
    top: -150px;
    left: 400px;
    @include min(768) {
        width: 500px;
        height: 500px;
        @include max(991.98) {
            opacity: 0;
        }
    }
    @include min(1200) {
        width: 700px;
        height: 700px;
        opacity: .3;
        filter: blur(50px);
    }
}
.ovalshape {
    background: rgb(255,205,93);
    background: radial-gradient(circle, rgba(255,205,93,1) 0%, rgba(249,249,249,0) 100%);
    position: absolute;
    @include border-radius(50%);
    opacity: .3;
    z-index: -1;
    width: 200px;
    height: 500px;
    top: 100px;
    right: -100px;
    filter: blur(60px);
    pointer-events: none;
    @include min(992) {
        top: auto;
        filter: blur(80px);
        bottom: 110px;
        right: 110px;
        width: 300px;
        height: 550px;
    }
    @include min(1200) {
        width: 300px;
        height: 550px;
        opacity: .2;
        filter: blur(30px);
    }
}
.roundedrecshape {
    @include min(992) {
        display: block;
        position: absolute;
        @include border-radius(20px);
        left: 38px;
        background-color: $primary-color;
        opacity: .14;
        width: calc(100% - 76px);
        height: 150px;
        z-index: -1;
        bottom: -16px;
        filter: blur(50px);
    }
    @include min-max(768,991.98) {
        display: block;
        position: absolute;
        width: 200px;
        height: 200px;
        @include border-radius(50%);
        filter: blur(100px);
        background-color: $primary-color;
        right: -100px;
        opacity: .2;
        z-index: -1;
    }
}
.dropresumesec {
    position: relative;
    padding-bottom: size(50);
    overflow: hidden;
    @include min(768) {
        padding-bottom: size(60);
    }
    @include min(992) {
        padding-bottom: size(80);
    }
    @include min(1200) {
        padding-bottom: size(140);
    }
    @include min(1600) {
        padding-bottom: size(170);
    }
    opacity: 0;
    transform: translateY(20px);
    @include transition(all, cubic-bezier(0.38, 0.67, 1, 1), .3s);
    &.loaded {
        opacity:1;
        transform: translateY(0px);
        height: auto !important;
        min-height: 1px !important;
    }
}
.outerwrap {
    position: relative;
    background-color: #F9F8F7;
    @include border-radius(15px);
    padding: size(25);
    @include min(992) {
        background-color: $white;
        @include border-radius(20px);
        padding: size(40);
    }
    @include min(1200) {
        padding: size(65);
    }
    @include min(1600) {
        padding: size(80);
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 1135px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        &>* {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.formouterwrap {
    width: 420px;
    max-width: 100%;
    margin-left: auto;
}
.textwrap {
    width: 461px;
    max-width: 100%;
    margin-bottom: size(60);
    padding-right: size(40);
    @include min(768) {
        margin-bottom: size(70);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(100);
    }
    @include min(1600) {
        margin-bottom: size(133);
    }
    h2 {
        margin: 0;
        @include min(1200) {
            padding-bottom: size(10);
        }
    }
    .arw_link {
        margin-top: size(20);
        @include min(992) {
            margin-top: size(35);
        }
        @include min(1200) {
            margin-top: size(45);
        }
        @include min(1600) {
            margin-top: size(50.3);
        }
    }
}
.mbleview {
    margin-bottom: size(45);
    @include min(768) {
        display: none;
    }
    .arw_link {
        margin-top: size(25);
    }
}


html[dir=rtl] {
    .textwrap {
        padding-left: size(40);
        padding-right: 0;
    }
    .formouterwrap {
        margin-left: 0;
        margin-right: auto;
    }
}