@import '../../styles/utilities.scss';
.ovalshape {
    @include min(768) {
        width: 90px;
        height: 200px;
        background: rgb(255,155,93);
        position: absolute;
        z-index: 0;
        opacity: .78;
        bottom: 70%;
        filter: blur(80px);
        left: -30px;
        @include border-radius(50%)
    }
    @include min(1200) {
        width: 150px;
        height: 500px;
        left: -90px;
        bottom: 0%;
    }
}
.abtstorysec {
    position: relative;
    margin-bottom: size(50);
    @include min(768) {
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(130);
    }
    @include min(1600) {
        margin-bottom: size(150);
    }
}
.wrapper {
    display: flex;
    flex-wrap: wrap;
    &>* {
        flex-basis: 100%;
        max-width: 100%;
        @include min(992) {
            flex-basis: 50%;
            max-width: 50%;
        }
        @include min(992) {
            flex-basis: 48%;
            max-width: 48%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            &+* {
                flex-basis: 52%;
                max-width: 52%;
            }
        }
    }
}
.textwrap {
    max-width: 100%;
    @include min(992) {
        width: 635px;
        padding-right: size(25);
    }
    @include min(1200) {
        padding-right: 0;
    }
    &>h2 {
        font-weight: $font-medium;
    }
    .arw_link {
        margin: size(25) 0 size(20);
        @include min(992) {
            margin: size(30) 0;
        }
        @include min(1200) {
            margin: size(40) 0;
        }
    }
}
.storyteller {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: size(20);
    margin-top: size(20);
    border-top: 1px solid #E0E0E0;
    @include min(992) {
        padding-top: size(30);
        margin-top: size(30);
    }
    @include min(1200) {
        padding-top: size(40);
        margin-top: size(40);
    }
    .logowrap {
        flex-basis: 50px;
        width: 50px;
        max-width: 50px;
        position: relative;
        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: calc((48.41/50.71)*100%);
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            
            object-fit: cover;
            -o-object-fit: cover;
        }
    }
    .authordetail {
        flex-basis: calc(100% - 50px);
        max-width: calc(100% - 50px);
        padding-left: size(20);
        .name {
            font-size: size(18);
            padding-bottom: size(10);
            line-height: 1;
            font-weight: $font-semibold;
            color: $black;
            @include min(992) {
                padding-bottom: size(12);
                font-size: size(20);
            }
        }
        .desig {
            color: #818181;
            font-weight: $font-medium;
            font-size: size(15);
            @include min(992) {
                font-size: size(16);
            }
        }
    }
}

.figwrap {
    width: 568px;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-bottom: 0;
    @include max(991.98) {
        margin: 0 auto size(0);
    }
    @include max(767.98) {
        margin: 0 auto size(0);
    }
    .imgwrap {
        margin: 0 !important;
        position: relative;
        width: 100%;
        padding-bottom: 67%;
        @include border-radius(20px);
        @include overflow(hidden);

        @include min(1200){
            padding-bottom: 80.692308%;
        }
       
        // @include min(1600){
        //     padding-bottom: calc((545/568)*100%);
        // }
        img {
            
            object-fit: contain;
            top: 0;
            left: 0;
            right: 0;
            width: 67%;
            height: 80%;
            position: absolute;
            margin: 0 auto;
            @include min(1600){
                left: 0;
                right: unset;
                width: 100%;
                 height: 100%;
            }
        }
    }
}

html[dir=rtl] {
    .figwrap {
        margin-left: 0;
        margin-right: auto;
    }
    .textwrap {
        @include min(992) {
            padding-right: 0;
            padding-left: size(25);
        }
        @include min(1200) {
            padding-right: 0;
            padding-left: 0;
        }
    }
    .storyteller {
        .authordetail {
            padding-left: 0;
            padding-right: size(20);
        }
    }
}