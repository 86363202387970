@import '../../styles/utilities.scss';
.sharedetailsec {
    margin-bottom: size(50);
    @include min(768) {
        margin-top: 0;
        margin-bottom: size(60);
    }
    @include min(992) {
        margin-bottom: size(80);
    }
    @include min(1200) {
        margin-bottom: size(109);
    }
}
.wrapper {
    display: block;
    padding: size(30);
    background-color: #F9F8F7;
    @include border-radius(20px);
    text-align: center;
    @include min(768) {
        padding: size(40);
    }
    @include min(992) {
        padding: size(50);
    }
    @include min(1200) {
        padding: size(62);
    }
    .titlesec {
        margin-bottom: size(18);
        @include min(768) {
            margin-bottom: size(15);
        }
    }
}
.smedias {
    margin-top: size(28);
    @include min(768) {
        margin-top: size(30);
    }
    @include min(768) {
        margin-top: size(40);
    }
    ul {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        width: 235px;
        @include min(768) {
            width: 265px;
        }
        li {
            position: relative;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $text-alt;
                @include border-radius(50%);
                width: 35px;
                height: 35px;
                line-height: 35px;
                position: relative;
                @include min(768) {
                    width: 40px;
                    height: 40px;
                }
                svg {
                    max-width: 100%;
                    fill: $white;
                    transition: all cubic-bezier(0.38, 0.67, 1, 1) .2s;
                }
                &:hover {
                    @include min(992) {
                        background-color: $primary-color;
                        svg {
                            transform: scale(1.2);
                        }
                    }
                }
                button {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;left: 0;
                }
            }
        }
    }
}

.clip_to_board {
    position: absolute;
    display: inline-block;
    bottom: -25px;
    color: $primary-color;
    left: 50%;
    transform: translateX(-50%);
    font-size: size(12);
    padding: 2px 3px;
    border: 1px solid rgba($primary-color, 0.7);
    background-color: rgba($primary-color, 0.3);
    border-radius: 4px;
    line-height: 1;
  }