@import '../../styles/utilities.scss';
.abtbnrfold {
    margin-bottom: size(60);
    @include min(768) {
        margin-bottom: size(80);
    }
    @include min(992) {
        margin-bottom: size(100);
    }
    @include min(1200) {
        margin-bottom: size(140);
    }
    @include min(1600) {
        margin-bottom: size(180);
    }
    .wrapper {
        @include min(992) {
            display: flex;
            margin-left: -10px;
            margin-right: -10px;
            justify-content: space-between;
            &>* {
                padding-left: 10px;
                padding-right: 10px;
                max-width: 33.33%;
                flex-basis: 372px;
                width: 372px;
            }
        }
        @include max(991.98) {
            &>*  {
                &+* {
                    margin-top: size(49);
                }
            }
        }
        @include max(767.98) {
            &>*  {
                &+* {
                    margin-top: size(20);
                }
            }
        }
        .itemwrap {
            display: flex;
            flex-wrap: wrap;
            .itemimg, .itemdetail header, .itemdetail p {
                transition: all linear .3s;
                opacity: 0;
                transform: translateY(10px);
            }
            .itemimg {
                flex-basis: 49px;
                max-width: 49px;
                margin: 0;
                @include min(768) {
                    margin: 0 auto;
                    flex-basis: 62px;
                    max-width: 62px;
                }
                @include min(992) {
                    margin: 0;
                    flex-basis: 55px;
                    max-width: 55px;
                }
                @include min(1200) {
                    flex-basis: 60px;
                    max-width: 60px;
                }
            }
            .itemdetail {
                flex-basis: 100%;
                max-width: 100%;
                padding-top: 12px;
                text-align: left;
                @include min(768) {
                    text-align: center;
                }
                @include min(992) {
                    text-align: left;
                    flex-basis: calc(100% - 55px);
                    max-width: calc(100% - 55px);
                    padding-left: 20px;
                }
                @include min(1200) {
                    flex-basis: calc(100% - 60px);
                    max-width: calc(100% - 60px);
                }
                header {
                    color: $text-off;
                    font-size: size(20);
                    font-weight: $font-semibold;
                    @include min(768) {    
                        font-size: size(22);
                    }
                    @include min(1200) {
                        // font-weight: $font-medium;    
                        font-size: size(24);
                    }
                    @include min(1600) {    
                        font-size: size(26);
                    }
                }
                p {
                    color: $black;
                    @include max(767.98) {
                        margin-top: 16px !important;
                    }
                }
            }
        }
    }
    &.loaded {
        height: auto !important;
        min-height: 1px !important;
        .wrapper {
            &>* {
                .itemwrap {
                    .itemimg, .itemdetail header, .itemdetail p {
                        opacity: 1;
                        transform: translateY(0px);
                    }
                    .itemimg {
                        transition-delay: .1s;
                    }
                    .itemdetail {
                        header {
                            transition-delay: .15s;
                        }
                        p {
                            transition-delay: .2s;
                        }
                    }
                    @include max(767.98) {
                        border: 1px solid $line-color;
                        @include border-radius(20px);
                        padding: 20px 20px;
                        background-color: $white;
                    }
                }
                &:nth-child(2) {
                    .itemimg {
                        transition-delay: .25s;
                    }
                    .itemdetail {
                        header {
                            transition-delay: .3s;
                        }
                        p {
                            transition-delay: .35s;
                        }
                    }
                }
                &:nth-child(3) {
                    .itemimg {
                        transition-delay: .4s;
                    }
                    .itemdetail {
                        header {
                            transition-delay: .45s;
                        }
                        p {
                            transition-delay: .5s;
                        }
                    }
                }
            }
        }
    }
}

html[dir=rtl] {
    .abtbnrfold {
        .wrapper {
            .itemwrap {
                display: flex;
                flex-wrap: wrap;
                .itemdetail {
                    padding-left: 0;
                    padding-right: 10px;
                    @include min(992) {
                        text-align: right;
                    }
                }
            }
        }
    }
}