@import '../../styles/utilities.scss';

.joinusec {
    margin-bottom: size(50);
    @include min(992) {
        margin-bottom: size(60);
    }
    @include min(1200) {
        margin-bottom: size(70);
    }
    @include min(1600) {
        margin-bottom: size(90);
    }
    .wrapper {
        background-color: $primary-color;
        @include border-radius(15px);
        text-align: center;
        position: relative;
        @include overflow(hidden);
        padding: 35px 25px ;
        @include min(768) {
            padding: 30px 30px ;
        }
        @include min(992) {
            padding: 40px 40px ;
        }
        @include min(1200) {
            padding: 45px 45px ;
        }
        @include min(1600) {
            padding: 53px 53px ;
        }
        .smtitle {
            color: $white;
            text-transform: uppercase;
            font-weight: $font-medium;
            position: relative;
            z-index: 1;
            font-size: size(13);
            letter-spacing: 3.5px;
            @include min(768) {
                margin-top: size(15);
                font-size: size(15);
                letter-spacing: 3.5px;
            }
            @include min(992) {
                font-size: size(16);
            }
        }
        .secmainhead {
            color: $white;
            font-weight: $font-medium;
            margin-bottom: size(20);
            font-size: size(25);
            margin-top: size(10);
            line-height: 1;
            position: relative;
            z-index: 1;
            transition-delay: .2s;
            @include min(768) {
                margin-bottom: size(30);
                font-size: size(30);
            }
            @include min(992) {    
                font-size: size(35);
            }
            @include min(1200) {
                margin-bottom: size(35);
                font-size: size(45);
                margin-top: size(11);
            }
            @include min(1600) {    
                font-size: size(50);
            }
        }
        .openingbtn {
            // transition-delay: .4s;
        }
        .shape {
            position: absolute;
            display: inline-block;
            line-height: 0;
            width: 280px;
            max-width: 22%;
            top: 50%;
            transform: translateY(-50%);
            right: 2%;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: contain;
                top: 0;
                left: 0;
            }
            &::before {
                content: "";
                padding-bottom: 100%;
                display: block;
            }
            @include max(767.98) {
                display: none;
            }
        }
    }
}